import React, { useState } from "react";
import OpponentPlayerList2 from "./OpponentPlayerList2";
import OwnerDisplayNameCard2 from "./OwnerDisplayNameCard2";


//This errors out when all owner_ids are null.... why are they all null? Should we replace with roster_id where we used owner_id (throughout entire app)?
const LeagueTeamsView = (props) => {
    const [isOpponentSelected, setIsOpponentSelected] =  useState(props.isOpponentSelected);
    const [teamIndexInLeagueRosters, setTeamIndexInLeagueRosters] = useState(0);


    // const leagueRosters = props.leagueRosters;
    const leagueRosters = props.leagueUsersRosterObject.userList;
    const leagueUsersRosterObject = props.leagueUsersRosterObject; 

    const handleClickOnTeamList = event => {
        if(!event.target.className.includes("playerCard") && !event.target.className.includes("currentUserPlayerCheckbox")) {
            if(isOpponentSelected === false) {
                setIsOpponentSelected(true);
                //event.target can be either div within the button, is there a way to fix this, or do we just deal with both? Need to be able to get the button info
                //event.currentTarget is the answer ^
                if(event.currentTarget.dataset.ownerid !== undefined) {
                    props.setSelectedOpponentTeamId(event.currentTarget.dataset.ownerid);
                } else {
                    props.setSelectedOpponentTeamId(Math.random().toString())
                }
                setTeamIndexInLeagueRosters(Number(event.currentTarget.dataset.index));
            } else {
                handleClickToCloseTeam(event);
            }
        }
    };

    const handleClickToCloseTeam = event => {
        if(!event.target.className.includes("playerCard")) {
            setIsOpponentSelected(false);
            props.resetOpponentTradeHandler();
        }
    };

    function shouldBeHidden(ownderId) {
        if(isOpponentSelected && (ownderId !== props.selectedOpponentTeamId)) {
            return " hidden";
        } else {
            return "";
        }
        
    };

    return(
        <div className="teamColumn opponentTeamColumn">
            <h2 className="proposeTradeHeaderText">Select Opponent Team</h2>
            
            <div id="opponentTeams" data-testid="opponentTeamsContainer">
                {leagueUsersRosterObject.userList.map((current_roster, index) =>(
                    <div data-testid="singleOpponentContainer" className={"opponentTeamContainer" + shouldBeHidden(current_roster.getOwnerIdFromRosterData())} key={"team_" + current_roster.getOwnerIdFromRosterData()} >
                        <div key={"team_" + current_roster.getOwnerIdFromRosterData()} id={"team_" + current_roster.getOwnerIdFromRosterData()} className="singleOpponentTeam" type="button">
                            <OwnerDisplayNameCard2 onClick={handleClickOnTeamList} isCollapsed={!isOpponentSelected} userRosterObject={current_roster} index={index}></OwnerDisplayNameCard2>
                            <OpponentPlayerList2 allPlayerValuesSet={props.allPlayerValuesSet} getCardColor={props.getCardColor} onCheckPlayer={props.onCheckPlayer} userRosterObject={current_roster} players={leagueRosters[teamIndexInLeagueRosters].roster_data.players} player_info={leagueRosters[teamIndexInLeagueRosters].roster_data.player_info} teamId={"team_" + current_roster.getOwnerIdFromRosterData()}></OpponentPlayerList2>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

}


export default LeagueTeamsView;