import { UserRoster } from "./UserRoster";

export class LeagueUser {
    constructor(userData, rosterData, nflStateData, leagueObject, leagueType) {
        if(leagueType === "sleeper") {
            if(userData !== undefined ) {
                this.avatar = userData.avatar;
                this.display_name = userData.display_name;
                this.league_id = userData.league_id;
                this.metadata = userData.metadata;
                // if(!this.metadata["team_name"]) {
                //     this.metadata["team_name"] = "Team " + rosterData.roster_id;
                // } 
                this.user_id = userData.user_id;
                this.username = userData.username;
            } else { //if there is no sleeper user assigned to this team (should be rare, we make up some things)
                this.avatar = null;
                this.display_name = "No Display Name";
                this.league_id = leagueObject.league_id;
                this.user_id = Math.floor(Math.random() * 100000).toString();
                // this.user_id = rosterData.roster_id;
                this.metadata = {};
                this.metadata["team_name"] = "No Team Name " + this.user_id;
                rosterData.owner_id = this.user_id;
                // this.metadata["team_name"] = "Team " + rosterData.roster_id;
                // rosterData.owner_id = rosterData.roster_id;
                this.username = "No username";
            }
            this.roster_data = new UserRoster(rosterData, nflStateData, leagueObject, leagueType )
        }
    }

    getUserID() {
        return this.user_id;
    }

    getOwnerIdFromRosterData() {
        return this.getRosterData().getOwnerId();
    }

    getPlayersArray() {
        return this.players;
    }

    getValueForPlayer(playerId) {
        let a = "a"
        // this.player_projections[playerId]
    }

    getPlayers() {
        return this.getRosterData().getPlayersArray();
    }

    getRosterData() {
        return this.roster_data;
    }

    getPlayersPositon(player_id) {
        return this.getRosterData().getPlayerPositionFromFullData(player_id);
    }

    //This is duplicated in CurrentUser.js , if you change one, change both (and figure out way to have them share functions...)
    getPlayerProjectionsJsonFromFullData() {
        return this.getRosterData().getPlayerProjectionsJsonFromFullData();
    }

    //This is duplicated in CurrentUser.js , if you change one, change both (and figure out way to have them share functions...)
    getPlayerValueFromFullData(playerId, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek) {
        return this.getRosterData().getPlayerValueFromFullData(playerId, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek);
    }

    getAllPlayersOnTeam() {
        let allPlayers = this.getRosterData().getAllPlayersOnTeam();
        for(let player of Object.entries(allPlayers)) {
            let playerInfo = player[1];
            playerInfo['owner_id'] = this.user_id;
            playerInfo['player_id'] = player[0];

        }

        return allPlayers;
    }
}

