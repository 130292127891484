import logo from '../../assets/Logo2_small_h.png';
import './Footer.css';
import { HashLink as Link2 } from 'react-router-hash-link';


const Footer = (props) => {


    return (
        <div id="footer" className="container infoFont">
            <footer className="py-3 my-4">
                <ul className="nav justify-content-center pb-3 mb-3">
                <li className="nav-item"><a href="/#root" className="nav-link px-2 text-muted">Home</a></li>
                <li className="nav-item"><Link2 to='/#about' className="nav-link px-2 text-muted">About</Link2></li>
                </ul>
                <div id="footer-bottom">
                    <div id="logo">
                        <img src={logo} className="d-inline-block align-top" alt=""/>
                    </div>
                    <div className="footerText">
                        <div id="copyright" className="text-muted">© 2023 FantasyTradeGrade.com</div>
                        <div id="contentText" className="text-muted"> The content of this website is intended to be used for entertainment purposes only.</div>
                    </div>
                    <div id="socials">
                        <a id="email" className="text-muted" href="mailto:admin@fantasytradegrade.com"><i className="bi bi-envelope-fill"></i></a>
                        <a id="twitter" className="text-muted" href="https://twitter.com/fantasytradegrd"><i className="bi-twitter"></i></a>
                    </div>
                </div>
                

            </footer>
        </div>
    )

};
 
export default Footer;