
export class UserRoster {
    constructor(rosterData, nflStateData, leagueObject, leagueType) {
        this.leagueType = leagueType;
        this.userHasPlayers = false;
        if(leagueType === "sleeper") {
            this.nflStateData = nflStateData;
            this.owner_id = rosterData.owner_id;
            this.players = this.sortPlayersWithStartersFirst(rosterData.players, rosterData.starters);
            this.reserve = rosterData.reserve;
            this.roster_id =  rosterData.roster_id;
            this.settings = rosterData.settings;
            this.starters = rosterData.starters;
            this.leagueObject = leagueObject;
            this.player_projections = {};
            this.player_info = {};
        }

    }

    //Given an array of players, and array of starters
    //Return an array where the starters are first
    sortPlayersWithStartersFirst(players, starters) {
        for (var i = starters.length; i--; ) {
            //If you don't have starters set, it can show random 0's as your starters, filter these out
            if(starters[i] === '0') {
                starters.splice(i, 1)
            }
        }
       let sortedPlayers = starters;
       for(let i = 0; i < players.length; i++) {
           let player = players[i];
           if(!sortedPlayers.includes(player)) {
                sortedPlayers.push(player);
           }
       }
       return sortedPlayers;
    }

    //Should be called 'set' not get...
    async getPlayerInfoAndProjectionsForTeam(allWeeklyProjections, allPlayerInfo, finalWeekOfSeasonForLeague) {
        if(this.leagueType === "sleeper") {
            this.getPlayerInfo(allPlayerInfo); //Get name, position, etc. for each player

            let i = 0;
            if(this.userHasPlayers === true) {
                for(let week = this.nflStateData.week; week <= finalWeekOfSeasonForLeague; week++) {
                    // const projectionsEndpoint = `https://api.sleeper.app/v1/projections/nfl/regular/${this.nflStateData.season}/${week}`;
                    // let minCreatedTime =  new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).getTime(); //24 hours ago
                    // const weeklyProjections = await this.writeJsonToLocalBrowserStorageAndReturn("projectionJsonWeek_" + week, minCreatedTime, projectionsEndpoint);
                    let projectionForCurrentWeek = allWeeklyProjections[i];
                    this.assignWeeklyProjections(projectionForCurrentWeek, week, this.leagueObject);
                    i++;
                }
            }
        } 
    }

    async writeJsonToLocalBrowserStorageAndReturn(key, minCreatedTime, jsonEndpoint) {
        const now = new Date().getTime();
        const createdTimeString = "CreatedTime";
        let jsonData = '';
        //If key doesn't exist or key is older than the passed minimum date, create it
        if((!localStorage.hasOwnProperty(key) || !localStorage.hasOwnProperty(key + createdTimeString)) || (localStorage.hasOwnProperty(key) && localStorage.hasOwnProperty(key + createdTimeString) && (localStorage.getItem(key + createdTimeString) < minCreatedTime))) {
          if(localStorage.hasOwnProperty(key)) {
            localStorage.removeItem(key);
          }
          if(localStorage.hasOwnProperty(key + createdTimeString)) {
            localStorage.removeItem(key + createdTimeString);
          }
          const response = await fetch(jsonEndpoint);
          jsonData = await response.json();
          localStorage.setItem(key + createdTimeString, now)
          localStorage.setItem(key, JSON.stringify(jsonData));
        } else {
          jsonData = JSON.parse(localStorage.getItem(key));
        }
        
        return jsonData;
    }


    assignWeeklyProjections(weeklyProjections, week, leagueObject) {
        //new code
        for(let player of this.players) {
            if(weeklyProjections[player] !== undefined) {
               
                if(!this.player_projections[player]) {
                    this.player_projections[player] = {};
                }
                let singleWeekSiteProjectionForPlayer = weeklyProjections[player];

                this.player_projections[player]["week_"+week] = singleWeekSiteProjectionForPlayer;
                let playerPosition = this.getPlayerPositionFromFullData(player);
                this.player_projections[player]["week_"+week]["pts_league"] = this.constructor.getPlayerPointProjectionNew(singleWeekSiteProjectionForPlayer, leagueObject.scoring_settings.scoring_settings, playerPosition);
            } else {
                if(!this.player_projections[player]) {
                    this.player_projections[player] = {};
                }
                this.player_projections[player]["week_"+week] = {};
                this.player_projections[player]["week_"+week]["pts_league"] = 0;
            }
        }
        
        //old code
        // for(let roster of rosters.entries()) {
        //     if(!rosters[roster[0]]["player_projections"]) {
        //         rosters[roster[0]]["player_projections"] = {};
        //     }

        //     //for each roster, find the matching projection for that user, for that week, and add it to roster
        //     for(let player of roster[1].players.entries()) {
        //         if(!rosters[roster[0]]["player_projections"][player[1]]) {
        //             rosters[roster[0]]["player_projections"][player[1]] = {};
        //         }
        //         if(!rosters[roster[0]]["player_projections"][player[1]]["week_"+week]) {
        //             rosters[roster[0]]["player_projections"][player[1]]["week_"+week] = {};
        //         }
        //         if(!rosters[roster[0]]["player_projections"][player[1]]["week_"+week]["pts_league"]) {
        //             rosters[roster[0]]["player_projections"][player[1]]["week_"+week]["pts_league"] = 0;
        //         }
        //         if(weeklyProjections[player[1]]) {
        //             rosters[roster[0]]["player_projections"][player[1]]["week_"+week] = weeklyProjections[player[1]];
        //             //console.log("Starting proj for player " + player[1]);
        //             let playerProjectionForWeek = this.getPlayerPointProjection(weeklyProjections[player[1]], leagueObject.scoring_settings);
        //             rosters[roster[0]]["player_projections"][player[1]]["week_"+week]["pts_league"] = playerProjectionForWeek;
        //         }
                
        //         //TRY UPDATING THE CALL TO THIS METHOD IN USEFFECT TO NOT USE PROPS.WHATEVER, BUT INSTEAD SET A VARIABLE ON THE SETTINGS CALL, MAKE IT AWAIT ASYNC, THEN PASS IT TO THIS (if it exists... maybe??????
        //          //need to verify the above works ^ then use it in tradebreakdown view rather than re-calculate it. 
        //         //If it's not working, it's because leagueSettings isn't fetched in time for the call, need to fix that somehow...
        //         //ADDED SOME LOGGING TO SEE WHY THIS IS ENDLESSLY LOOPING...looks like TEN is the culprit? PLAYERS DON'T EXIST IN BYE WEEK PROJECTION JSON
        //     }
        // }
    }

    //There are bonuses like ... bonus_rec_rb ... bonus_fd_qb
    //These are a bit more complicated, need to know player's position and match it, also I think fd for a qb is "pass_fd", so would need to match 'qb' and 'pass'
    //Not as straightforward, requires specific logic for each bonus, handle here
    //Only pass into this bonuses that have already been checked to have value (aka be enabled for the league)
    //Return the points the player should gain from this bonus (if any)
    static handleLogicForDifficultScoringSettings(playerSingleWeekProjectionsJson, stat, settingsInfo, playerPosition) {
        //See LeagueScoringSettings variables for list of options
        
        //For settingsInfo: 0th entry in array is original bonus name (with "bonus" included), 1st entry is points for hitting bonus
        
        
        //Group rules by who they apply to (i.e. everything in this if block is QB specific)
        if(playerPosition === "QB") {
            //chase this QB's pass_fd was like.. 27... that seems high? Look more into this
            //First down by QB (pass_fd = passing first down)
            if(stat === "fd_qb" && playerPosition === "QB" && playerSingleWeekProjectionsJson["pass_fd"] !== undefined) {
                return Number(playerSingleWeekProjectionsJson["pass_fd"]) * settingsInfo[1];
            }
        }
        
        if(playerPosition === "RB") {
            //First Down by RB
            //RBs have rush_fd and rec_pd as possibilities. I assume fd_rb includes either
            if(stat === "fd_rb" && playerPosition === "RB" && playerSingleWeekProjectionsJson["rush_fd"] !== undefined) {
                let totalProjectedRushFirstDowns = Number(playerSingleWeekProjectionsJson["rush_fd"]) > 0 ? Number(playerSingleWeekProjectionsJson["rush_fd"]) : 0;
                let totalProjectedRecFirstDowns = Number(playerSingleWeekProjectionsJson["rec_fd"]) > 0 ? Number(playerSingleWeekProjectionsJson["rec_fd"]) : 0;
                let totalProjectedFirstDowns = totalProjectedRushFirstDowns + totalProjectedRecFirstDowns;
                return totalProjectedFirstDowns;
            }

            //Bonus for Receptions by a RB
            if(stat === "rec_rb" && playerPosition === "RB" && playerSingleWeekProjectionsJson["rec"] !== undefined) {
                return Number(playerSingleWeekProjectionsJson["rec"]) * settingsInfo[1];
            }
        }
        
        if(playerPosition === "TE") {
            //First Down by TE
            //TEs have rush_fd and rec_fd as possibilities. I assume fd_te includes either
            if(stat === "fd_te" && playerPosition === "TE" && playerSingleWeekProjectionsJson["rec_fd"] !== undefined) {
                let totalProjectedRushFirstDowns = Number(playerSingleWeekProjectionsJson["rush_fd"]) > 0 ? Number(playerSingleWeekProjectionsJson["rush_fd"]) : 0;
                let totalProjectedRecFirstDowns = Number(playerSingleWeekProjectionsJson["rec_fd"]) > 0 ? Number(playerSingleWeekProjectionsJson["rec_fd"]) : 0;
                let totalProjectedFirstDowns = totalProjectedRushFirstDowns + totalProjectedRecFirstDowns;
                return totalProjectedFirstDowns;
            }

            
            //Bonus for Receptions by a TE
            if(stat === "rec_te" && playerPosition === "TE" && playerSingleWeekProjectionsJson["rec"] !== undefined) {
                return (Number(playerSingleWeekProjectionsJson["rec"])  * Number(settingsInfo[1]));
            }
        }       

        if(playerPosition === "WR") {
            //First Down by WR
            //WRs have rush_fd and rec_fd as possibilities. I assume fd_wr includes either
            if(stat === "fd_wr" && playerPosition === "WR" && playerSingleWeekProjectionsJson["rec_fd"] !== undefined) {
                let totalProjectedRushFirstDowns = Number(playerSingleWeekProjectionsJson["rush_fd"]) > 0 ? Number(playerSingleWeekProjectionsJson["rush_fd"]) : 0;
                let totalProjectedRecFirstDowns = Number(playerSingleWeekProjectionsJson["rec_fd"]) > 0 ? Number(playerSingleWeekProjectionsJson["rec_fd"]) : 0;
                let totalProjectedFirstDowns = totalProjectedRushFirstDowns + totalProjectedRecFirstDowns;
                return totalProjectedFirstDowns;
            }

            //Bonus for Receptions by a WR
            if(stat === "rec_wr" && playerPosition === "WR" && playerSingleWeekProjectionsJson["rec"] !== undefined) {
                return (Number(playerSingleWeekProjectionsJson["rec"])  * Number(settingsInfo[1]));
            }
        }

        if(playerPosition === "DEF") {
            if(stat === "def_fum_td") {
                //No idea what this is for... def_td of 50+ yards? There's no projection for that
                //Also, this has a number (bonus_def_fum_td_50p is original text) that gets stripped off.
                //Need to find out how to deal with cases like 50p if they're ever needed
                return 0;
            }
            if(stat === "def_int_td") {
                //No idea what this is for... same notes as above
                return 0;
            }
    
            //Chase this won't get reached because it has a number.... "2p" in the original rule
            //How to fix?
            if(stat === "sack" && playerPosition === "DEF" && playerSingleWeekProjectionsJson["sack"] !== undefined) {
                return (Number(playerSingleWeekProjectionsJson["sack"])  * Number(settingsInfo[1]));
            }    

            //Chase this won't get reached because it has a number.... "2p" in the original rule
            //How to fix?
            if(stat === "tkl" && playerPosition === "DEF") {
                // IDP rule? Don't see tkl, just tkl_loss on defenses
                return 0;
            }    
        }

        return 0;
        
    }

    //chase something below is resulting in the below string???
    //projectedPointTotal: '019.764200000000002bonus_pass_cmp_2513.13000000000000312.56815.74611.3579.21147.94199999999999912.2380000000000017.0600000000000018.13'
    static getPlayerPointProjectionNew(playerSingleWeekProjectionsJson, leagueScoringSettings, playerPosition) {
        let projectedPoints = 0;
        if(leagueScoringSettings) {
            for(let stat of Object.entries(playerSingleWeekProjectionsJson)) {
                //Only give points if the scoring setting value is not 0 (0 === not set/enabled for league)
                if(leagueScoringSettings[stat[0]] !== 0 && leagueScoringSettings[stat[0]] !== undefined) {
                    let pointsForPlayerFromStat = Number(stat[1] * leagueScoringSettings[stat[0]]);
                    if(!isNaN(pointsForPlayerFromStat)) {
                        projectedPoints = Number(projectedPoints + pointsForPlayerFromStat);
                    }
                }
            }
            //Handle bonuses
            for(let setting of Object.entries(leagueScoringSettings)) {
                //Only give points if the bonus value is not 0 (0 === not set/enabled for league)
                if(setting[0].includes("bonus") && setting[1] !== 0) {
                    //examples: bonus_pass_yd_300
                    //strip the word bonus off
                    let stat = setting[0].replace('bonus_','');
                    let numberNeededForBonus;
                    //if it contains a number, save and remove number, add to total if player is projected to beat number
                    if(/\d/.test(stat)) {
                        let lastUnderscoreIndex = stat.lastIndexOf('_');
                        //strip and save the number at the end (and remove the trailing _ before the number)
                        numberNeededForBonus = stat.substring(lastUnderscoreIndex + 1);
                        stat = stat.substring(0, lastUnderscoreIndex);

                        //rush_rec_yd is a bonus for all-purpose??
                        if(stat[0].includes('rush_rec_yd')) {
                            let rush_yd = playerSingleWeekProjectionsJson['rush_yd'];
                            let rec_yd = playerSingleWeekProjectionsJson['rec_yd'];
                            if((rush_yd + rec_yd) >= Number(numberNeededForBonus)) {
                                projectedPoints += setting[1];
                            }
                        }
                        //if the user has a stat that matches the remaining string i.e. "pass_yd", and it's >= the saved number, add it to projectedPoints
                        if(Number(playerSingleWeekProjectionsJson[stat]) >= Number(numberNeededForBonus)) {
                            projectedPoints += setting[1];
                        }
                    } else {
                        //There are bonuses like ... bonus_rec_rb ... bonus_fd_qb
                        //These are a bit more complicated, need to know player's position and match it, also I think fd for a qb is "pass_fd", so would need to match 'qb' and 'pass'
                        //Not as straightforward, requires specific logic for each bonus, do later.
                        const difficultBonuses = this.handleLogicForDifficultScoringSettings(playerSingleWeekProjectionsJson, stat, setting, playerPosition);
                        if(!isNaN(difficultBonuses)) {
                            projectedPoints += difficultBonuses;
                        }
                        // projectedPoints += this.handleLogicForDifficultScoringSettings(playerSingleWeekProjectionsJson, stat, setting, playerPosition);
                        // if(stat stat === "fd_qb" && playerSingleWeekProjectionsJson["pass_fd"] !== undefined) {

                        // }
                    }
                    
                }
            }
        } else {
            if(playerSingleWeekProjectionsJson && playerSingleWeekProjectionsJson.pts_half_ppr) {
                return Number(playerSingleWeekProjectionsJson.pts_half_ppr);
            } else {
                return 0;
            }
        }
        return projectedPoints;
    }

    

    //don't need
    tallyUpReceptionCount (playerJsonProjections) {
        let totalReceptions = 0;
        totalReceptions += playerJsonProjections["rec_0_4"];
        totalReceptions += playerJsonProjections["rec_5_9"];
        totalReceptions += playerJsonProjections["rec_10_19"];
        totalReceptions += playerJsonProjections["rec_20_29"];
        totalReceptions += playerJsonProjections["rec_30_39"];
        totalReceptions += playerJsonProjections["rec_40p"];
        return totalReceptions
    }

    //Takes into account league settings and gives point value based on projected stats
    getPlayerCalculatedWeeklyPointProjectionFromFullData(playerId, week) {
        let projectedPoints = 0;
        const leagueScoringSettings = this.getLeagueScoringSettingsFromObject();
        const playerSingleWeekProjectionsJson = this.getPlayerSingleWeekProjectionsJsonFromFullData(playerId, week);
        
        //Get Non-bonus projected points
        projectedPoints += this.getProjectedNonBonusPointsForWeekForPlayer(leagueScoringSettings, playerSingleWeekProjectionsJson);
        //Handle bonuses
        projectedPoints += this.getProjectedBonusPointsForWeekForPlayer(leagueScoringSettings, playerSingleWeekProjectionsJson)
        
        return projectedPoints;
    }

    getProjectedNonBonusPointsForWeekForPlayer(leagueScoringSettings, playerSingleWeekProjectionsJson) {
        let projectedPoints = 0;
        for(let stat of Object.entries(playerSingleWeekProjectionsJson)) {
            if(leagueScoringSettings[stat[0]]) {
                projectedPoints = Number(projectedPoints + (stat[1] * leagueScoringSettings[stat[0]]));
            }
        }
        return projectedPoints;
    }

    getProjectedBonusPointsForWeekForPlayer(leagueScoringSettings, playerSingleWeekProjectionsJson) {
        let projectedBonusPoints = 0;
        for(let settings of Object.entries(leagueScoringSettings)) {
            if(settings[0].includes("bonus")) {
                //examples: bonus_pass_yd_300
                //strip the word bonus off
                let stat = settings[0].replace('bonus_','');
                let numberNeededForBonus;
                //if it contains a number, save and remove number, add to total if player is projected to beat number
                if(/\d/.test(stat)) {
                    let lastUnderscoreIndex = stat.lastIndexOf('_');
                    //strip and save the number at the end (and remove the trailing _ before the number)
                    numberNeededForBonus = stat.substring(lastUnderscoreIndex + 1);
                    stat = stat.substring(0, lastUnderscoreIndex);

                    //rush_rec_yd is a bonus for all-purpose??
                    if(stat[0].includes('rush_rec_yd')) {
                        let rush_yd = playerSingleWeekProjectionsJson['rush_yd'];
                        let rec_yd = playerSingleWeekProjectionsJson['rec_yd'];
                        if((rush_yd + rec_yd) >= Number(numberNeededForBonus)) {
                            projectedBonusPoints += settings[1];
                        }
                    }
                    //if the user has a stat that matches the remaining string i.e. "pass_yd", and it's >= the saved number, add it to projectedPoints
                    if(Number(playerSingleWeekProjectionsJson[stat]) >= Number(numberNeededForBonus)) {
                        projectedBonusPoints += settings[1];
                    }
                } else {
                    //There are bonuses like ... bonus_rec_rb ... bonus_fd_qb
                    //These are a bit more complicated, need to know player's position and match it, also I think fd for a qb is "pass_fd", so would need to match 'qb' and 'pass'
                    //Not as straightforward, requires specific logic for each bonus, do later.
                }
            }
        }
        return projectedBonusPoints;
    }

    //Week text should be in format "week_15"
    getPlayerSingleWeekProjectionsJsonFromFullData(playerId, week) {
        return this.player_projections[playerId][week];
    }

    //Should be called set...
    getPlayerInfo(allPlayerInfo) {
        if(this.players[0] !== '0') {
            this.userHasPlayers = true;
            for(let player of this.players.entries()) {
                    if(!this.player_info[player[1]]) {
                        this.player_info[player[1]] = {};
                    }
                    this.player_info[player[1]]["player_name"] = this.getPlayerName(player[1], allPlayerInfo);
                    this.player_info[player[1]]["player_position"] = this.getPlayerPosition(player[1], allPlayerInfo);
                    this.player_info[player[1]]["player_team"] = this.getPlayerTeam(player[1], allPlayerInfo);
                }
        } else {
            this.userHasPlayers = false;
        }
    }

    getPlayerName(playerId, allPlayerInfo) {
        if(allPlayerInfo[playerId] && allPlayerInfo[playerId].full_name) {
            return allPlayerInfo[playerId].full_name;
        } else {
            if(allPlayerInfo[playerId].position === 'DEF') {
                return allPlayerInfo[playerId].last_name;
            }
            return "No Name Found For Player";
        }
    }

    getPlayerPosition(playerId, allPlayerInfo) {
        if(allPlayerInfo[playerId] && allPlayerInfo[playerId].position) {
            return allPlayerInfo[playerId].position;
        } else {
            return "No Position Found For Player";
        }
    }
  
    getPlayerTeam(playerId, allPlayerInfo) {
        if(allPlayerInfo[playerId] && allPlayerInfo[playerId].team) {
            return allPlayerInfo[playerId].team;
        } else {
            return "No Team";
        }
    }

    getPlayerValue(playerId, allPlayerInfo) {
        if(allPlayerInfo[playerId] && allPlayerInfo[playerId].value) {
            return allPlayerInfo[playerId].value;
        } else {
            return "No value";
        }
    }

    setPlayerValues(replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek) {
        //For each player on roster, calculate their value and add to their player_info entry
        for(let playerId of Object.keys(this.player_info)) {
            const playerValue = this.getPlayerValueFromFullData(playerId, replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek)
            //Add the player's playerValue to this.player_info[playerId]
            this.player_info[playerId]["player_value"] = playerValue;
        }
    }

    getPlayerCardDetailedInfo(playerId, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek) {
        return {
            player_name: this.getPlayerNameFromFullData(playerId),
            player_team: this.getPlayerTeamFromFullData(playerId),
            player_position: this.getPlayerPositionFromFullData(playerId),
            player_remaining_projection_points: this.getPlayerRemainingProjectionPointsFromFullData(playerId, startWeek, endWeek),
            player_value: this.getPlayerValueFromFullData(playerId, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek)
        };
    }

    getPlayerNameFromFullData(playerId) {
        return this.player_info[playerId]["player_name"];
    }

    getPlayerPositionFromFullData(playerId) {
        return this.player_info[playerId]["player_position"];
    }

    getPlayerTeamFromFullData(playerId) {
        return this.player_info[playerId]["player_team"];
    }

    //Given a playerId and a json object of replacement level values for each position for this league, get their value score.
    getPlayerValueFromFullData(playerId, replacementLevelValuesJson, leaguesLargestParScore, returnNegativeValues, startWeek, endWeek) {
        let playerPosition = this.getPlayerPositionFromFullData(playerId);
        let replacementLevelPointsForPosition = this.getReplacementLevelPointsForPosition(playerPosition, replacementLevelValuesJson);
        let playerRemainingAvgPoints = this.getPlayersAvgRemainingProjectionPointsBasedOnScoringSettingsFromFullData(playerId, startWeek, endWeek);
        const playerValue =  this.calculateValueGivenPlayerAvgAndReplacementScore(playerRemainingAvgPoints, replacementLevelPointsForPosition, leaguesLargestParScore);
        if(isNaN(playerValue)) {
            return 0;
        }
        if(!returnNegativeValues && playerValue < 0) {
            return 0;
        } 
        return playerValue;
    }

    getReplacementLevelPointsForPosition(playerPosition, replacementLevelValuesJson) {
        let value = replacementLevelValuesJson[playerPosition];
        if(isNaN(value)) {
            if(playerPosition === "DT" || playerPosition === "DE") {
                return replacementLevelValuesJson["DL"];
            }
            if(playerPosition === "CB") {
                return replacementLevelValuesJson["DB"];
            }
        }
        return value;
    }

    calculateValueGivenPlayerAvgAndReplacementScore(playerRemainingAvgPoints, replacementLevelPointsForPosition, leaguesLargestParScore) {
        let playerAvgPar = playerRemainingAvgPoints - replacementLevelPointsForPosition;
        return Math.round((playerAvgPar / leaguesLargestParScore) * 100);
    }

    getWeeksArrayWithWeeksText(startWeek, endWeek) {
        let weeksWithWeekText = [];
        for(let i = startWeek; i <= endWeek; i++) {
            let week = "week_" + i;
            weeksWithWeekText.push(week);
        }
        return weeksWithWeekText;
    }

    //Get calculated projection based on projected stats and league scoring settings for each week and sum them
    getPlayerRemainingProjectionPointsFromFullData(playerId, startWeek, endWeek) {
        let remainingPointsProjection = 0;
        //Old pre-weeks setting code below
        // const weeks = this.getProjectionWeeksForPlayerWithFullData(playerId);
        const weeks = this.getWeeksArrayWithWeeksText(startWeek, endWeek);

        //Get calculated projection based on projected stats and league scoring settings for each week and sum them
        for(let week of weeks.entries()) {
            // remainingPointsProjection += this.getPlayerCalculatedWeeklyPointProjectionFromFullData(playerId, week[1]);
            remainingPointsProjection += this.getPlayersProjectedPtsLeagueForWeekFromFullData(playerId, week[1]);
        }

        remainingPointsProjection = Math.round(remainingPointsProjection * 100) / 100;

        return remainingPointsProjection;
    }

    //Get the "pts_league" value previously calculated based on projected stats and league scoring settings for a week for a player
    //Week should be in format "week_1"
    getPlayersProjectedPtsLeagueForWeekFromFullData(playerId, week) {
        let leaguePtsForWeekFromPlayersProjectionObject = 0;
        if(this.player_projections[playerId] !== undefined && this.player_projections[playerId][week] !== undefined && this.player_projections[playerId][week]["pts_league"] !== undefined) {
            leaguePtsForWeekFromPlayersProjectionObject = this.player_projections[playerId][week]["pts_league"];
        }
        if(isNaN(leaguePtsForWeekFromPlayersProjectionObject)) {
            return 0;
        }
        return leaguePtsForWeekFromPlayersProjectionObject;
    }

    getPlayersAvgRemainingProjectionPointsBasedOnScoringSettingsFromFullData(playerId, startWeek, endWeek) {
        const totalPoints = this.getPlayerRemainingProjectionPointsFromFullData(playerId, startWeek, endWeek);
        //chase update below to use the correct number of weeks for the player
        return (totalPoints / this.getWeeksArrayWithWeeksText(startWeek, endWeek).length);
    }

    getLeagueScoringSettingsFromObject() {
        return this.leagueObject.scoring_settings.scoring_settings;
    }

    getProjectionWeeksForPlayerWithFullData(playerId) {
        return Object.keys(this.player_projections[playerId]);
    }

    getPlayersArray() {
        return this.players;
    }

    getPlayerProjectionsJsonFromFullData() {
        return this.player_projections
    }

    getOwnerId() {
        return this.owner_id.toString();
    }

    getHasPlayers() {
        return this.userHasPlayers;
    }

    getAllPlayersOnTeam() {
        return this.player_info;
    }
}

// export { UserRoster as default }
export default UserRoster;
// export function getPlayerPointProjectionNew() {};
// export const getPlayerPointProjectionFromUserRoster = UserRoster.prototype.getPlayerPointProjection;
// export const fooMethod = UserRoster.prototype.handleLogicForDifficultScoringSettings;
// export const { getPlayerPointProjectionNew, handleLogicForDifficultScoringSettings } = new UserRoster()
