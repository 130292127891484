import React from "react";
import './CurrentUserTeamPlayerList.css';
import PlayerCard from './PlayerCard';

const CurrentUserTeamPlayerList = (props) => {

    const players = props.players;
    const player_info = props.player_info;
    let teamId = props.team_id;

    const handlePlayerCheck = (event) => {
        const checkedPlayerId = event.target.value;
        const isChecked = event.target.checked;

        if(!teamId) {
            teamId="team_noTeam"
        }
        props.onCheckPlayer(checkedPlayerId, isChecked, teamId, true);
    }

    const listItems = players.map((player) =>
        <li key={"player_" + player}>
            <PlayerCard getCardColor={props.getCardColor} players={players} player_info={player_info} teamId={teamId} player={player} onCheckPlayer={props.onCheckPlayer} isCurrentUser={true}></PlayerCard>
        </li>
    );

    return (
        <div id="currentUserTeamContainer">
            <ul className="playerCardList"> 
                {listItems}
            </ul>
        </div>
    );
};

export default CurrentUserTeamPlayerList;