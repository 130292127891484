import React, { useState } from "react";
import './OpponentPlayerList.css';
import PlayerCard from './PlayerCard';

//Props:
// onCheckPlayer={props.onCheckPlayer} 
// userRosterObject={current_roster}  <-- a UserRoster object
// players={leagueRosters[teamIndexInLeagueRosters].players} 
// player_info={leagueRosters[teamIndexInLeagueRosters].player_info} 
// teamId={"team_" + current_roster.getUserID()}

const PlayerList2 = (props) => {
    const [ isExpanded, setExpanded ] = useState(false);
    
    const players = props.players;
    const player_info = props.player_info;
    const userRosterObject = props.userRosterObject;

    const handlePlayerCheck = (event) => {
        const checkedPlayerId = event.target.value;
        const isChecked = event.target.checked;
        let teamId;
        let parent = event.target.parentElement;
        while(!teamId && parent) {
            if(parent.id.includes("team_")) {
                teamId = parent.id;
            }
            parent = parent.parentElement;
        }
        if(!teamId) {
            teamId="team_noTeam"
        }
        props.onCheckPlayer(checkedPlayerId, isChecked, teamId, false);
    }

    //chase figure out way to collapse other team when another is expanded
    function handleOnClick() {
        // Do more stuff with the click event!
        // Or, set isExpanded conditionally 
        //document.querySelectorAll('.opponentPlayerCheckbox').forEach( el => el.checked = false );

        //var opponentCheckbox = document.querySelectorAll('.opponentPlayerCheckbox').forEach( el => el.checked = false );


        // var opponentCheckbox = document.getElementsByClassName('opponentPlayerCheckbox');
        // for(let checkbox of opponentCheckbox) {
        //     if (checkbox.checked) {
        //         checkbox.dispatchEvent(new Event('change'));
        //         //checkbox.onChange();
        //     }
        // }

        let checkedPlayers = document.querySelectorAll("input[class='opponentPlayerCheckbox']:checked");
        for( var i = 0; i < checkedPlayers.length; i++){ 
            checkedPlayers[i].click();
        }

        setExpanded(!isExpanded);
    }

    const listItemsOld = userRosterObject.roster_data.players.map((player) =>
        <li key={"player_" + player}>
            <input onChange={handlePlayerCheck} className="opponentPlayerCheckbox" type="checkbox" value={player}/>{userRosterObject.roster_data.player_info[player].player_name && userRosterObject.roster_data.player_info[player].player_name} {userRosterObject.roster_data.player_info[player].player_name && "/"} {userRosterObject.roster_data.player_info[player].player_position} / {userRosterObject.roster_data.player_info[player].player_team}
        </li>
    );

    const listItems = userRosterObject.roster_data.players.map((player) =>
        <li key={"player_" + player}>
            <PlayerCard allPlayerValuesSet={props.allPlayerValuesSet} getCardColor={props.getCardColor} players={userRosterObject.roster_data.players} player_info={userRosterObject.roster_data.player_info} teamId={props.teamId} player={player} onCheckPlayer={props.onCheckPlayer} isCurrentUser={false}></PlayerCard>
        </li>
    );

    return (
        <div id="collapsePlayerList" data-testid="singleOpponentTeamDiv" className="collapse">
            <ul className="playerCardList"> 
                {listItems}
            </ul>

        </div>
    );
}

export default PlayerList2;