import React, {useRef} from "react";
import SiteSelector from "../TradeData/SiteSelector";
import HeaderMain from "../Header/HeaderMain";
import './MainContent.css';
import tailoredImage from '../../assets/tailor_small.jpg';
import forecastImage from '../../assets/Forecast_small.jpg';
import indepthImage from '../../assets/indepth_small.jpg';
import valuableImage from '../../assets/valuable_small.jpg';

import { useNavigate } from "react-router-dom";
import AboutSection from "./AboutSection";
import { HashLink as Link2 } from 'react-router-hash-link';


const MainContent = (props) => {
    const navigate = useNavigate();

    const refToTop = useRef();
    const goToTop = () => {
        refToTop.current && refToTop.current.scrollIntoView();
    }
        
    return (
        <div ref={refToTop} id="bodyContent" className="mainPageContent">
            <div >
                <HeaderMain></HeaderMain>
            </div>
            <div id="mainContent">
                <SiteSelector id="tradeDataContent"> </SiteSelector>
                <div id="about">
                    <AboutSection id='tailored' bg='darkAboutSection' image={tailoredImage} sectionHighlight='Tailored' sectionHeader='No Generic Trade Grades' sectionInfo="Fantasy Trade Grade takes your league, teams, and roster into account when determining grades and scores."></AboutSection>
                    <AboutSection id='grades' bg='lightAboutSection' image={forecastImage} sectionHighlight='Forecast' sectionHeader='Project Future Impact' sectionInfo="Use the latest projections to help envision a breakdown of your upcoming schedule and potential impact to your team if your trade were to go through."></AboutSection>
                    <AboutSection id='in-depth' bg='darkAboutSection' image={indepthImage} sectionHighlight='In-Depth' sectionHeader='Instant Analysis Of Potential Trades' sectionInfo="Instantly receive an in-depth analysis of your trade, informing you of projected player values, changes in your team’s projected score, and a realistic lineup post-trade."></AboutSection>
                    <AboutSection id='valuable' bg='lightAboutSection' image={valuableImage} sectionHighlight='Valuable' sectionHeader='Save Yourself From Mistakes' sectionInfo="Don’t make the mistake of accepting a trade without realizing the negative affect it could have on your team, and ruin your chance to win your league."></AboutSection>
                </div>
                {/* <button onClick={() => navigate('/#root')} id='getStartedButton' className='siteButton boldFont'>Get Started</button> */}
                <Link2 onClick={goToTop} id='getStartedButton' className='siteButton boldFont'>Get Started</Link2>
            </div>
           
        </div>
        
    );

};



export default MainContent;