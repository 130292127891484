import React from "react";
import { useNavigate } from "react-router-dom";
import './SiteSelector.css';
import { Tooltip, OverlayTrigger } from "react-bootstrap";

//Update this to allow user to actually select site once there are more
const SiteSelector = (props) => {
    const navigate = useNavigate();

    const sleeperType = 'sleeper';

    const comingSoonTooltip = (
        <Tooltip id="comingSoonTooltip">
            <strong>Coming soon!</strong>
        </Tooltip>
    );

    return (
        <div id="siteSelector" className="twoColumnRow">
            <div id="heroText" className="twoColumn boldFont">
                    Select Your<br/>
                    Fantasy Site
            </div>
            <div id="siteButtons" className="twoColumn">
                <div>
                    <button id="sleeperSelector" data-testid="sleeperSelectorButton" onClick={() => navigate('enterusername?leaguetype=' + sleeperType)}  className='siteButton boldFont'>Sleeper</button>
                    <OverlayTrigger placement="right" overlay={comingSoonTooltip}>
                        <button className='siteButton boldFont disabledButtonCustom'>Yahoo!</button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={comingSoonTooltip}>
                        <button className='siteButton boldFont disabledButtonCustom'>ESPN</button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={comingSoonTooltip}>
                        <button className='siteButton boldFont disabledButtonCustom'>NFL</button>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
        
    );

};



export default SiteSelector;