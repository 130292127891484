import React from "react";
import './PlayerCardDetailed.css';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const PlayerCardDetailed = (props) => {

    const valueTooltip = (
        <Tooltip id="valueTooltip">
            <strong>100 is max, 0 is min and equivalent to a replacement free agent at that position. </strong>
            Value is a score based on a player's projected points above the best replacement free agents at their position.
        </Tooltip>
    )

    const projRemainingPointsTooltip = (
        <Tooltip id="projRemainingPointsTooltip">
            <strong>Total points projected to score in this league for the remainder of the selected weeks.</strong>
        </Tooltip>
    )

    return (
        <div data-testid="playerCardDetailed" className={"playerCardDetailed boldFont twoColumnRow " + props.getCardColor(props.playerCardDetailedInfo.player_position) }>
            <div className="playerCardDetailedStartColumn twoColumn">
                <div className='playerCardDetailedName'>{props.playerCardDetailedInfo.player_name}</div>
                <OverlayTrigger placement="left" overlay={projRemainingPointsTooltip}>
                    <div className='playerCardDetailedProjRemainingPointsText playerCardDetailText'>Proj. Remaining Pts: </div>
                </OverlayTrigger>
                <OverlayTrigger placement="left" overlay={valueTooltip}>
                    <div className='playerCardValueText playerCardDetailText'>Value: </div>
                </OverlayTrigger>
            </div>
            <div  className="playerCardDetailedEndColumn twoColumn">
                <div className='playerCardDetailedPositionTeam'>{props.playerCardDetailedInfo.player_position} - {props.playerCardDetailedInfo.player_team}</div>
                <div className='playerCardDetailedRemainingProjectionPoints playerCardDetailText'>{props.playerCardDetailedInfo.player_remaining_projection_points}</div>
                <div className='playerCardValue playerCardDetailText'>{props.playerCardDetailedInfo.player_value}</div>
            </div>
        </div>
    );

}

export default PlayerCardDetailed;