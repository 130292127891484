//import axios from "axios";
import React, { useState, useRef } from "react";
import './UsernameForm.css';
import { useNavigate, useSearchParams  } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const UsernameForm = (props) => {
    const navigate = useNavigate();

    //const nameInputRef = useRef(); //chase you can use a ref like this later for player search? Or to reset ?
    let [enteredUsername, setEnteredUsername] = useState('');
    let [usernameEndpointResponse, setUsernameEndpointResponse] = useState([]);
    let [isLoadingUserName, setIsLoadingUserName] = useState(false);
    let [badEndpointResponseMessage, setBadEndpointResponseMessage] = useState('');
    let [enteredUsernameIsValid, setEnteredUsernameIsValid] = useState(false);
    let [invalidLeagueType, setInvalidLeagueType] = useState(false);

    const ref = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const leagueType = searchParams.get('leaguetype');

    const sleeperUsernameEndpoint ="https://api.sleeper.app/v1/user/"

    const nameInputChangeHandler = event => {
        setEnteredUsername(event.target.value);
    };

    //Handle username input submit and fetch.
    const formSubmissionHandler = async event => {
        //Prevent submit from sending form input to server
        event.preventDefault();

        //setTrigger((trigger) => trigger + 1);
        if(ref.current) {
            ref.current.clear();
        }

        if(enteredUsername.trim() === '') {
            return;
        }

        setIsLoadingUserName(true);
        // if(leagueSelectorElement.current != null) {
        //     leagueSelectorElement.current.clearState();
        // }

        let usernameJson = await getUsernameJsonFromEndpoint(enteredUsername);
        setIsLoadingUserName(false);
        if(usernameJson != null) {
            setBadEndpointResponseMessage('');
            setEnteredUsernameIsValid(true);
            setUsernameEndpointResponse(usernameJson);
            // let routerPath = '/' + leagueType + '/' + enteredUsername.toLowerCase() + '/' + usernameJson.display_name + '/' + usernameJson.user_id;
            let routerPath = '/leagueselector?' + 'leaguetype=' + leagueType + '&username=' + enteredUsername.toLowerCase() + '&displayname=' + usernameJson.display_name + '&user_id=' + usernameJson.user_id;
            navigate(routerPath);
        } else {
            setEnteredUsernameIsValid(false);
            setBadEndpointResponseMessage(enteredUsername + " was not returned as a valid Sleeper username");
        }

        //https://api.sleeper.app/v1/user/<username>
        //const enteredValue = nameInputRef.current.value;
        //console.log(enteredValue);

    };


    function getLeagueTypeText() {
        if(leagueType === "sleeper") {
            return "Sleeper";
        }
        setInvalidLeagueType(true);
        return "<Not a correct league type>";
    }

    async function getUsernameJsonFromEndpoint(username) {
        if(leagueType === "sleeper") {
            try {
                const endpoint = sleeperUsernameEndpoint + username;
                //const response2 = await axios.get(endpoint);
                const response = await fetch(endpoint);
                const json = await response.json();
                return json;
            } catch (e) {
                return null;
            }
            
        }
        
    }

    const usernameToolTip = (
        <Tooltip id="usernameToolTip">
            Use example username <strong>fantasytrdgrd</strong> to test.
        </Tooltip>
    )

    const handleNavigateBack = () => {
        setEnteredUsernameIsValid(false);
    };


    return (
        <div className="boldFont mainPageContent">
            <div className="pageHeader">
                <div>
                    {enteredUsernameIsValid && usernameEndpointResponse.user_id !== undefined &&
                        <i onClick={handleNavigateBack} className="bi bi-arrow-left backButton hoverCursor"></i>
                    }
                </div>
            </div>

            {!invalidLeagueType && (!enteredUsernameIsValid || usernameEndpointResponse.user_id === undefined) && 
                <div>
                    <form className= "centered usernameForm" onSubmit={formSubmissionHandler}>
                        <div className="enterUsernameLabelParent centeredDiv">
                            <h1 data-testid="search-query-leaguetype-parameter" className="enterUsernameLabel boldFont largeHeader">Enter {getLeagueTypeText()} Username</h1>
                        </div>                
                        <div id="usernameDiv">
                            <input 
                                //ref={nameInputRef}
                                type='text' 
                                id='username'
                                className="boldFont inputFieldMatchingButton"
                                placeholder="Username" 
                                onChange={nameInputChangeHandler} 
                            />
                            <OverlayTrigger placement="top" overlay={usernameToolTip}>
                                <i id="usernameTooltip" data-testid='usernameTooltip' className="bi bi-info-circle"></i>
                            </OverlayTrigger>
                        </div>
                        <div>
                            <button onClick={formSubmissionHandler} data-testid="submitUsernameButton" name="submitUsernameButton" id='submitUsernameButton' className='siteButton boldFont'>Show Leagues</button>
                        </div>
                        {!enteredUsernameIsValid && badEndpointResponseMessage !== '' && 
                            <div className="badEndpointResponse">
                                {badEndpointResponseMessage}
                            </div>
                        }
                    </form> 
                </div>
            }
            {invalidLeagueType && 
                <h1 id="invalidLeagueTypeParam" className="boldFont header warningText">
                    Invalid leagueType parameter in URL. <br/>
                    Navigate home and use a valid button for your fantasy site.
                </h1>
            }
            {/* {enteredUsernameIsValid && usernameEndpointResponse.user_id !== undefined && 
                <div>
                    <LeagueSelector leagueType={props.leagueType} onBackClick={handleNavigateBack} ref={ref} username={usernameEndpointResponse.username} displayName={usernameEndpointResponse.display_name} user_id={usernameEndpointResponse.user_id}></LeagueSelector>
                </div>
            } */}
        </div>
        
    );

};



export default UsernameForm;