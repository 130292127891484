import { useEffect, useRef, useState } from "react";
import './SearchableDropdown.css';

const SearchableDropdown = ({
  options,
  name,
  team,
  position,
  playerId,
  ownerId,
  value,
  id,
  selectedVal,
  handleChange,
  placeholder,
  searchableDropdownSelectionHandler,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option[name]);
    searchableDropdownSelectionHandler(option[playerId], option[ownerId]);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  const playerDiv = (option) => {
    return (
      <div className="dropdownOption" data-ownderid={option[ownerId]} data-playerid={option[playerId]} data-playername={option[name]} data-team={option[team]} data-position={option[position]} data-value={option[value]} id={option[playerId]} key={option[playerId]}>
        <span>{option[name]}</span>
        <span className="dropdownTeamSpan">{option[position]} - {option[team]} ({option[value]})</span>
      </div>
    );
  }

  const filter = (options) => {
    return options.filter(
      (option) => option[1][name].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <div id={id} className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
            placeholder={placeholder}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option[1])}
              className={`option ${
                option[1][name] === selectedVal ? "selected" : ""
              }`}
              key={`${id}-${index}`}
            >
              {playerDiv(option[1])}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
