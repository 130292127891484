import React, { useState, useEffect } from "react";
import PlayerCardDetailed from "./PlayerCardDetailed";
import WeeklyBreakdown from "./WeeklyBreakdown";
import './TradeBreakdownView.css';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { maxWeightAssign } from 'munkres-algorithm';


//Props:
// leagueType={props.leagueType} 
// leagueObject = {props.leagueObject} 
// currentUsersRosterObject={currentUsersRosterObject} 
// leagueUsersRosterObject={leagueUsersRosterObject} 
// currentlySelectedUserPlayers={currentlySelectedUserPlayers} 
// currentlySelectedOpponentPlayers={currentlySelectedOpponentPlayers} 
// currentUserRoster={currentUserRoster} 
// leagueRosters={props.selectedLeagueRosters} 
// tradeBreakdownLeagueSettings={props.leagueTradeBreakdownSettings}   


//LOOPING BECAUSE OF ALL OF THE getWeeklyNetPointsRender(userWeekByWeekPointsProjection, opponentWeekByWeekPointsProjection, true) CAUSING RE-RENDERS
const TradeBreakdownView2 = (props) => {
    const leagueObject = props.leagueObject;
    const currentUsersRosterObject = props.currentUsersRosterObject;
    const leagueUsersRosterObject = props.leagueUsersRosterObject;
    const userSettings = props.settings;
    const startWeek = userSettings.startWeek;
    const endWeek = userSettings.endWeek;
    const totalWeeks = (endWeek - startWeek) + 1;
    const playerValueGradeWeight = userSettings.playerValueGradeWeight / 100;
    const pointDifferentialGradeWeight = userSettings.pointDifferentialGradeWeight / 100; 
    const useNegativePlayerValues = userSettings.useNegativePlayerValues;
    const useReplacementPlayers = userSettings.useReplacementPlayers;


    //chase store projected lineup for trade ?? Only change/re-do if the team ID has changed? (especially user one...)
    const currentlySelectedUserPlayers = props.currentlySelectedUserPlayers;
    const currentlySelectedOpponentPlayers = props.currentlySelectedOpponentPlayers;

    const [isLoading, setIsLoading] = useState(true);

    const listOfPositionsToIgnore = ["BN", "projectedPointTotal"];

    const [tradeGradeForUser, setTradeGradeForUser] = useState('F');
    const [tradeGradeForOpponent, setTradeGradeForOpponent] = useState('F');
    const [tradeGradeForUserForCss, setTradeGradeForUserForCss] = useState('F');
    const [tradeGradeForOpponentForCss, setTradeGradeForOpponentForCss] = useState('F');
    const [usersPointDifferentialGrade, setUsersPointDifferentialGrade] = useState('F');
    const [opponentsPointDifferentialGrade, setOpponentsPointDifferentialGrade] = useState('F');
    const [usersTradeValueGrade, setUsersTradeValueGrade] = useState('F');
    const [opponentsTradeValueGrade, setOpponentsTradeValueGrade] = useState('F');
    const [usersPointDifferentialGradeForCss, setUsersPointDifferentialGradeForCss] = useState('F');
    const [opponentsPointDifferentialGradeForCss, setOpponentsPointDifferentialGradeForCss] = useState('F');
    const [usersTradeValueGradeForCss, setUsersTradeValueGradeForCss] = useState('F');
    const [opponentsTradeValueGradeForCss, setOpponentsTradeValueGradeForCss] = useState('F');

    const [weeklyNetPointsRender, setWeeklyNetPointsRender] = useState(null);
    const [usersAverageProjectedPointDifferencePostTrade, setUsersAverageProjectedPointDifferencePostTrade] = useState(0);
    const [opponentsAverageProjectedPointDifferencePostTrade, setOpponentsAverageProjectedPointDifferencePostTrade] = useState(0);
    const [totalTradeValue, setUsersTotalTradeValue] = useState(0);

    let cumulativeUserPoints = 0;
    let userWeekByWeekPointsProjection = {};
    let cumulativeOpponentPoints = 0;
    let opponentWeekByWeekPointsProjection = {};
    let selectedOpponentRoster = getSelectedOpponentRoster(leagueUsersRosterObject, currentlySelectedOpponentPlayers);
    let preTradeUserStartingLineup = getProjectedStartingLineup(currentUsersRosterObject, leagueObject);
    let preTradeOpponentStartingLineup = getProjectedStartingLineup(selectedOpponentRoster, leagueObject);
    const [postTradeUserStartingLineup, setPostTradeUserStartingLineup] = useState(null);
    const [postTradeOpponentStartingLineup, setPostTradeOpponentStartingLineup] = useState(null);
    let usersPostTradeWeeklyNetPointDifference = {};
    let opponentsPostTradeWeeklyNetPointDifference = {};
    


    useEffect(() => {
        getWeeklyProjectedNetPointsRender(currentlySelectedUserPlayers, currentlySelectedOpponentPlayers, preTradeUserStartingLineup, preTradeOpponentStartingLineup, currentUsersRosterObject, selectedOpponentRoster, leagueObject);
        setTradeGrades(usersPostTradeWeeklyNetPointDifference, opponentsPostTradeWeeklyNetPointDifference, currentlySelectedUserPlayers, currentlySelectedOpponentPlayers);
        setIsLoading(false);
    }, [currentlySelectedUserPlayers, currentlySelectedOpponentPlayers, isLoading, props.settings] );

    //Update this to get points specific to a league's settings...
    function getPlayersRemainingPointsProjection(playerProjectionWeeks, isCurrentUser) {
        let playersTotalRemainingPointsProjected = 0;

        //chase update this to create new json object that adds each teams weekly gain from this player to a pre-existing amount for that week
        //then as part of a net function, find the net for each week for both teams.
        for(var key in playerProjectionWeeks) {
            if(playerProjectionWeeks.hasOwnProperty(key)) {
                if(playerProjectionWeeks[key]["pts_league"]) {
                    let playersSelectedWeekPointsProjection = Math.round(playerProjectionWeeks[key]["pts_league"]);
                    playersTotalRemainingPointsProjected += playersSelectedWeekPointsProjection;
                    if(isCurrentUser) {
                        userWeekByWeekPointsProjection = addToWeekByWeekPointsProjectionJson(userWeekByWeekPointsProjection, playersSelectedWeekPointsProjection, key);
                    } else {
                        opponentWeekByWeekPointsProjection = addToWeekByWeekPointsProjectionJson(opponentWeekByWeekPointsProjection, playersSelectedWeekPointsProjection, key);
                    }
                }
            }
        }
        let playersTotalRemainingPointsProjectedRounded = Math.round(Number(playersTotalRemainingPointsProjected));

        //Add this player's totals to the cumulative points
        if(isCurrentUser) {
            cumulativeUserPoints = cumulativeUserPoints + playersTotalRemainingPointsProjectedRounded;
        } else {
            cumulativeOpponentPoints = cumulativeOpponentPoints + playersTotalRemainingPointsProjectedRounded;
        }

        return playersTotalRemainingPointsProjectedRounded;
    }

    function addToWeekByWeekPointsProjectionJson(weekByWeekPointsProjectionJson, weeklyPlayerProjection, key) {
        if(!weekByWeekPointsProjectionJson[key]) {
            weekByWeekPointsProjectionJson[key] = {};
        }
        if(!weekByWeekPointsProjectionJson[key]["projected_points"]) {
            weekByWeekPointsProjectionJson[key]["projected_points"] = weeklyPlayerProjection;
        } else {
            weekByWeekPointsProjectionJson[key]["projected_points"] = weekByWeekPointsProjectionJson[key]["projected_points"] + weeklyPlayerProjection;
        }

        return weekByWeekPointsProjectionJson;
    }

   

    function getProjectedStartingLineup(roster, leagueSettings) {
        if(!roster) {
            return "No Selected Players";
        }
        let leaguePositions = leagueSettings.roster_positions.roster_positions;
        let playerJson;
        //chase - this should be done better, the first usage happens when there's no opponent team, and passes in the entire object
        //the second happens when picking an opponent player, and only passes a subset. They should be uniform
        if(!roster.roster_data) {
            playerJson = roster.player_info;
        } else {
            playerJson = roster.roster_data.player_info;
        }
        let projectedLineup = {};
        let emptyWeeklyLineup = getRosterSpotCounts(leaguePositions);
        let scoringSettings = leagueSettings.scoring_settings.scoring_settings;

        //Get player projections
        for(let player of Object.entries(playerJson)) {
            let playerProjections;
            if(!roster.roster_data) {
                playerProjections = roster.player_projections[player[0]];
            } else {
                playerProjections = roster.roster_data.player_projections[player[0]];
            }
            playerJson[player[0]]["projections"] = playerProjections;
        }

        //Add empty week projections to projectedLineup JSON
        let firstPlayerProjections = Object.entries(playerJson)[0][1]["projections"];
        for(let week of Object.entries(firstPlayerProjections)) {
            projectedLineup[week[0]] = emptyWeeklyLineup;
        }

        //Get Projections By Week
        for(let week of Object.entries(projectedLineup)) {
            projectedLineup[week[0]] = getProjectedLineupForSingleWeek(projectedLineup[week[0]], playerJson, week[0], scoringSettings);
        }

        projectedLineup = getStartingLineupWeeklyProjectionPointTotals(projectedLineup, scoringSettings, useReplacementPlayers);

        return projectedLineup;
    }

    function fillProjectedLineupWithReplacementPlayers(weeklyLineupJson, useReplacementPlayersForByeOr0) {
        //createReplacementPlayerForLineup
        // player[1] = createReplacementPlayerForLineup(position[0], leagueObject.replacement_level_values[position[0]]);


        for(let position of Object.entries(weeklyLineupJson)) {
            let positionName = position[0];
            if(!listOfPositionsToIgnore.includes(positionName)) {
                // let positionPlayers = position[1].players;

                for(let player of Object.entries(position[1].players)) {
                    if(player[1].player_name === undefined) {
                        let replacementLevelPlayer = createReplacementPlayerForLineup(positionName, leagueObject.replacement_level_values[positionName]);
                        weeklyLineupJson[position[0]].players[player[0]] = replacementLevelPlayer;
                    } else if(player[1].projections === undefined) {
                        //chase - Why is this a thing? - if we have a player with a name with no projections... we don't want to use their name and give them a replacement projection.. do we?
                        let replacementLevelPlayer = createReplacementPlayerForLineupWithName(player[1].player_name, positionName, leagueObject.replacement_level_values[positionName]);
                        weeklyLineupJson[position[0]].players[player[0]] = replacementLevelPlayer;
                    } else if(player[1].projections.pts_league === undefined) {
                        let replacementLevelPlayer = createReplacementPlayerForLineup(positionName, leagueObject.replacement_level_values[positionName]);
                        weeklyLineupJson[position[0]].players[player[0]] = replacementLevelPlayer;
                        weeklyLineupJson = addPlayerToBench(weeklyLineupJson, (getCurrentNumBenchSpotsForLineup(weeklyLineupJson) + 1), player[1]);
                    } else if(useReplacementPlayersForByeOr0 && player[1].projections.pts_league === 0) {
                        //If someone is projected to score 0 (bye or other), that means there was no one better already on the team, replace them with a replacement level player
                        let replacementLevelPlayer = createReplacementPlayerForLineup(positionName, leagueObject.replacement_level_values[positionName]);
                        weeklyLineupJson[position[0]].players[player[0]] = replacementLevelPlayer;
                        weeklyLineupJson = addPlayerToBench(weeklyLineupJson, (getCurrentNumBenchSpotsForLineup(weeklyLineupJson) + 1), player[1]);
                    }

                }

                // let numSpotsInPosition = positionPlayers[positionName];
                // if(numPlayersInPosition < numSpotsInPosition) {
                //     let numPlayersToAdd = numSpotsInPosition - numPlayersInPosition;
                //     for(let i = 0; i < numPlayersToAdd; i++) {
                //         let replacementPlayer = getRandomReplacementPlayer(positionName);
                //         positionPlayers[replacementPlayer.id] = replacementPlayer;
                //     }
                // }
            }
        }

        return weeklyLineupJson;
    }
    
    function getCurrentNumBenchSpotsForLineup(weeklyLineupJson) {
        return weeklyLineupJson.BN.numSpots !== undefined ? Number(weeklyLineupJson.BN.numSpots) : Object.keys(weeklyLineupJson.BN.players).length;
    } 

    function getProjectedLineupForSingleWeek(originalWeeklyLineupJson, playerJson, weekName, scoringSettings) {
        let benchIndex = 1;
        let weeklyLineupJson = JSON.parse(JSON.stringify(originalWeeklyLineupJson));

        //get all players in position, put in new array with projection and ID, then sort and fill array with top N entries where N is numSpots for position
        for(let position of Object.entries(originalWeeklyLineupJson)) {
            let positionName = position[0];
            if(!listOfPositionsToIgnore.includes(positionName) && !positionName.includes("FLEX")) {

                let allPlayersOfPosition = []; //small array with entries of just the player's id(key) and projectedPoints(value)
                for(let player of Object.entries(playerJson)) {
                    let playerPosition = player[1].player_position;
                    if(playerPosition === positionName || playerCanPlayInPosition(playerPosition, positionName)) {
                        let playerId = player[0];
                        //let playerProjection = Number(player[1].projections["pts_half_ppr"]);
                        //let playerProjection = Number(player[1].projections[weekName]["pts_half_ppr"]);
                        //chasetodo: This can just be re-factored to get the projection pts_league ?
                        //except rn I'm getting nan for projections...?
                        let playerProjection = getPlayerPointProjection(player[1].projections[weekName], scoringSettings);
                        if(isNaN(playerProjection)) {
                            player[1].projections[weekName]["pts_half_ppr"] = 0;
                            player[1].projections[weekName]["pts_league"] = 0;
                            playerProjection = Number(0);
                        }
                        let playerEntry = {id: playerId, projectedPoints: playerProjection};
                        allPlayersOfPosition.push(playerEntry)
                        //allPlayersOfPosition[playerId] = playerProjection;
                    }  
                }

                //sort players of position by projected points
                if(allPlayersOfPosition.length > 1) {
                    //sort allPlayersOfPosition by value
                    allPlayersOfPosition.sort(function(a, b){return b.projectedPoints - a.projectedPoints});
                }

                //for all players in allPlayersOfPosition, push them into weeklyLineupJson for that position where it is currently 'N/A'
                //account for the possibility that there could be 2 QB spots, but the roster could only have 1 (maybe pop empty json with projection of 0 there, or just account for 'N/A' when the json is read)
                let numSpotsForPosition = weeklyLineupJson[positionName].numSpots;
                for(let player of allPlayersOfPosition.entries()) {
                    let playerId = player[1].id;
                    let playersFullProjectionJson = JSON.parse(JSON.stringify(playerJson[playerId])); //user Player ID to get player entry in full playerJson
                    //chase the below line (I think), is wiping the previous week??
                    //chase something is setting week_17 and week_18  to be the same? I think it might be this, setting the projection that is eventually passed back in?? Might need to maek a copy of a NEW json here that gets returned or something
                    playersFullProjectionJson["projections"] = playersFullProjectionJson["projections"][weekName]; //remove other weeks 
                    let playerIndexInSortedArary = player[0]+1; //+1 to match numSpots which starts at 1
                    if(playerIndexInSortedArary <= numSpotsForPosition) {
                        //add player's full playerJson to Weekly Lineup
                        //weeklyLineupJson[positionName]["players"]["player"+playerIndexInSortedArary] = playersFullProjectionJson;

                        weeklyLineupJson = addPlayerToLineupSpot(positionName, weeklyLineupJson, playerIndexInSortedArary, playersFullProjectionJson);
                    } else {
                        //Throw player to bench
                        weeklyLineupJson = addPlayerToBench(weeklyLineupJson, benchIndex,  playersFullProjectionJson);
                        benchIndex++;
                    }
                    
                }
            }
        }

        let flexPositions = Object.entries(weeklyLineupJson).filter(position => position[0].includes("FLEX"));
        weeklyLineupJson = fillFlexSpotsWithMaxFlexPoints(weeklyLineupJson["BN"], flexPositions, weeklyLineupJson);

        //There could potentially be an option to only use replacement players in the event of not having enough players post-trade
        //But I think for now we're just keeping it simple and if replacement players is true, use them for any 0 point scorers or un-fillable slots
        let useReplacementPlayersForByeOr0 = true;
        if(useReplacementPlayers) {
            weeklyLineupJson = fillProjectedLineupWithReplacementPlayers(weeklyLineupJson, useReplacementPlayersForByeOr0);
        }
        return weeklyLineupJson;
    }

    //Setup data for hungarian/munkres algorithm, but since we don't have a complete bipartite graph (1:1 for all vertices)
    //  make dummy (Infinity/0) weighted edges between players and FLEX spots that they can't occupy.
    //The 'bench' variable is a list of all bench players. At this point, the flex spots should be empty, any non positional starter should be on the bench
    //Flex positions are all positions in the lineup json that include 'flex' in the name
    //No replacement players should exist yet
    function fillFlexSpotsWithMaxFlexPoints(bench, flexPositions, weeklyLineupJson) {
        //Create an array of all the flex spots (where numspots is reflexed, so there can be 2 "FLEX" spots in the array, consecutively)
        const flexPositionsArray = getAllFlexPositionsArray(flexPositions);
        let benchPlayersMatrix = [];
        const zeroValue = -Infinity; //-Infinity when used in a max weight of maxWeightAssign is the equivalent of 0 (aka do not use this edge because it doesn't actually exist);
        
        //For each bench player:
        for(let player of Object.entries(bench.players)) {
            //Get all flex positions that a player can play ->> ["FLEX", "WRTE_FLEX"... etc.]
            const possibleFlexPositionsForPlayer = leagueObject.getFlexPositionsAllowedForPlayerPositionForThisLeague(player[1].player_position);
            if(player[1].player_position && possibleFlexPositionsForPlayer !== null) { //ignore empty bench spots and players that can't play FLEX
                //Create player score array that matches flex positions:
                //Create array of Infinity's (aka 0's) into new array for player that's the size of the flex spots length (copy of found in step 1 at start of function)
                let playerArray = [...flexPositionsArray];
                //for each entry in new flex spots array:
                for(let i = 0; i < flexPositionsArray.length; i++) {
                    //if the player can play in this flex position and has projected points, set their projected points as the entry in the playerArray
                    if(possibleFlexPositionsForPlayer.includes(flexPositionsArray[i]) && player[1].projections && player[1].projections.pts_league) {
                            playerArray[i] = player[1].projections.pts_league;                    
                    } else {
                        playerArray[i] = zeroValue;
                    }
                }
                benchPlayersMatrix.push(playerArray);
            } else {
                let playerArray = new Array(flexPositionsArray.length).fill(zeroValue);
                benchPlayersMatrix.push(playerArray);
            }
        }
            
        //imported maxWeightAssign uses  hungarian/munkres algorithm to get an array where each bench player is represented as the key in the same order as they are in the bench,
        //and their assignment is the value, where the value represents the index in the flexPositionsArray that they should be placed in.
        //i.e. benchPlayer(0) will be first in this array, and their value (let's say it's 2) represents that they should go 
        //into the flex spot that matches what's found in flexPositionsArray(2)
        const benchPlayersFlexAssignments = maxWeightAssign(benchPlayersMatrix);

        //create a flex lineup using the above array:
        for(let i = 0; i < benchPlayersFlexAssignments.assignments.length; i++) {
            if(benchPlayersFlexAssignments.assignments[i] !== null) {
                const playersIntendedFlexPosName = flexPositionsArray[benchPlayersFlexAssignments.assignments[i]];
                let flexPositionJson = weeklyLineupJson[playersIntendedFlexPosName];
                //There can be multiple of the same flex spots, fill the empty ones
                for(let j = 1; j <= Object.entries(flexPositionJson.players).length; j++) {
                    if(!flexPositionJson.players["player"+ j].projections) {
                        addPlayerToLineupSpot(playersIntendedFlexPosName, weeklyLineupJson, j, bench.players["player" + (i+1)]); //i+1 because positions start with "player1" and we started i at 0
                        //Remove player from bench? bench is object setup like player1: -player-
                        //If I just remove "player4" from the middle, will it have a negative effect, or do we always just loop from this point on?
                        delete weeklyLineupJson["BN"]["players"]["player"+(i+1)];
                        weeklyLineupJson["BN"]["numSpots"] = weeklyLineupJson["BN"]["numSpots"]- 1;
                        break;
                    }
                }
            } else {
                //put/leave player on bench
            }
        }

        return weeklyLineupJson;
    }

    //Returns an array of all the flex positions i.e. ["FLEX", "FLEX", "WRRB_FLEX"...]
    //This should return duplicates in the event that there are multiple of the same flex spots
    function getAllFlexPositionsArray(flexPositions) {
        let returnArray = [];
        for(let flexPosition of flexPositions) {
            let flexPositionName = flexPosition[0];
            returnArray.push(...new Array(flexPosition[1].numSpots).fill(flexPositionName));
            // for(let i = 0; i < flexPosition[1].numSpots; i++) {
            //     returnArray.push(flexPositionName);
            // }
        }
        return returnArray;
    }

    function playerCanPlayInPosition(playerPosition, positionName) {
        if((playerPosition === 'DT' || playerPosition === 'DE') && positionName === 'DL') {
            return true;
        }

        if(playerPosition === 'CB' && positionName === 'DB') {
            return true;
        }
    }
    

    //chase verify this adds new BN spots if needed
    function addPlayerToBench(weeklyLineupJson, benchIndex, playersFullProjectionJson) {
        weeklyLineupJson["BN"]["players"]["player"+benchIndex] = playersFullProjectionJson;
        if(benchIndex > weeklyLineupJson["BN"].numSpots) {
            weeklyLineupJson["BN"].numSpots = benchIndex;
        }
        return weeklyLineupJson;
    }

    function addPlayerToLineupSpot(positionName, weeklyLineupJson, playerIndex, playersFullProjectionJson) {
        weeklyLineupJson[positionName]["players"]["player"+playerIndex] = playersFullProjectionJson;
        
        return weeklyLineupJson;
    }

    function getRosterSpotCounts(leaguePositionsArray) {
        let positionsJson = {};
        for(let position of leaguePositionsArray.entries()) {
            if(!positionsJson[position[1]]) {
                positionsJson[position[1]] = {"numSpots": 1, "players" : {"player1" : {}}};
            } else {
                //increment numSpots for position
                positionsJson[position[1]]["numSpots"] = Number(positionsJson[position[1]]["numSpots"]) + 1;
                //Add an Nth spot for that position player, i.e. "player2"
                positionsJson[position[1]]["players"]["player"+ positionsJson[position[1]]["numSpots"]] = {};
            }
        }
        return positionsJson;
    }

    function getSelectedOpponentRoster(leagueRosters, selectedPlayers) {
        if(selectedPlayers.length > 0) {
            let teamId = selectedPlayers[0].teamId.replace("team_", "");
            for(let roster of leagueRosters.userList.entries()) {
                let rosterOwnerId = roster[1].user_id;
                if(teamId === rosterOwnerId) {
                    return roster[1];
                }
            }
        } 
    }

    //
    // {getWeeklyProjectedNetPointsRender(currentlySelectedUserPlayers, currentlySelectedOpponentPlayers, preTradeUserStartingLineup, preTradeOpponentStartingLineup, currentUsersRosterObject, selectedOpponentRoster, leagueObject, true)}

    function getWeeklyProjectedNetPointsRender(currentlySelectedUserPlayers, currentlySelectedOpponentPlayers, preTradeUserStartingLineup, preTradeOpponentStartingLineup, currentUserRoster, selectedOpponentRoster, leagueSettingsObject) {
        let returnList= [];
        let scoringSettings = leagueSettingsObject.scoring_settings.scoring_settings;

        if(currentlySelectedOpponentPlayers.length > 0) {
            

            //put currently selected opponent players into new array/json that matches 'roster' in getProjectedStartingLineup (passed as currentUserRoster)
            let newUserRoster = JSON.parse(JSON.stringify(currentUserRoster.roster_data));
            let newOpponentRoster = JSON.parse(JSON.stringify(selectedOpponentRoster));
            swapPlayersOnRosters(newUserRoster, newOpponentRoster, currentlySelectedUserPlayers, currentlySelectedOpponentPlayers);

                        
            //call getProjectedStartingLineup() with these new rosters and leagueRosterSettings (might have to pass those into this function)
            let newUserStartingLineup = getProjectedStartingLineup(newUserRoster, leagueSettingsObject);
            let newOpponentStartingLineup = getProjectedStartingLineup(newOpponentRoster, leagueSettingsObject);

            //calculate the points each week of all the non-BN players in the pre-trade starting lineup
            // preTradeUserStartingLineup = getStartingLineupWeeklyProjectionPointTotals(preTradeUserStartingLineup);
            // preTradeOpponentStartingLineup = getStartingLineupWeeklyProjectionPointTotals(preTradeOpponentStartingLineup);

            //calculate the points each week of all the non-BN players in this new starting lineup
            newUserStartingLineup = getStartingLineupWeeklyProjectionPointTotals(newUserStartingLineup, scoringSettings, useReplacementPlayers);
            newOpponentStartingLineup = getStartingLineupWeeklyProjectionPointTotals(newOpponentStartingLineup, scoringSettings, useReplacementPlayers);
            setPostTradeUserStartingLineup(newUserStartingLineup);
            setPostTradeOpponentStartingLineup(newOpponentStartingLineup);

            
            //compare week by week and output in list
            usersPostTradeWeeklyNetPointDifference = getWeeklyNetPointDifference(newUserStartingLineup, preTradeUserStartingLineup);
            opponentsPostTradeWeeklyNetPointDifference = getWeeklyNetPointDifference(newOpponentStartingLineup, preTradeOpponentStartingLineup);

            returnList = getWeeklyNetProjectedPointDifferenceListRender(usersPostTradeWeeklyNetPointDifference);
        }
        // return returnList;
        setWeeklyNetPointsRender(returnList);
    }

    function getWeeklyNetPointDifference(newLineup, preTradeLineup) {
        let weekList = {};
        for(let week of Object.entries(newLineup)) {
            let newWeekTotal = week[1].projectedPointTotal;
            let preTradeWeekTotal = preTradeLineup[week[0]].projectedPointTotal;
            let netDifference = newWeekTotal - preTradeWeekTotal;
            weekList[week[0]] = Math.round((netDifference + Number.EPSILON) * 100) / 100;
        }
        return weekList;
    }

    //Given a startingLineup list that is a json object of the starters for each week remaining, and the league scoring settings
    //Return the startingLineup with a "projectedPointTotal" entry added to each week
    function getStartingLineupWeeklyProjectionPointTotals(startingLineup, scoringSettings, useReplacements) {
        
        for(let week of Object.entries(startingLineup)) {
            let playerPointProjectionForWeek = 0;
            week[1]["projectedPointTotal"] = 0;
            for(let position of Object.entries(week[1])) {
                if(!listOfPositionsToIgnore.includes(position[0])) {
                        for(let player of Object.entries(position[1].players)) {
                            if(player[1].projections !== undefined || !useReplacements) {
                                if(player[1].projections && player[1].projections.pts_league) {
                                    playerPointProjectionForWeek = playerPointProjectionForWeek + player[1].projections.pts_league;
                                } else {
                                    //Player doesn't have a projection for this week, and replacements are disabled, don't add anything
                                }
                            } else {
                                //chase update this to create an object for this spot that sets name, position, etc. as replacement player , team: N/A, etc.
                                // player[1] = createReplacementPlayerForLineup(position[0], leagueObject.replacement_level_values[position[0]]);
                                playerPointProjectionForWeek = playerPointProjectionForWeek + leagueObject.replacement_level_values[position[0]];
                            }
                        }
                } 
            }
            week[1]["projectedPointTotal"] = playerPointProjectionForWeek;
        }
        return startingLineup;
    }

    function createReplacementPlayerForLineup(position, replacementProjectionForPosition) {
        return createReplacementPlayerForLineupWithName("Replacement Player", position, replacementProjectionForPosition);
    }

    function createReplacementPlayerForLineupWithName(name, position, replacementProjectionForPosition) {
        return {
            player_name: name,
            player_team: "N/A",
            player_position: position,
            projections: {"pts_league": replacementProjectionForPosition}
        };
    }

    //Given a single week projection json for a player, and the league scoring settings
    //Return the points that player is projected to score that week
    function getPlayerPointProjection(playerSingleWeekProjectionsJson, leagueScoringSettings) {
        let projectedPoints = 0;
        //Before or after trade there could be no player in that position on the team. Return 0 in this case.
        if(leagueScoringSettings && playerSingleWeekProjectionsJson !== undefined && playerSingleWeekProjectionsJson !== null) {
            for(let stat of Object.entries(playerSingleWeekProjectionsJson)) {
                if(leagueScoringSettings[stat[0]]) {
                    projectedPoints = Number(projectedPoints + (stat[1] * leagueScoringSettings[stat[0]]));
                }
            }
            //Handle bonuses
            for(let settings of Object.entries(leagueScoringSettings)) {
                if(settings[0].includes("bonus")) {
                    //examples: bonus_pass_yd_300
                    //strip the word bonus off
                    let stat = settings[0].replace('bonus_','');
                    let numberNeededForBonus;
                    //if it contains a number, save and remove number, add to total if player is projected to beat number
                    if(/\d/.test(stat)) {
                        let lastUnderscoreIndex = stat.lastIndexOf('_');
                        //strip and save the number at the end (and remove the trailing _ before the number)
                        numberNeededForBonus = stat.substring(lastUnderscoreIndex + 1);
                        stat = stat.substring(0, lastUnderscoreIndex);

                        //if the user has a stat that matches the remaining string i.e. "pass_yd", and it's >= the saved number, add it to projectedPoints
                        if(Number(playerSingleWeekProjectionsJson[stat]) >= Number(numberNeededForBonus)) {
                            projectedPoints += settings[1];
                        }
                    } else {
                        //There are bonuses like ... bonus_rec_rb ... bonus_fd_qb
                        //These are a bit more complicated, need to know player's position and match it, also I think fd for a qb is "pass_fd", so would need to match 'qb' and 'pass'
                        //Not as straightforward, requires specific logic for each bonus, do later.
                    }
                    
                }
            }
        } else {
            return 0;
        }
        return projectedPoints;
    }

    function swapPlayersOnRosters(userRoster, opponentRoster, selectedUserPlayers, selectedOpponentPlayers) {

        //pop currently selected user players off of the new array/json, save both in array/json
        userRoster = popPlayersOffOfUserRoster(selectedUserPlayers, userRoster);

        //pop currently selected opponent players off of the new array/json
        opponentRoster = popPlayersOffOfUserRoster(selectedOpponentPlayers, opponentRoster.roster_data);

        userRoster = pushPlayersOntoUserRoster(selectedOpponentPlayers, userRoster);
        opponentRoster = pushPlayersOntoUserRoster(selectedUserPlayers, opponentRoster);

    }

    function popPlayersOffOfUserRoster(players, roster) {
        for(let player of players.entries()) {
            let playerId = player[1].player_id;
            delete roster["player_info"][playerId];
            delete roster["player_projections"][playerId];
            roster["players"].splice(roster["players"].indexOf(playerId), 1);
        }
        
        return roster;
    }

    function pushPlayersOntoUserRoster(players, roster) {
        for(let player of players.entries()) {
            let playerId = player[1].player_id;
            roster["player_info"][playerId] = player[1]["player_info"]
            roster["player_projections"][playerId] = player[1]["player_projections"]
            roster["players"].push(playerId);
        }
        
        return roster;
    }

    function getWeeklyNetProjectedPointDifferenceListRender(weeklyNetPointDifferenceJson) {
        let seasonTotalNetPointDifference = 0;
        let returnList= [];

        seasonTotalNetPointDifference = getSeasonTotalNetPointDifference(weeklyNetPointDifferenceJson);

        if(Object.entries(weeklyNetPointDifferenceJson).length > 0) {
            returnList.push(<li key="user_net_total">Net Point Change : {seasonTotalNetPointDifference}</li>);
            for(let week of Object.entries(weeklyNetPointDifferenceJson)) {
                let weekText = week[0].replace("w", "W").replace("_", " ");
                returnList.push(<li key={"user_week_"+week[0]}>{weekText} : {week[1]}</li>)
            }
        }
        return returnList;
    }
    
    function getSeasonTotalNetPointDifference(weeklyNetPointDifferenceJson) {
        let seasonTotalNetPointDifference = 0;
        for(let week of Object.entries(weeklyNetPointDifferenceJson)) {
            seasonTotalNetPointDifference = seasonTotalNetPointDifference + Number(week[1]);
        }
        
        return Math.round((seasonTotalNetPointDifference + Number.EPSILON) * 100) / 100;
    }

   function setTradeGrades(usersWeeklyPointDifferential, opponentsWeeklyPointDifferential, currentlySelectedUserPlayers, currentlySelectedOpponentPlayers) {
        // setTradeGradeForUser(currentUsersRosterObject, currentlySelectedOpponentPlayers);
        
        //Get and set projected weekly point differential for current user after trade
        let usersAvgPointDifferenceAfterTrade = getAvgPointsFromWeeklyPointDifferential(usersWeeklyPointDifferential);
        setUsersAverageProjectedPointDifferencePostTrade(usersAvgPointDifferenceAfterTrade);
        
        //Get and set projected weekly point differential for opponent after trade
        let opponentsAvgPointDifferenceAfterTrade = getAvgPointsFromWeeklyPointDifferential(opponentsWeeklyPointDifferential);
        setOpponentsAverageProjectedPointDifferencePostTrade(opponentsAvgPointDifferenceAfterTrade);

        //Calculate trade value differentials for user and opponent after trade
        let usersSelectedPlayersTradeValue = getTradeValueTotalForPlayerSelection(currentlySelectedUserPlayers, currentUsersRosterObject);
        let opponentsSelectedPlayersTradeValue = getTradeValueTotalForPlayerSelection(currentlySelectedOpponentPlayers, leagueUsersRosterObject.getLeagueUser(currentlySelectedOpponentPlayers[0].teamId));
        
        //Get user's trade value differential (opponent's is just the same number *-1 so no need to calculate that)
        let usersTotalTradeValueScore = opponentsSelectedPlayersTradeValue - usersSelectedPlayersTradeValue;
        setUsersTotalTradeValue(usersTotalTradeValueScore);
        let weightsToUseForGrading = getWeightsToUseForGrading();
        
        //These need to be global states
        let usersPostTradeNetPointDifferencePercentage = getPointDifferencePercentage(preTradeUserStartingLineup, usersAvgPointDifferenceAfterTrade);
        let opponentsPostTradeNetPointDifferencePercentage = getPointDifferencePercentage(preTradeOpponentStartingLineup, opponentsAvgPointDifferenceAfterTrade);

        let gradeForUser = getTradeGradeForOwner(usersPostTradeNetPointDifferencePercentage, usersTotalTradeValueScore, weightsToUseForGrading, false);
        let gradeForOpponent = getTradeGradeForOwner(opponentsPostTradeNetPointDifferencePercentage, (usersTotalTradeValueScore*-1), weightsToUseForGrading, true);

        setTradeGradeForUser(gradeForUser)
        setTradeGradeForOpponent(gradeForOpponent);
        setTradeGradeForUserForCss(stripPlusMinusFromGradeString(gradeForUser));
        setTradeGradeForOpponentForCss(stripPlusMinusFromGradeString(gradeForOpponent));
   }

   function getWeightsToUseForGrading() {
        // return {
        //     weelyAvgPointDifferential: .75,
        //     totalTradeValueDifference: .25
        // };
        return {
            weelyAvgPointDifferential: pointDifferentialGradeWeight,
            totalTradeValueDifference: playerValueGradeWeight,
        };
   }

   //Given a user's list of weekly lineups remaining, and the average number of points they've gained/lost in a trade
    //Return the percentage that they're losing on average compared to their average pre-trade score
    function getPointDifferencePercentage(lineups, averagePointDifferential) {
        const averageLineupScore = getAverageLineupScore(lineups);
        const percentage = averagePointDifferential / averageLineupScore;
        return percentage;
    }

    //Given a list of lineups (all weeks remaining), return the average "projectedPointTotal" for the lineup for all the weeks
    //Note: lineup scores must have already been calculated, this is just grabbing the field
    function getAverageLineupScore(lineupWeeks) {
        let totalScoreRemaining = 0;
        for(let week of Object.entries(lineupWeeks)) {
            if(week[1].projectedPointTotal && week[1].projectedPointTotal > 0) {
                totalScoreRemaining += week[1].projectedPointTotal;
            }
        }
        return (totalScoreRemaining/Object.keys(lineupWeeks).length);
    }

   function getTradeValueTotalForPlayerSelection(currentlySelectedPlayers, currentOwnerOfSelectedPlayersRosterObject) {
        let totalTradeValueOfSelectedPlayers = 0;
        for(let player of currentlySelectedPlayers.entries()) {
            let playerId = player[1].player_id;
            //replacementLevelValuesJson, leaguesLargestParScore
            let playerTradeValue = currentOwnerOfSelectedPlayersRosterObject.getPlayerValueFromFullData(playerId, leagueObject.replacement_level_values, leagueObject.largest_par_score, useNegativePlayerValues, startWeek, endWeek);
            totalTradeValueOfSelectedPlayers += playerTradeValue;
        }
        return totalTradeValueOfSelectedPlayers;
   }

   function getAvgPointsFromWeeklyPointDifferential(usersWeeklyPointDifferential) {
    // var averagePoints = usersWeeklyPointDifferential.map(week => week.pendingAmount).reduce((acc, amount) => acc + amount);
        let totalPoints = 0;
        for(let i = startWeek; i <= endWeek; i++) {
            if(usersWeeklyPointDifferential["week_" + i]) {
                totalPoints += usersWeeklyPointDifferential["week_" + i];
            }
        }

        return Math.round(((totalPoints / totalWeeks) + Number.EPSILON) * 10) / 10;


        //Pre-week setting code:
        // if(Object.entries(usersWeeklyPointDifferential).length > 0) {
        //     for(let week of Object.entries(usersWeeklyPointDifferential)) {
        //         totalPoints += week[1];
        //     }
        // }

        // return Math.round(((totalPoints / Object.entries(usersWeeklyPointDifferential).length) + Number.EPSILON) * 10) / 10
   }

   function stripPlusMinusFromGradeString(grade) {
        if(grade.includes("+")) {
            grade = grade.replace("+", "");
        } else if(grade.includes("-")) {
            grade = grade.replace("-", "");
        }
        return grade;
   }

   //Given a usersAvgPointDifferentialPercentage, 
        //usersAvgPointDifferentialPercentage is the average percentage of their pre-trade lineup score that has been changed by the trade
        //aka (averagePointDifferential / averageLineupScore)
    //Calculate and return the grade for this user after the trade.
    // A+ (97–100), A (93–96), A- (90–92), B+ (87–89), B (83–86), B- (80–82), C+ (77–79), 
    // C (73–76), C- (70–72), D+ (67–69), D (63–66), D- (60-62), F+ (56-59), F (below 55)
   function getAvgPointDifferentialTradeGrade(avgWeeklyPointDifferentialPercentage) { 
        let grade = 20;
        //Multiply by 100 to turn % to number (.01 -> 1)
        avgWeeklyPointDifferentialPercentage = avgWeeklyPointDifferentialPercentage * 100;

        if(avgWeeklyPointDifferentialPercentage >= 6) {
            grade = 100;
        } else if(avgWeeklyPointDifferentialPercentage >= 5) {
            grade = 95; 
        } else if(avgWeeklyPointDifferentialPercentage >= 4.5) {
            grade = 92;
        } else if(avgWeeklyPointDifferentialPercentage >= 4) {
            grade = 89;
        } else if(avgWeeklyPointDifferentialPercentage >= 3.5) {
            grade = 87;
        } else if(avgWeeklyPointDifferentialPercentage >= 3) {
            grade = 86;
        } else if(avgWeeklyPointDifferentialPercentage >= 2.5) {
            grade = 83;
        } else if(avgWeeklyPointDifferentialPercentage >= 2) {
            grade = 82;
        } else if(avgWeeklyPointDifferentialPercentage >= 1.5) {
            grade = 77;
        } else if(avgWeeklyPointDifferentialPercentage >= 1) {
            grade = 76;
        } else if (avgWeeklyPointDifferentialPercentage >= 0) {
            grade = 74;
        } else if(avgWeeklyPointDifferentialPercentage >= -1) {
            grade = 73;
        } else if (avgWeeklyPointDifferentialPercentage >= -2) {
            grade = 71;
        } else if(avgWeeklyPointDifferentialPercentage >= -3) {
            grade = 69;
        } else if (avgWeeklyPointDifferentialPercentage >= -4) {
            grade = 66;
        } else if(avgWeeklyPointDifferentialPercentage >= -5) {
            grade = 63;
        } else if (avgWeeklyPointDifferentialPercentage >= -6) {
            grade = 61;
        } else if(avgWeeklyPointDifferentialPercentage >= -6.5) {
            grade = 60;
        } else if (avgWeeklyPointDifferentialPercentage >= -7) {
            grade = 58;
        } else if(avgWeeklyPointDifferentialPercentage >= -7.5) {
            grade = 55;
        } else if (avgWeeklyPointDifferentialPercentage >= -8) {
            grade = 50;
        } else {
            grade = 30;
        }

        return grade;
   }

    //Given a total trade value differential, calculate the grade for this user after the trade.
    //Grade representations below:
    // A+ (97–100), A (93–96), A- (90–92), B+ (87–89), B (83–86), B- (80–82), C+ (77–79), 
    // C (73–76), C- (70–72), D+ (67–69), D (63–66), D- (60-62), F+ (56-59), F (below 55)
    function getTotalTradeValueScoreGrade(totalTradeValue) {
        let grade = 30;
        if(totalTradeValue >= 44) {
            grade = 100;
        } else if(totalTradeValue >= 42) {
            grade = 98; 
        } else if(totalTradeValue >= 39) {
            grade = 96;
        } else if(totalTradeValue >= 36) {
            grade = 94;
        } else if(totalTradeValue >= 33) {
            grade = 92;
        } else if(totalTradeValue >= 30) {
            grade = 91;
        } else if(totalTradeValue >= 27) {
            grade = 90;
        } else if(totalTradeValue >= 24) {
            grade = 89;
        } else if(totalTradeValue >= 21) {
            grade = 87;
        } else if(totalTradeValue >= 18) {
            grade = 85;
        } else if(totalTradeValue >= 15) {
            grade = 83;
        } else if(totalTradeValue >= 12) {
            grade = 81;
        } else if(totalTradeValue >= 9) {
            grade = 80;
        } else if(totalTradeValue >= 6) {
            grade = 78;
        } else if(totalTradeValue >= 3) {
            grade = 77;
        } else if (totalTradeValue >= 0) {
            grade = 75;
        } else if(totalTradeValue >= -3) {
            grade = 72;
        } else if (totalTradeValue >= -6) {
            grade = 70;
        } else if(totalTradeValue >= -9) {
            grade = 69;
        } else if (totalTradeValue >= -12) {
            grade = 67;
        } else if(totalTradeValue >= -15) {
            grade = 65;
        } else if (totalTradeValue >= -18) {
            grade = 63;
        } else if(totalTradeValue >= 21) {
            grade = 61;
        } else if (totalTradeValue >= 24) {
            grade = 59;
        } else if(totalTradeValue >= -27) {
            grade = 57;
        } else if (totalTradeValue >= -30) {
            grade = 55;
        } else if (totalTradeValue >= -33) {
            grade = 52;
        } else if (totalTradeValue >= -36) {
            grade = 49;
        } else if (totalTradeValue >= -39) {
            grade = 47;
        } else if (totalTradeValue >= -42) {
            grade = 45;
        } else {
            grade = 30;
        }

        return grade;
   }

   //Given the trade scores (atm, avg weekly point differential before/after trade, and total trade value score),
        //and the weights to use for each score
   //calculate the grade for tthis user after the trade.
   function getTradeGradeForOwner(usersAvgPointDifferentialPercentage, totalTradeValueScore, weightsJsonObject, isOpponent) {
        let averagePointDifferentialGrade = getAvgPointDifferentialTradeGrade(usersAvgPointDifferentialPercentage);
        
        let totalTradeValueScoreGrade = getTotalTradeValueScoreGrade(totalTradeValueScore);

        //Set individual measurement grades (to be used for css and maybe more)
        if(isOpponent) {
            setOpponentsPointDifferentialGrade(averagePointDifferentialGrade);
            setOpponentsPointDifferentialGradeForCss(stripPlusMinusFromGradeString(numberToGradeConverter(averagePointDifferentialGrade)));
            setOpponentsTradeValueGrade(totalTradeValueScoreGrade);
            setOpponentsTradeValueGradeForCss(stripPlusMinusFromGradeString(numberToGradeConverter(totalTradeValueScoreGrade)));
        } else {
            setUsersPointDifferentialGrade(averagePointDifferentialGrade);
            setUsersPointDifferentialGradeForCss(stripPlusMinusFromGradeString(numberToGradeConverter(averagePointDifferentialGrade)));
            setUsersTradeValueGrade(totalTradeValueScoreGrade);
            setUsersTradeValueGradeForCss(stripPlusMinusFromGradeString(numberToGradeConverter(totalTradeValueScoreGrade)));
        }
        
        let tradeScore = calculateGrade(averagePointDifferentialGrade, totalTradeValueScoreGrade, weightsJsonObject);

        //TradeScore should be 0-100 (on typical grade scale), to get letter score
        return numberToGradeConverter(tradeScore)
   }

   //given a 0-100 number, convert to a letter grade
   function numberToGradeConverter(numberGrade) {
        // A+ (97–100), A (93–96), A- (90–92), B+ (87–89), B (83–86), B- (80–82), C+ (77–79), 
        // C (73–76), C- (70–72), D+ (67–69), D (63–66), D- (60-62), F+ (56-59), F (below 55)
    
        let grade = "F";
        
        if(numberGrade >= 97) {
            grade = "A+";
        } else if(numberGrade >= 93) {
            grade = "A";
        } else if(numberGrade >= 90) {
            grade = "A-";
        } else if(numberGrade >= 87) {
            grade = "B+";
        } else if(numberGrade >= 83) {
            grade = "B";
        } else if(numberGrade >= 80) {
            grade = "B-";
        } else if(numberGrade >= 77) {
            grade = "C+";
        } else if (numberGrade >= 73) {
            grade = "C";
        } else if (numberGrade >= 70) {
            grade = "C-";
        } else if (numberGrade >= 67) {
            grade = "D+";
        } else if (numberGrade >= 63) {
            grade = "D";
        } else if (numberGrade >= 60) {
            grade = "D-";
        } else if (numberGrade >= 55) {
            grade = "F+";
        } else {
            grade = "F";
        }

        return grade;
   }

    // A+ (97–100), A (93–96), A- (90–92), B+ (87–89), B (83–86), B- (80–82), C+ (77–79), 
    // C (73–76), C- (70–72), D+ (67–69), D (63–66), D- (60-62), F+ (56-59), F (below 55)
    function calculateGrade(averagePointDifferentialGrade, totalTradeValueScoreGrade, weightsJsonObject) {
        //Weights json keys: weelyAvgPointDifferential, totalTradeValueDifference
        let pointDifferentialScore = averagePointDifferentialGrade * weightsJsonObject.weelyAvgPointDifferential;
        let totalTradeValueScore = totalTradeValueScoreGrade * weightsJsonObject.totalTradeValueDifference;
        let totalScore = pointDifferentialScore + totalTradeValueScore;

        return totalScore;
    }

    const projectedValueDifferenceTooltip = (
        <Tooltip id="ProjectedValueDifferenceTooltip">
            <strong>Projected difference in the team's overall value after trade.</strong> Value is a score based on a player's projected points in relation to the current best replacement free agents at their position.
        </Tooltip>
   );

   const projectedWeeklyAveragePointDifferenceTooltip = (
        <Tooltip id="projectedWeeklyAveragePointDifferenceTooltip">
            <strong>Projected change in the team's weekly starting lineup point total after the trade. </strong> 
            This is the projected average across all of the remaining weeks. Unless disabled, replacement level players 
            are used to fill any starting lineup spots where a non-zero scoring player isn't available that week, for a more accurate projection.
            Replacement players are given a projected score based on the current top free agents in this league at their respective positions.
        </Tooltip>
   )

   const gradeTooltip = (
        <Tooltip id="gradeTooltip">
            <strong>Trade grade for this team.</strong> This is based on a composite of post-trade projections.
        </Tooltip>
    )

    //Breakdown shows individual player totals, add cumulative and week by week analysis
    return(
        <div id={props.id}>
            <h1 id="tradeBreakdownTitle" className="boldFont largeHeader">Trade Breakdown</h1>
                <div id="receivingSendingContainer" className="twoColumnRow">
                    <div id="receivingContainer" className="twoColumnRow">
                        <div data-testid="userReceivingTeam" className="highlightOutfit breakdownTeamName">{currentUsersRosterObject.getCurrentUserTeamName()}</div>
                        <OverlayTrigger placement="top" overlay={gradeTooltip}>
                            <div data-testid="userGrade" className={"grade " + tradeGradeForUserForCss}>{tradeGradeForUser}</div>
                        </OverlayTrigger>
                        <h3>Receiving</h3>      
                        {currentlySelectedOpponentPlayers.map((playerInfo, i) => (
                            <PlayerCardDetailed getCardColor={props.getCardColor} key={i} playerCardDetailedInfo={leagueUsersRosterObject.getPlayerCardDetailedInfo(playerInfo.player_id, playerInfo.teamId, leagueObject.replacement_level_values, leagueObject.largest_par_score, useNegativePlayerValues, startWeek, endWeek)} keyProp={i}></PlayerCardDetailed>
                        ))}
                    </div>
                    <div id="sendingContainer" className="twoColumnRow">
                        <div data-testid="opponentReceivingTeam" className="highlightOutfit breakdownTeamName">{leagueUsersRosterObject.getUserTeamName(currentlySelectedOpponentPlayers[0].teamId)}</div>
                        <OverlayTrigger placement="top" overlay={gradeTooltip}>
                            <div data-testid="opponentGrade" className={"grade " + tradeGradeForOpponentForCss}>{tradeGradeForOpponent}</div>
                        </OverlayTrigger>
                        <h3>Receiving</h3>
                        {currentlySelectedUserPlayers.map((playerInfo, i) => (
                            <PlayerCardDetailed getCardColor={props.getCardColor} key={i} playerCardDetailedInfo={currentUsersRosterObject.getPlayerCardDetailedInfo(playerInfo.player_id, leagueObject.replacement_level_values, leagueObject.largest_par_score, useNegativePlayerValues, startWeek, endWeek)} keyProp={i}></PlayerCardDetailed>
                        ))}
                    </div>
                </div>
                <div id="tradeValueDifferenceContainer">
                    <div id="projectedValueDifferenceHeader" className="breakdownSubHeader">
                        <h2 className="boldFont secondaryHeader">Projected Value Difference</h2>
                        <OverlayTrigger placement="top" overlay={projectedValueDifferenceTooltip}>
                            <i data-testid='PVDTooltip' className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                    <div data-testid="tradeValueDifferences" id="tradeValueDifferenceProjections" className="twoColumnRow">
                        <div className={"twoColumn valueDifference " + usersTradeValueGradeForCss}>{totalTradeValue}</div>
                        <div className={"twoColumn valueDifference " + opponentsTradeValueGradeForCss}>{totalTradeValue*-1}</div>
                    </div>
                </div>
                <div id="pointProjectionsContainer">
                    <div id="projectedWeeklyAveragePointDifferenceHeader" className="breakdownSubHeader">
                        <h2 className="boldFont secondaryHeader">Projected Weekly Average Point Differential</h2>
                        <OverlayTrigger placement="top" overlay={projectedWeeklyAveragePointDifferenceTooltip}>
                            <i data-testid='PWAPDTooltip' className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                    <div data-testid="avgPointDifferential" id="pointProjections" className="twoColumnRow">
                        <div className={"twoColumn grade " + usersPointDifferentialGradeForCss}>{usersAverageProjectedPointDifferencePostTrade}</div>
                        <div className={"twoColumn grade " + opponentsPointDifferentialGradeForCss}>{opponentsAverageProjectedPointDifferencePostTrade}</div>
                    </div>
                </div>
                {postTradeOpponentStartingLineup !== null && preTradeUserStartingLineup !== null && 
                    <WeeklyBreakdown settings={props.settings} currentUserTeamName={currentUsersRosterObject.getCurrentUserTeamName()} selectedOpponentTeamName={leagueUsersRosterObject.getUserTeamName(currentlySelectedOpponentPlayers[0].teamId)} preTradeUserStartingLineup={preTradeUserStartingLineup} preTradeOpponentStartingLineup={preTradeOpponentStartingLineup} postTradeUserStartingLineup={postTradeUserStartingLineup} postTradeOpponentStartingLineup={postTradeOpponentStartingLineup}></WeeklyBreakdown>
                }
        </div>
    )

};

export default TradeBreakdownView2;