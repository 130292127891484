import { LeagueUser } from "./LeagueUser";

export class LeagueUsers {
    constructor(userData, rosterData, nflStateData, leagueType, current_user_id, leagueObject) {
        this.isDoneLoading = false;
        this.userList = [];
        if(leagueType === "sleeper") {

            for(let roster of rosterData.entries()) {
                if(current_user_id !== roster[1].owner_id) {
                    const usersRoster = roster; //get the matching roster for the user
                    const user = userData.find(user => usersRoster[1].owner_id === user.user_id); //get the matching roster for the user
                    this.userList.push(new LeagueUser(user, usersRoster[1], nflStateData, leagueObject, leagueType));
                }
            }

            this.isDoneLoading = true;

            // for(let user of userData.entries()) {
            //     if(current_user_id !== user[1].user_id) {
            //         const usersRoster = rosterData.find(roster => roster.owner_id === user[1].user_id); //get the matching roster for the user
            //         this.userList.push(new LeagueUser(user[1], usersRoster, nflStateData, leagueObject, leagueType));
            //     }
            // }
        }
    }

    getUserTeamName(team_id) {
        return this.getLeagueUser(team_id).metadata.team_name;
    }

    getPlayerCardDetailedInfo(player_id, team_id, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek) {
        return this.getRosterData(team_id).getPlayerCardDetailedInfo(player_id, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek);
    }

    getLeagueUser(team_id) {
        team_id= this.stripTeamString(team_id);
        return this.userList.find(user => user.user_id === team_id);
    }

    getRosterData(team_id) {
        team_id= this.stripTeamString(team_id);
        return this.userList.find(user => user.user_id === team_id).roster_data;
    }

    stripTeamString(team_id) {
        if(team_id.includes("team_")) {
            team_id = team_id.replace("team_", "");
        }
        return team_id;
    }

    //Return a list of all players on all teams in the league
    //The list contains an object for each player which contains their position and team acronym 
    getAllPlayersOnLeagueUserTeams() {
        let allPlayers = {};
        for(let user of this.userList) {
            allPlayers = {...allPlayers, ...user.getAllPlayersOnTeam()};
        }
        
        //Sort allPlayers by playerInfo['player_value'] number
        allPlayers = Object.entries(allPlayers).sort((a, b) => b[1]['player_value'] - a[1]['player_value']);
        return allPlayers;
    }

    //Set the replacement level score for all players on league user rosters
    setAllPlayerValues(replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek) {
        //For each user in the league
        for(let user of this.userList) {
            //Set the player values for each player on this user's roster, in the player_info
            user.roster_data.setPlayerValues(replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek);
        }
    }
}