import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import Loader from "./Loader";
import './LeagueSelector.css';
import { useParams, useSearchParams, useNavigate, useLocation } from 'react-router-dom';

//chase update to clear when username changes?
const LeagueSelector = forwardRef((props, ref) => {

    const navigate = useNavigate();
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useImperativeHandle(ref, () => ({
        clear() {
          clearState();
        }
    }));

    const [nflStateData, setNflStateData] = useState(null);
    const [loadingNflState, setLoadingNflState] = useState(true);
    const [nflStateError, setNflStateError] = useState(null);
    const [usersLeaguesData, setUsersLeaguesData] = useState(null);
    const [loadingUsersLeaguesData, setLoadingUsersLeaguesData] = useState(true);
    let [selectedLeagueId, setSelectedLeagueId] = useState('');
    let [selectedLeagueName, setSelectedLeagueName] = useState('');

    const params = useParams();
    const leagueType = searchParams.get('leaguetype');
    const user_id = searchParams.get('user_id');


    
    // this method should eventually clear the state for child component?
    const clearState = () => {
        setUsersLeaguesData(null);
        setLoadingUsersLeaguesData(true);
        setSelectedLeagueId('');
        setSelectedLeagueName('');
    }


    //Fetch data on component mount (first render)
    useEffect(() => {

        // data fetching here
        if(!nflStateData) {
            // getNflStateJsonFromEndpoint();
            getNflStateJsonFromEndpointNew();
        }

        
        if(nflStateData && !usersLeaguesData) {
            getLeagues();
        }        
    
    }); 
   

    async function getLeagues() {
        if(user_id !== undefined) {
            setLoadingUsersLeaguesData(true);
            const leaguesJson = await getLeaguesJsonFromEndpoint(user_id, nflStateData.season);
            setUsersLeaguesData(leaguesJson);
            setLoadingUsersLeaguesData(false);
        }
    }

    async function getLeaguesJsonFromEndpoint(user_id, season) {
        let leaguesEndpoint = `https://api.sleeper.app/v1/user/${user_id}/leagues/nfl/${season}`;
        const response = await fetch(leaguesEndpoint);
        const json = await response.json();
        return json;
    }

    async function getNflStateJsonFromEndpointNew() {
        let nflStateResponseData = null;
        let successfulResponse = false;
        try {
            if(leagueType === 'sleeper') {
                let stateEndpoint = `https://api.sleeper.app/v1/state/nfl`;
                const response = await fetch(stateEndpoint);
                if(response.error) {
                    setNflStateError(response.error);
                    setNflStateData(null);
                } else {
                    nflStateResponseData = await response.json();
                    successfulResponse = true;
                }
                
            }
        } catch (err) {
            setNflStateError(err);
        }

        if(successfulResponse) {
            if(props.debug.enabled === true && props.debug.tailEndOfSeason === true) {
                nflStateResponseData.week = 16; 
                
            }
            if(props.debug.enabled === true && props.debug.use2022Season === true) {
                nflStateResponseData.season='2022';
                nflStateResponseData.league_season='2022';
            }
    
            //Set to display week... generally the current/upcoming week we want to see
            nflStateResponseData.week = nflStateResponseData.display_week;
            
            //Don't do things with week 0 - might have to deal with last week also? (above might do that?)
            if(nflStateResponseData.week === 0 || nflStateResponseData.season_type === "pre") {
                nflStateResponseData.week = 1;
            }
            setNflStateData(nflStateResponseData);
            setNflStateError(null);
            setLoadingNflState(false);
        }
    }

    const leagueClickHandler2 = event => {
        let newPath = location;
        newPath.pathname = '/leagueview';
        if(event.target.value ) {
            newPath.search += '&league_id=' + event.target.value;
        } else {
            newPath.search += '&league_id=' + event.target.parentNode.value;
        }
        const route = newPath.pathname + newPath.search;
        navigate(route);
    };





    //user with multiple leagues: jaltiere
    //chase deal with switching between leagues
    //chase back button below needs to either clear state... or "send user back" to username screen, which needs to be hidden at this point
    return (
        <div id="leagueSelector" className="mainPageContent">
            {!selectedLeagueId &&
            <div>
                <div className="boldFont">
                <div id="selectLeagueHeader" className="selectLeagueHeaderParent">
                    <h1 className="selectLeagueHeader boldFont largeHeader">Select League</h1>
                </div>
                {(loadingNflState || loadingUsersLeaguesData) && <Loader displayText="Loading Leagues"></Loader>}   
                {nflStateError && (
                    <div className="badEndpointResponse">{`There is a problem fetching the state data, try again - ${nflStateError}`}</div>
                )}
                    {nflStateData && <h2 id="currentLeaguesText" className="highlightOutfit sectionHighlight">Current Leagues ({nflStateData.season}):</h2>}
                    {usersLeaguesData && usersLeaguesData.length > 0 &&
                        <ul className="leagueInputUl">
                            {usersLeaguesData &&
                            usersLeaguesData.map(({ name, league_id }) => (
                                <div  key={league_id}>
                                    <li className="leagueInputItem">
                                    {/* {name} <input className = "leagueInputItem2" type="radio" value={league_id} title={name} name="leagues"/> */}
                                        {/* chase update the to to go to the same url but with the league id concat?.. maybe we need more? can we pass props? */}
                                        {/* <button onClick={(e) => leagueClickHandler2(location)(e)} className="leagueSelectorButton siteButton boldFont leagueButtonText" value={league_id} title={name} name="leagues"> */}
                                        <button onClick={leagueClickHandler2} className="leagueSelectorButton siteButton boldFont leagueButtonText" value={league_id} title={name} name="leagues">
                                            <span className="leagueNameText">{name}</span>
                                        </button>
                                        {/* // onClick={() => navigate('enterusername?leaguetype=' + sleeperType)} */}
                                        {/* to={'/leagueview?' + searchParamsString} */}
                                    </li>
                                </div>

                            ))}

                        </ul>
                    }
                    {usersLeaguesData && usersLeaguesData.length === 0 &&
                        <p className="boldFont noLeaguesFound">No leagues found for the {nflStateData.season} season.</p>
                    }
                </div>
            </div>
            }
            {/* <div>
                {selectedLeagueId && <LeagueView leagueObject = {leagueObject} leagueType={leagueType} leagueTradeBreakdownSettings={leagueTradeBreakdownSettings} selectedLeagueRosters={selectedLeagueRosters} selectedLeagueRostersObject={selectedLeagueRostersObject} selectedUserRostersObject={selectedUserRostersObject} selectedLeagueData={selectedLeagueData} setSelectedLeagueRostersInParent={setSelectedLeagueRostersInChild} setSelectedLeagueDataInParent={setSelectedLeagueDataInChild} nflState={nflStateData} leagueName={selectedLeagueName} league_id={selectedLeagueId} username={username} displayName={displayName} user_id={user_id}></LeagueView>}
            </div> */}
        </div>
    );

});



export default LeagueSelector;