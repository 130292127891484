import React, { useState, useEffect } from "react";
import LineupDisplay from './LineupDisplay';
import './WeeklyBreakdown.css';
import { Tooltip, OverlayTrigger } from "react-bootstrap";


const WeeklyBreakdown = (props) => {
    //props.preTradeUserStartingLineup
    //props.preTradeOpponentStartingLineup
    //props.postTradeUserStartingLineup
    //props.postTradeOpponentStartingLineup
    //props.currentUserTeamName
    //props.selectedOpponentTeamName
    //props.settings
    
    //Settings
    const startWeek = props.settings.startWeek;
    const endWeek = props.settings.endWeek;
    const weeks = Array.from({ length: endWeek - startWeek + 1 },
        (_, index) => startWeek + index);
    const weeksWithWeekText = createWeeksWithTextArray(weeks);

    const [userLineupDifferences, setUserLineupDifferences] = useState([]);
    const [opponentLineupDifferences, setOpponentLineupDifferences] = useState([]);


    const [selectedWeek, setSelectedWeek] = useState(props.settings.startWeek);
    const [selectedTeam, setSelectedTeam] = useState(props.currentUserTeamName);

    const width = window.innerWidth;
    const breakpoint = 768;



    useEffect(() => {
        setUserLineupDifferences(getLineupDifferences(props.preTradeUserStartingLineup, props.postTradeUserStartingLineup));
        setOpponentLineupDifferences(getLineupDifferences(props.preTradeOpponentStartingLineup, props.postTradeOpponentStartingLineup));
        setSelectedWeek(props.settings.startWeek);

    }, [props.postTradeOpponentStartingLineup, props.postTradeUserStartingLineup, props.preTradeOpponentStartingLineup, props.preTradeUserStartingLineup, props.settings]);


    function createWeeksWithTextArray(weeks) {
        let weeksWithWeekText = [];
        for(let week of weeks) {
            week = "week_" + week;
            weeksWithWeekText.push(week);
        }
        return weeksWithWeekText;
    }

    //Given a user's rest of season lineups before and after a trade
    //Return Json object that, for each week, return a tuple of player_name and a second entry of either "fromOtherTeam" or "fromBench" (could just be true/false...)
    function getLineupDifferences(preTradeLineup, postTradeLineup) {
        let restOfSeasonLineupDifferences = {};

        for(let week of weeksWithWeekText) {
            restOfSeasonLineupDifferences[week] = getPlayerDifferences(preTradeLineup[week], postTradeLineup[week]);
        }

        return restOfSeasonLineupDifferences;
    }

    //Given 2 lineups for a week
    //Return a list of tuples for each difference, where the first is player_name and the second is either "fromOtherTeam" or "fromBench" (could just be true/false...)
    function getPlayerDifferences(preTradeLineup, postTradeLineup) {
        let playerDifferences = [];
        let listOfPositionsThatCanBeFlex = ["WR", "RB", "TE", "QB"];
        let flexPositions = Object.keys(preTradeLineup).filter(position => position.includes("FLEX"));
        for(let position of Object.entries(preTradeLineup)) {
            let positionName = position[0]; 
            let positionInfo = position[1];
            if(positionName !== "projectedPointTotal") {
                //There's a possibility that a player goes from FLEX -> RB/WR/ETC. or the opposite... have to account for both
                //Best way may be to check POS then FLEX, unless their position in preTrade was flex... in which case get 'player_position' from their info and check there after flex
                if(!positionName.includes("FLEX")) {
                    let postTradePlayersInPosition = Object.entries(postTradeLineup[positionName]);
                    for(let player of Object.entries(postTradePlayersInPosition[1][1])) {
                        //From this point on - positionName essentially denotes the player's position POST TRADE
                        //We want to find where they were pre-trade

                        let isInPreTradeTeam = false; // Was this player on the team before the trade?
                        let isMovedToOrFromBench = false; //Was this player moved to or from the bench after the trade?
                        let playerInfo = player[1];
                        //Return a list of all players in this position in this lineup that match the player's name
                        let matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, positionName, playerInfo);
                        if(matchingPlayersInPreTradeLineup !== null && matchingPlayersInPreTradeLineup.length > 0) { 
                            //Player is in the same position in both lineups, nothing needs to be saved about them
                            isInPreTradeTeam = true;
                        } else {
                            //Player isn't in starting lineup for same position as preTrade lineup
                            
                            //Check FLEX spots for player
                            matchingPlayersInPreTradeLineup = null;
                            for(let flexPosition of flexPositions) {
                                matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, flexPosition, playerInfo);
                                if(matchingPlayersInPreTradeLineup.length > 0) {
                                    if(positionName === "BN") {
                                        //Position in PreTrade Lineup was bench, player is *FLEX post trade
                                        isMovedToOrFromBench = true;
                                    }
                                    isInPreTradeTeam = true;
                                    break;
                                }
                            }

                            //If position isn't already "BN"
                            //Check PreTrade Bench "BN" spots for player
                            if(positionName !== "BN") {
                                matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, "BN", playerInfo);
                                if(matchingPlayersInPreTradeLineup.length > 0) {
                                    //Player was on the bench in preTrade lineup and is now a starter
                                    isMovedToOrFromBench = true;
                                    isInPreTradeTeam = true;
                                }
                            }

                            //Player is on the bench in postTrade lineup, but maybe they weren't on the bench pre-trade
                            if(positionName === "BN") {
                                //Check their player position in the preTrade lineup for them (i.e. check WR if they're a WR)
                                matchingPlayersInPreTradeLineup = null;
                                let playersPosition = playerInfo.player_position;
                                matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, playersPosition, playerInfo);
                                if(matchingPlayersInPreTradeLineup !== null && matchingPlayersInPreTradeLineup.length > 0) {
                                    //Player was a starter at their position in the preTrade lineup and is now a bench player
                                    isInPreTradeTeam = true;
                                    isMovedToOrFromBench = true;
                                } else {
                                    //Check the pre-trade *FLEX spots for them
                                    matchingPlayersInPreTradeLineup = null;                            
                                    for(let flexPosition of flexPositions) {
                                        matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, flexPosition, playerInfo);
                                        if(matchingPlayersInPreTradeLineup.length > 0) {
                                            //Player was moved from a *FLEX position in preTrade lineup to a bench position in postTrade lineup
                                            isInPreTradeTeam = true;
                                            isMovedToOrFromBench = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        //If player was never found in preTrade Lineup, they are from the other team (isInPreTradeTeam should be false)
                        if(!isInPreTradeTeam || isMovedToOrFromBench) {
                            playerDifferences.push(getChangedPlayerObject(playerInfo, isInPreTradeTeam, isMovedToOrFromBench));
                        }
                    }
                } else {
                    //Handle players who end up as a "*FLEX" position in postTradeLineup
                    //First, check if they're in the same "*FLEX" position in pre-trade lineup
                    //Otherwise, check their original player_position, and bench
                    let postTradePlayersInPosition = Object.entries(postTradeLineup[positionName]);

                    //For all postTrade players in this "*FLEX" position
                    for(let player of Object.entries(postTradePlayersInPosition[1][1])) {
                        let isInPreTradeTeam = false; // Was this player on the team before the trade?
                        let isMovedToOrFromBench = false; //Was this player moved to or from the bench after the trade?

                        let playerInfo = player[1];

                        //Return a list of all players in this position in the pre-trade lineup that match the player's name
                        let matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, positionName, playerInfo);
                        if(matchingPlayersInPreTradeLineup.length > 0) {
                            //Player is in the same position in both lineups, nothing needs to be saved about them
                            isInPreTradeTeam = true;
                        } else {
                            //Player may have been moved to *FLEX position after trade, check their actual position according to their info in preTrade lineup and see if they are there
                            matchingPlayersInPreTradeLineup = null;
                            let playersPosition = playerInfo.player_position;
                            matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, playersPosition, playerInfo);
                            if(matchingPlayersInPreTradeLineup.length > 0) {
                                //Player was a *FLEX starter preTrade, and now starts at their actual position, nothing needs to be saved about them
                                isInPreTradeTeam = true;
                            } else {
                                //Check PreTrade Bench "BN" spots for player
                                matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, "BN", playerInfo);
                                if(matchingPlayersInPreTradeLineup.length > 0) {
                                    //Player was on the bench in preTrade lineup and is now a *FLEX postTrade
                                    isMovedToOrFromBench = true;
                                    isInPreTradeTeam = true;
                                } else {
                                    //Player may be in other *FLEX spots pre-trade, check there for player
                                    matchingPlayersInPreTradeLineup = null;                            
                                    for(let flexPosition of flexPositions) {
                                        if(flexPosition !== positionName) { // dont re-check the current *FLEX position
                                            matchingPlayersInPreTradeLineup = getMatchingPlayersInLineupForPosition(preTradeLineup, flexPosition, playerInfo);
                                            if(matchingPlayersInPreTradeLineup.length > 0) {
                                                isInPreTradeTeam = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if(!isInPreTradeTeam || isMovedToOrFromBench) {
                            playerDifferences.push(getChangedPlayerObject(playerInfo, isInPreTradeTeam, isMovedToOrFromBench));
                        }       
                    }
                }
            }
        }
        return playerDifferences;
    }

    //Given a lineup, a position, and a player's info (most importantly, the playerInfo object needs playerInfo.player_name)
    //Return a list of players in the lineup that match the player's name
    function getMatchingPlayersInLineupForPosition(lineup, position, playerInfo) {
        if(lineup[position] !== undefined && lineup[position] !== null && lineup[position].players !== null && lineup[position].players !== undefined) {
            return Object.entries(lineup[position].players).filter(preTradePlayer => preTradePlayer[1].player_name === playerInfo.player_name);
        }
        return null;
    }

    function getChangedPlayerObject(player, isInPreTradeTeam, isMovedToOrFromBench) {
        return {
            player: player,
            isInPreTradeTeam: isInPreTradeTeam,
            isMovedToOrFromBench: isMovedToOrFromBench
        };
    }

    
    function handlePlusMinusLinkClick(week, teamName) {
        setSelectedWeek(week);
        setSelectedTeam(teamName);
    }

    function getIsActiveTeam(team) {
        return selectedTeam === team ? "active" : "";
    }

    function getIsActivePlusMinus(week, teamName) {
        if(selectedTeam === teamName && selectedWeek === week) {
            return "activePlusMinus";
        } 
    }

    function getLineup(isPreTrade) {
        if(selectedTeam === props.currentUserTeamName) {
            if(isPreTrade) {
                return props.preTradeUserStartingLineup["week_" + selectedWeek];
            } else {
                return props.postTradeUserStartingLineup["week_" + selectedWeek];
            }
        } else {
            if(isPreTrade) {
                return props.preTradeOpponentStartingLineup["week_" + selectedWeek];
            } else {
                return props.postTradeOpponentStartingLineup["week_" + selectedWeek];
            }
        }
    }

    function getLineupTotalScore(isPreTrade) {
        let returnTotal = 0;
        if(selectedTeam === props.currentUserTeamName) {
            if(isPreTrade) {
                returnTotal =  props.preTradeUserStartingLineup["week_" + selectedWeek].projectedPointTotal;
            } else {
                returnTotal =  props.postTradeUserStartingLineup["week_" + selectedWeek].projectedPointTotal;
            }
        } else {
            if(isPreTrade) {
                returnTotal =  props.preTradeOpponentStartingLineup["week_" + selectedWeek].projectedPointTotal;
            } else {
                returnTotal =  props.postTradeOpponentStartingLineup["week_" + selectedWeek].projectedPointTotal;
            }
        }
        
        return Math.round((returnTotal + Number.EPSILON) * 100) / 100
    }

    function getLineupDifferencesToDisplay() {
        if(selectedTeam === props.currentUserTeamName) {
            return userLineupDifferences["week_" + selectedWeek];
        } else {
            return opponentLineupDifferences["week_" + selectedWeek];
        }
    }

    function getPreTradeLineupDifferencesToDisplay() {
        if(selectedTeam === props.currentUserTeamName) {
            return opponentLineupDifferences["week_" + selectedWeek];
        } else {
            return userLineupDifferences["week_" + selectedWeek];
        }
    }

    //Given a number string starting with a + or - sign, and possibly followed by a number, and possibly a decimal point, and possibly a number.
    //Can also just be "-" to denote 0
    function getPlusMinusColorClass(numberString) {
        if(numberString.toString().startsWith("+")) {
            return "positive";
        } else if(!numberString.toString().endsWith("-")) {
            //Number is not positive and is not just "-", so it is negative
            return "negative";
        }
    }

    const weeklyPlusMinusesGeneric = (week, teamName, opponentOrUserText) => {
        // + " " + getPlusMinusColorClass(week, teamName)
        let textNumberForWekForTeam = getPlusMinusTextForTeamForWeek(week, opponentOrUserText);
        let colorClassName = getPlusMinusColorClass(textNumberForWekForTeam);
        
        return(
            <div onClick={() => handlePlusMinusLinkClick(week, teamName)} data-testid="plusMinusCell" id={opponentOrUserText + "PlusMinus" + week} key={week+opponentOrUserText+"PlusMinus"} className={"plusMinusEntry " + getIsActivePlusMinus(week, teamName) + " " + colorClassName}>
                {getPlusMinusTextForTeamForWeek(week, opponentOrUserText)}
            </div>
        )
    }

    function getPlusMinusTextForTeamForWeek(week, opponentOrUserText) {
        let preTradeLineupScore = 0;
        let postTradeLineupScore = 0;
        if(opponentOrUserText === "user") {
            if(props.preTradeUserStartingLineup["week_" + week] !== undefined && props.preTradeUserStartingLineup["week_" + week].projectedPointTotal !== undefined) {
                preTradeLineupScore = props.preTradeUserStartingLineup["week_" + week].projectedPointTotal;
                postTradeLineupScore = props.postTradeUserStartingLineup["week_" + week].projectedPointTotal;
            }
        } else {
            if(props.preTradeOpponentStartingLineup["week_" + week] !== undefined && props.preTradeOpponentStartingLineup["week_" + week].projectedPointTotal !== undefined) {
                preTradeLineupScore = props.preTradeOpponentStartingLineup["week_" + week].projectedPointTotal;
                postTradeLineupScore = props.postTradeOpponentStartingLineup["week_" + week].projectedPointTotal;
            }
        
        }
        
        //Round to 2 decimal places
        let tradeDifference = Math.round(((postTradeLineupScore - preTradeLineupScore) + Number.EPSILON) * 100) / 100;
        if(tradeDifference > 0) {
            return "+" + tradeDifference;
        } else if(tradeDifference < 0) {
            return tradeDifference;
        } else {
            return "-";
        } 
    }

    function getTotalPlusMinusForTeam(user) {
        let totalDifferential = 0;
        let postTradeLineupScore = 0;

        if(user === "user") {
            for(let week of weeks) {
                let weeklyDifferential = Number(props.postTradeUserStartingLineup["week_" + week].projectedPointTotal) - Number(props.preTradeUserStartingLineup["week_" + week].projectedPointTotal);
                totalDifferential = totalDifferential += weeklyDifferential;
            }
        } else {
            for(let week of weeks) {
                let weeklyDifferential = Number(props.postTradeOpponentStartingLineup["week_" + week].projectedPointTotal) - Number(props.preTradeOpponentStartingLineup["week_" + week].projectedPointTotal);
                totalDifferential = totalDifferential += weeklyDifferential;
            }
        }
        
        //Round to 2 decimal places
        let tradeDifference = Math.round((totalDifferential) * 100) / 100;
        if(tradeDifference > 0) {
            return "+" + tradeDifference;
        } else if(tradeDifference < 0) {
            return tradeDifference;
        } else {
            return "-";
        } 
    }

    const weeklyPlusMinusesForUser = weeks.map((week) =>
        weeklyPlusMinusesGeneric(week, props.currentUserTeamName, "user")
    );


    const weeklyPlusMinusesForOpponent = weeks.map((week) =>
        weeklyPlusMinusesGeneric(week, props.selectedOpponentTeamName, "opponent")
    );

    
    const weekLinks = weeks.map((week) => 
        <div id={"weekLink_" + week} className={"weekLinkButton" } key={"weekLink_" + week}>
            {week}
        </div>
    );

    const weeklyBreakdownLineupTooltip = (
        <Tooltip id="weeklyBreakdownLineupTooltip">
            Score is based on the projected starting lineup. <br/>
            <strong>Green</strong> indicates a player that was acquired in the trade.<br/>
            <strong>Red</strong> indicates a player that was removed in the trade.<br/>
            <strong>Orange</strong> indicates a player that was moved to or from the bench that week, because of the trade.
        </Tooltip>
    );

    const weeklyBreakdownTooltip = (
        <Tooltip id="weeklyBreakdownTooltip">
            <strong>A weekly view of each team's projected starting lineup differences. </strong>
            Unless disabled, starting roster spots where a team has no non-zero scoring players to fill the roster 
            are replaced by a "replacement level" score for a more realistic breakdown. 
            Replacement players are given a projected score based on the current top free agents in
            this league at their respective positions.
        </Tooltip>
    )


    return(
        <div id="weeklyBreakdown">
            <div id="weeklyBreakdownHeaderParent" className="breakdownSubHeader">
                <h2 id="weeklyBreakdownHeader" className='boldFont secondaryHeader'>Weekly Breakdown</h2>
                <OverlayTrigger placement="top" overlay={weeklyBreakdownTooltip}>
                    <i data-testid='WBTooltip' className="bi bi-info-circle"></i>
                </OverlayTrigger>
            </div>
            {width > breakpoint ? 
                <div id="weeklyBreakdownContent" >
                    <div id="weeklyBreakdownTopBar">
                        <div id="weekLinksContainer" data-testid="weekLinksHeaderContainer">
                            <div id="teamsText" className='boldFont'>
                                Week:
                            </div>
                            <div id="weekLinks">
                                {weekLinks}                           
                            </div>
                            <div className="weekLinkButton" id="totalText">Total</div>
                        </div>
                        <div data-testid='userTeamWeeklyRow' className='teamWeeklyBreakdownDiffRow'>
                            <div data-testid='userBreakdownTeamName' className={'boldFont weeklyBreakdownTeamName weeklyBreakdownTeamNameTop ' + getIsActiveTeam(props.currentUserTeamName)} >
                                <div className='teamNameText'>
                                    {props.currentUserTeamName}: 
                                </div>
                            </div>
                            <div className='weeklyPlusMinuses weeklyPlusMinusesTop'>
                                {weeklyPlusMinusesForUser}             
                            </div>
                            <div className="totalPlusMinus totalPlusMinusTop weeklyPlusMinuses">{getTotalPlusMinusForTeam("user")}</div>
                        </div>
                        <div data-testid='opponentTeamWeeklyRow' className='teamWeeklyBreakdownDiffRow'>
                            <div data-testid='opponentBreakdownTeamName' className={'boldFont weeklyBreakdownTeamName ' + getIsActiveTeam(props.selectedOpponentTeamName)} >
                                <div className='teamNameText'>  
                                    {props.selectedOpponentTeamName}: 
                                </div>
                            </div>
                            <div className='weeklyPlusMinuses weeklyPlusMinusesBottom'>
                                {weeklyPlusMinusesForOpponent}
                            </div>
                            <div className="totalPlusMinus weeklyPlusMinuses">{getTotalPlusMinusForTeam("opponent")}</div>
                        </div>
                    </div>
                    <div id="weeklyBreakdownTablesHeader" className="breakdownSubHeader">
                        <h3 className='boldFont weeklyBreakdownTablesTitle'>{selectedTeam} - Week {selectedWeek} Projection </h3>
                        <OverlayTrigger placement="top" overlay={weeklyBreakdownLineupTooltip}>
                            <i data-testid='WBLTooltip' className="bi bi-info-circle"></i>
                        </OverlayTrigger>
                    </div>
                    <div className="lineupBreakdown" id="lineupBreakdowns">
                        <div data-testid='preTradeLineupContainer' id="preTradeLineupBreakdown">
                            <div className='individualLineupTitle'>
                                <div className='boldFont prePostTradeHeaders'>Pre-Trade</div> 
                                <div className='highlightOutfit prePostTradeHeaders'>{getLineupTotalScore(true)}</div>
                            </div>
                            <div id="userPreTradeLineup">
                                <LineupDisplay isPreTrade={true} lineup={getLineup(true)} opponentLineupDifferences={getPreTradeLineupDifferencesToDisplay()} lineupDifferences={getPreTradeLineupDifferencesToDisplay()}></LineupDisplay>
                            </div>
                            {/*
                            <div className='highlightOutfit'>{props.selectedOpponentTeamName}</div>
                            <div id="opponentPreTradeLineup">
                                <LineupDisplay lineup={props.preTradeOpponentStartingLineup["week_" + selectedWeek]}></LineupDisplay>
                            </div> */}
                            {/* <div className='boldFont totalScore' id="preTradeLineupTotalScore">
                                Starting Lineup Points: {getLineupTotalScore(true)}
                            </div> */}
                        </div>
                        <div data-testid='postTradeLineupContainer' id="postTradeLineupBreakdown">
                            <div className='individualLineupTitle'>
                                <div className='boldFont prePostTradeHeaders'>Post-Trade</div> 
                                <div className='highlightOutfit prePostTradeHeaders'>{getLineupTotalScore(false)}</div>
                            </div>
                            <LineupDisplay lineup={getLineup(false)} lineupDifferences={getLineupDifferencesToDisplay()}></LineupDisplay>
                            {/* <div className='highlightOutfit'>{props.selectedOpponentTeamName}</div>
                            <LineupDisplay lineup={props.postTradeOpponentStartingLineup["week_" + selectedWeek]} lineupDifferences={opponentLineupDifferences["week_" + selectedWeek]}></LineupDisplay> */}
                            {/* <div className='boldFont totalScore' id="postTradeLineupTotalScore">
                            Starting Lineup Points: {getLineupTotalScore(false)}
                            </div> */}
                        </div>
                    </div>
                </div>
                :
                <div class="warningText responsiveWarningText">Weekly Breakdown responsive view not yet implemented. For full functionality, turn device to landscape and refresh, or view on a larger screen.</div>
            }
        </div>
    );
}

export default WeeklyBreakdown;