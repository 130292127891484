import React from "react";
import { Link } from "react-router-dom"
import { HashLink as Link2 } from 'react-router-hash-link';
import logo from '../../assets/Logo2_small_h.png';
import './NavBar.css';



const NavBar = (props) => {
   

    return (
        <>
            <nav id="topNav" className="navbar fixed-top navbar-dark navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid navText">
                    <Link to='/' id="navBrand" className="navbar-brand boldFont brandText">
                        <img src={logo} width="90" height="30" className="d-inline-block align-top" alt=""/>
                        Fantasy Trade Grade
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Link2 to='/#root' className="nav-link infoFont highlightRobotoHover">Home</Link2>
                            <Link2 to='/#about' className="nav-link infoFont highlightRobotoHover">About</Link2>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );

};



export default NavBar;