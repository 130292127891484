import './App.css';
import React from 'react';
import UsernameForm from './components/TradeData/UsernameForm';
import MainContent from './components/Home/MainContent';
import NavBar from './components/Header/NavBar';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/js/bootstrap.bundle';
import {Routes, Route} from 'react-router-dom';
import LeagueSelector from './components/TradeData/LeagueSelector';
import LeagueViewErrorBoundary from './components/TradeData/LeagueViewErrorBoundary';

const initialVars = {
    league_id: 1

}

let debug = {
  enabled: false,
  tailEndOfSeason : false,
  use2022Season : false
};


function App() {



  return React.createElement(

    'div',
    {id: "App"},
    //<PlayerSearchForm></PlayerSearchForm>
    <>
      <NavBar></NavBar>
      <Routes>
        <Route path='/' element={<MainContent debug={debug} />}></Route>
        <Route path={'/enterusername'} element={<UsernameForm debug={debug}></UsernameForm>}></Route>
        {/* <Route path={'/:leagueType/:username/:displayname/:user_id'} element={<LeagueSelector></LeagueSelector>}></Route> */}
        <Route path={'/leagueselector'} element={<LeagueSelector debug={debug}></LeagueSelector>}></Route>
        <Route path={'/leagueview'} element={<LeagueViewErrorBoundary debug={debug}></LeagueViewErrorBoundary>}></Route>
        <Route path='*' element={<MainContent debug={debug} />}></Route>
      </Routes>
      <Footer></Footer>
    </>

    //<MainContent></MainContent>
    //<UsernameForm leagueType={"sleeper"}></UsernameForm>
    //<TradeData></TradeData>
  );
}

export default App;
