import { LeagueUser } from "./LeagueUser";
export class CurrentUser extends LeagueUser {
    constructor(userData, rosterData, nflStateData, leagueType, current_user_id, leagueObject) {
        let current_user_data = null;
        let current_user_roster = null;
        if(leagueType === "sleeper") {
            for(let user of userData.entries()) {
                if(user[1].user_id === current_user_id) {
                    current_user_roster = rosterData.find(roster => roster.owner_id === user[1].user_id); //get the matching roster for the user
                    current_user_data = user[1];
                    break;
                }
            }

            // for(let roster of rosterData.entries()) {
            //     if(current_user_id === roster[1].owner_id) {
            //         current_user_roster = roster; //get the matching roster for the user
            //         current_user_data = userData.find(user => roster[1].owner_id === user.user_id); //get the matching roster for the user
            //         break;
            //     }
            // }


        }
        super(current_user_data, current_user_roster, nflStateData, leagueObject, leagueType);
        //see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/super for super explanation
    }

    getCurrentUserDisplayName() {
        return this.display_name;
    }

    getCurrentUserTeamName() {
        return this.metadata.team_name;
    }

    getPlayerCardDetailedInfo(player_id, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek) {
        return this.getRosterData().getPlayerCardDetailedInfo(player_id, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek);
    }

    //This is duplicated in LeagueUser.js , if you change one, change both (and figure out way to have them share functions...)
    getPlayerProjectionsJsonFromFullData() {
        return this.getRosterData().getPlayerProjectionsJsonFromFullData();
    }

    getPlayerRemainingProjectionPointsFromFullData(player_id, startWeek, endWeek) {
        return this.getRosterData().getPlayerRemainingProjectionPointsFromFullData(player_id, startWeek, endWeek);
    }

    getPlayers() {
        return this.getRosterData().getPlayersArray();
    }

    getRosterData() {
        return this.roster_data;
    }

    getPlayersPositon(player_id) {
        return this.getRosterData().getPlayerPositionFromFullData(player_id);
    }

    //This is duplicated in LeagueUser.js , if you change one, change both (and figure out way to have them share functions...)
    getPlayerValueFromFullData(playerId, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek) {
        return this.getRosterData().getPlayerValueFromFullData(playerId, replacementLevelValuesJson, leaguesLargestParScore, useNegativePlayerValues, startWeek, endWeek);
    }

    // getReplacementLevelValueForPlayerPosition(player_id) {
    //     let playerPosition = this.getPlayersPositon(player_id);
    //     let replacementLevelValue = this.getReplacementLevelValueForPlayerPosition(playerPosition);
    //     return replacementLevelValue;
    // }

    setAllPlayerValues(replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek) {
        this.getRosterData().setPlayerValues(replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek);
    }
}


//const usersRoster = rosterData.find(roster => roster.owner_id === user[1].user_id); //get the matching roster for the user
//this.userList.push(new LeagueUser(user[1], usersRoster, leagueType));