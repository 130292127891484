import React, { useState, useEffect, useRef } from "react";
import LeagueTeamsView from "./LeagueTeamsView";
import CurrentUserTeamView from "./CurrentUserTeamView";
import TradeBreakdownView2 from "./TradeBreakdownView2";
import Loader from "./Loader";
import './LeagueView.css';
import { League } from "./ObjectClasses/League";
import { LeagueUsers } from "./ObjectClasses/LeagueUsers";
import { CurrentUser } from "./ObjectClasses/CurrentUser";
import { useSearchParams } from 'react-router-dom';
import { HashLink as Link2 } from 'react-router-hash-link';
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";
import UserRoster from "./ObjectClasses/UserRoster";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ONE_WEEK_MS = 604800000;
const ONE_DAY_MS = 86400000;
const ONE_HOUR_MS = 3600000;

let weeks = [0, 18];

let currentUsersRosterObject2;
let leagueUsersRosterObject2;
const breakdownId = 'tradeBreakdown';
const leagueSettingsKeySuffix = 'leagueSettings';



const LeagueView = (props) => {
    const listOfFlexPositions = ["RB", "WR", "TE"];
    const analyzeTradeButtonText = "Analyze";

    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedLeagueData, setSelectedLeagueData] =  useState({});
    const [nflStateData, setNflStateData] = useState(null);
    const [loadingNflState, setLoadingNflState] = useState(true);
    const [nflStateError, setNflStateError] = useState(null);
    const [selectedLeagueRosters, setSelectedLeagueRosters] =  useState([]);
    const [leagueObject, setLeagueObject] = useState(null); //Important
    const [isAnalyzeButtonClicked, setIsAnalyzeButtonClicked] = useState(false);
    const [isAnalyzeButtonDisabled, setIsAnalyzeButtonDisabled] = useState(true);
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
    const [playerProjectionsForWeekWithPosition, setPlayerProjectionsForWeekWithPosition] = useState(null);
    const [playerInfoJson, setPlayerInfoJson] = useState(null);
    // let playerInfoJson = null;
    let [leagueUsersRosterObject, setLeagueUsersRosterObject] = useState({rosters: 'None'});
    let [currentUsersRosterObject, setCurrentUsersRosterObject] = useState({rosters: 'None'});
    const [allPlayerValuesSet, setAllPlayerValuesSet] = useState(false);
    const [replacementLevelValuesJson, setReplacementLevelValuesJson] = useState(null);

    //const [selectedLeagueData, setSelectedLeagueData] =  useState([]);
    const [isLoadingSelectedLeagueData, setIsLoadingSelectedLeagueData] = useState(true);
    const [isLoadingSelectedLeagueRosters, setIsLoadingSelectedLeagueRosters] = useState(true);
    const [hasSelectedLeagueRosters, setHasSelectedLeagueRosters] = useState(false);
    const [currentUserRoster, setCurrentUserRoster] =  useState([]);
    const [isOpponentSelected, setisOpponentSelected] = useState(false);
    const [selectedOpponentTeamId, setSelectedOpponentTeamId] = useState([]);
    const [currentlySelectedOpponentPlayers, setCurrentlySelectedOpponentPlayers] =  useState([]);
    const [currentlySelectedUserPlayers, setCurrentlySelectedUserPlayers] =  useState([]);
    const [leagueTradeBreakdownSettings, setLeagueTradeBreakdownSettings] =  useState({});
    const [hasNoRosters, setHasNoRosters] =  useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [isMissingRosters, setIsMissingRosters] = useState(false);
    const [showMissingRosters, setShowMissingRosters] = useState(false);

    //Settings:
    const [settingsHaveBeenSet, setSettingsHaveBeenSet] = useState(false);
    const defaultPlayerValueGradeWeight = 50
    const defaultPointDifferentialGradeWeight = 50
    const defaultUseNegativePlayerValues = true;
    const defaultUseReplacementPlayerValues = true;
    const [show, setShow] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false); //used to track drawer state for clickout/close
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [startWeek, setStartWeek] = useState(1);
    const [endWeek, setEndWeek] = useState(18);
    const [playerValueGradeWeight, setPlayerValueGradeWeight] = useState(defaultPlayerValueGradeWeight);
    const [pointDifferentialGradeWeight, setPointDifferentialGradeWeight] = useState(defaultPointDifferentialGradeWeight);
    const [useNegativePlayerValues, setUseNegativePlayerValues] = useState(defaultUseNegativePlayerValues);
    const [useReplacementPlayers, setUseReplacementPlayers] = useState(defaultUseReplacementPlayerValues);
    const [drawerErrorMessage, setDrawerErrorMessage] = useState(null);
    const settingsProp = {startWeek, endWeek, playerValueGradeWeight, pointDifferentialGradeWeight, useNegativePlayerValues, useReplacementPlayers};

    //Temp Settings (used to store values while settings drawer is open):
    const [tempStartWeek, setTempStartWeek] = useState(startWeek);
    const [tempEndWeek, setTempEndWeek] = useState(endWeek);
    const [tempPlayerValueGradeWeight, setTempPlayerValueGradeWeight] = useState(playerValueGradeWeight);
    const [tempPointDifferentialGradeWeight, setTempPointDifferentialGradeWeight] = useState(pointDifferentialGradeWeight);
    
    //Search Bar:
    const [searchInput, setSearchInput] = useState("");
    const [allOpponentPlayersForSearch, setAllOpponentPlayersForSearch] = useState([]);

    //Search Params
    const leagueType = searchParams.get('leaguetype');
    const league_id = searchParams.get('league_id');
    const current_user_id = searchParams.get('user_id');

    const refToTop = useRef();
    const goToTop = () => {
        refToTop.current && refToTop.current.scrollIntoView();
    }
    
    
    const CACHE_VERSION = 1;
    const CURRENT_CACHES = {
        playerInfo: `playerInfo-v${CACHE_VERSION}`,
        backendPlayerInfo: `backendPlayerInfo-v${CACHE_VERSION}`,
        leagueInfo: `leagueInfo-v${CACHE_VERSION}`,
        projectionsForPar: `projectionsForPar-v${CACHE_VERSION}`,
        nflState: `nflState-v${CACHE_VERSION}`,
        leagueRosters: `leagueRosters-v${CACHE_VERSION}`,
        leagueUsers: `leagueUsers-v${CACHE_VERSION}`,
    };


    //Load page
    useEffect(() => {
        const getConstplayerInfoJson = async () => {
            if(leagueType === "sleeper") {
                if(props.debug.enabled === true && props.debug.use2022Season === true){
                    return require('../../sleeperPlayersFile2022.json');
                } else {
                    const backendAllPlayersUrl = 'https://api.fantasytradegrade.com/api/playerInfoFromIds';
                    // const backendAllPlayersUrl = 'http://localhost:8000/api/playerInfoFromIds';
                    caches.open(CURRENT_CACHES.backendPlayerInfo).then(async (cache) => {
                        const response = await cache.match(backendAllPlayersUrl);
                        let noCache = true;
                        if (response) {
                            //There is a matching cache entry
                             const date = new Date(response.clone().headers.get('date'));
                             // if cached file is less than 7 days old, use it
                             if(Date.now() < date.getTime() + 1000 * 60 * 60 * 24 * 7){
                                 const returnJson = await response.json();
                                 setPlayerInfoJson(returnJson);
                                 noCache = false;
                             } else {
                                caches.delete(CURRENT_CACHES.backendPlayerInfo);
                             }
                        } 
                        if(noCache) {
                            //No matching cache entry
                            const networkResponse = await fetch(backendAllPlayersUrl);
                            if (networkResponse.clone().status < 400) {
                                cache.put(backendAllPlayersUrl, networkResponse.clone());
                            }
                            const returnJson = await networkResponse.json();
                            setPlayerInfoJson(returnJson);
                        }
                    });
                }
            }
        }

        if(!playerInfoJson) {
            getConstplayerInfoJson();
        }        

        if(nflStateData === null) {
            getNflStateJsonFromEndpointNew();
        }
        
        if(selectedLeagueData.league_id === undefined) {
            
            getSelectedLeagueData();

        }

        if(nflStateData !== null && playerProjectionsForWeekWithPosition === null && playerInfoJson) {
            getPlayerProjectionsForWeekWithPosition(nflStateData.league_season, nflStateData.week)
        }

        if(!hasSelectedLeagueRosters && Object.entries(selectedLeagueData).length > 0 && playerProjectionsForWeekWithPosition !== null && playerInfoJson) {
            if(!settingsHaveBeenSet) {
                setSettings();
            }
            getSelectedLeagueRosters(nflStateData, selectedLeagueData, leagueType, weeks);
        }

        if(leagueObject !== null && leagueObject._replacement_level_values !== null && leagueObject.largest_par_score !== null && leagueObject.isDoneLoading && isMissingRosters !== true) {   
            if(allOpponentPlayersForSearch.length === 0) {
                setAllOpponentPlayersForSearch(Object.values(leagueUsersRosterObject.getAllPlayersOnLeagueUserTeams()));
            }
            setShowLoading(false);
        }
    }, [playerInfoJson, nflStateData, selectedLeagueData, isMissingRosters, settingsHaveBeenSet, hasSelectedLeagueRosters, playerProjectionsForWeekWithPosition]);


    const checkPlayerHandler = (checkedPlayerId, isChecked, teamId, isCurrentUser) => {
        //Current user player
        let hasCurrentUserPlayer = false;
        let hasOpponentUserPlayer = false;
        if(isCurrentUser) {
            if(isChecked) {
                //add to currentlySelectedUserPlayers
                let player = createCurrentUsersTeamPlayerObject(checkedPlayerId, teamId);
                setCurrentlySelectedUserPlayers([...currentlySelectedUserPlayers, player]);
                hasCurrentUserPlayer = true;
            } else {
                //remove player from currentlySelectedUserPlayers
                for( var i = 0; i < currentlySelectedUserPlayers.length; i++){ 
                    if (currentlySelectedUserPlayers[i].player_id === checkedPlayerId) { 
                        currentlySelectedUserPlayers.splice(i, 1);
                        setCurrentlySelectedUserPlayers([...currentlySelectedUserPlayers]);
                        setIsAnalyzeButtonClicked(false);
                        break; 
                    }
                }
            }
        } else { //Other team player
            if(isChecked) {
                let player = createOpponentPlayerObject(checkedPlayerId, teamId);
                //CHASE IF PLAYERS FROM OTHER TEAM ARE CHECKED, CLEAR THEM in DOM and from this state obj
                if(currentlySelectedOpponentPlayers.length > 0) {
                    let teamOfFirstPlayerInCurrentSelection = currentlySelectedOpponentPlayers[0].teamId;
                    if(teamId === teamOfFirstPlayerInCurrentSelection) {
                        //add player to selection, he's on the same team
                        setCurrentlySelectedOpponentPlayers([...currentlySelectedOpponentPlayers, player]);
                    } else {
                        //he's not on the same team, start new selection with player and clear in dom
                        setCurrentlySelectedOpponentPlayers([...[], player]); //chase for some reason without this line it doesn't clear checkbox unless you select from a third team?!?
                        deSelectAllOpponentTeamPlayersOtherThanPassedTeam(teamId);
                        //this.forceUpdate();
                    }
                } else {
                    //... update to json as well probably?
                    setCurrentlySelectedOpponentPlayers([...currentlySelectedOpponentPlayers, player]);
                }
                hasOpponentUserPlayer = true;
            } else {
                //remove player from currentlySelectedOpponentPlayers
                for( var j = 0; j < currentlySelectedOpponentPlayers.length; j++){ 
                    if (currentlySelectedOpponentPlayers[j].player_id === checkedPlayerId) { 
                        currentlySelectedOpponentPlayers.splice(j, 1);
                        setCurrentlySelectedOpponentPlayers([...currentlySelectedOpponentPlayers]);
                        setIsAnalyzeButtonClicked(false);
                        break; 
                    }
                }
            }
        }
        if((currentlySelectedOpponentPlayers.length > 0 || hasOpponentUserPlayer) && (currentlySelectedUserPlayers.length > 0 || hasCurrentUserPlayer)) {
            setIsAnalyzeButtonDisabled(false);
        } else if((currentlySelectedOpponentPlayers.length === 0 && !hasOpponentUserPlayer) || (currentlySelectedUserPlayers.length === 0 && !hasCurrentUserPlayer)) {
            setIsAnalyzeButtonDisabled(true);
        }

        if((currentlySelectedOpponentPlayers.length > 0 || hasOpponentUserPlayer) || (currentlySelectedUserPlayers.length > 0 || hasCurrentUserPlayer)) {
            setIsResetButtonDisabled(false);
        } else if(currentlySelectedOpponentPlayers.length === 0 && !hasOpponentUserPlayer && currentlySelectedUserPlayers.length === 0 && !hasCurrentUserPlayer) {
            setIsResetButtonDisabled(true)
        }
        setIsAnalyzeButtonClicked(false);
    };

    //Generic function to return a cached response if it exists and is within the acceptable time to live
    //If cached response doesn't exist, fetches URL and writes response to cache
    async function returnLocalBrowserCacheOrResponse(cacheKey, url, timeToLiveInMs) {
        //Time note: 1000 * 60 * 60 * 24 * 7 is 7 days, and constants for hour/day/week are at the top of the class

        return caches.open(cacheKey).then(async (cache) => {
            const response = await cache.match(url);
            let noCache = true;
            if (response) {
                //There is a matching cache entry
                if(response.clone().headers.has('date')) {
                    const date = new Date(response.clone().headers.get('date'));
                    const dateInMs = isNaN(date.getTime()) ? parseInt(response.clone().headers.get('date')) : date.getTime();
                    

                    if(Date.now() < dateInMs + timeToLiveInMs){
                        // const returnJson = await response.json();
                        noCache = false;
                        // return returnJson;
                        return response;
                    } else {
                        caches.delete(cacheKey);
                    }
                }
                 
            } 
            if(noCache) {
                //No matching cache entry
                const networkResponse = await fetch(url);
                if (networkResponse.clone().status < 400) {
                    if(networkResponse.clone().headers.has('date')) {
                        cache.put(url, networkResponse.clone());
                        return networkResponse;
                    } else {

                        const newHeaders = new Headers(networkResponse.clone().headers)
                        newHeaders.set('date', Date.now());
                        const alteredResponse = new Response(networkResponse.clone().body, {
                            headers: newHeaders
                        }) 
                        // let alteredResponse = networkResponse.clone();
                        // alteredResponse.headers.append('date', Date.now());
                        cache.put(url, alteredResponse.clone());
                        return alteredResponse;
                        // const returnJson = await alteredResponse.json();
                        // return returnJson;
                    }
                }

                return networkResponse;
                // const returnJson = await networkResponse.json();
                // return returnJson;
            }
        });
    }

    async function getNflStateJsonFromEndpointNew() {
        let nflStateResponseData = null;
        let successfulResponse = false;
        try {
            if(leagueType === 'sleeper') {
                let stateEndpoint = `https://api.sleeper.app/v1/state/nfl`;
                const response = await returnLocalBrowserCacheOrResponse(CURRENT_CACHES.nflState, stateEndpoint, ONE_DAY_MS);

                // const response = await fetch(stateEndpoint);
                if(response.error) {
                    setNflStateError(response.error);
                    setNflStateData(null);
                } else {
                    nflStateResponseData = await response.json();
                    successfulResponse = true;
                }
                
            }
        } catch (err) {
            setNflStateError(err);
        }

        if(successfulResponse) {
            if(props.debug.enabled === true && props.debug.tailEndOfSeason === true) {
                nflStateResponseData.week = 16; //chase remove this, just for testing for now
                
            }
            if(props.debug.enabled === true && props.debug.use2022Season === true) {
                nflStateResponseData.season='2022'; //chase remove this, just for testing for now
                nflStateResponseData.league_season='2022';//chase remove this, just for testing for now
            }
    
            //Set to display week... generally the current/upcoming week we want to see
            nflStateResponseData.week = nflStateResponseData.display_week;
            
            //Don't do things with week 0 - might have to deal with last week also? (above might do that?)
            if(nflStateResponseData.week === 0 || nflStateResponseData.season_type === "pre") {
                nflStateResponseData.week = 1;
            }
            setNflStateData(nflStateResponseData);
            setNflStateError(null);
            setLoadingNflState(false);
        }
    }

    async function getPlayerProjectionsForWeekWithPosition(year, week) {
        if(leagueType === 'sleeper') {
            let projectionEndpoint = `https://api.sleeper.com/projections/nfl/${year}/${week}?season_type=regular&order_by=pts_half_ppr`;
            const response = await returnLocalBrowserCacheOrResponse(CURRENT_CACHES.projectionsForPar, projectionEndpoint, ONE_HOUR_MS);
            // const response = await fetch(projectionEndpoint);
            const json = await response.json();
            setPlayerProjectionsForWeekWithPosition(json);
        }
    }

    async function getSelectedLeagueData() {
        setIsLoadingSelectedLeagueData(true);
        const leagueData = await getSelectedLeagueDataEndpoint(league_id);
        //props.setSelectedLeagueDataInParent(leagueData);
        setSelectedLeagueData(leagueData);
        setLeagueTradeBreakdownSettings(getTradeBreakdownLeagueSettings(leagueData));
        setLeagueObject(new League(leagueData, leagueType));
        setIsLoadingSelectedLeagueData(false);
    }

    async function getSelectedLeagueDataEndpoint(league_id) {
        if(leagueType === 'sleeper') {
            let leagueEndpoint = `https://api.sleeper.app/v1/league/${league_id}`;

            const response = await returnLocalBrowserCacheOrResponse(CURRENT_CACHES.leagueInfo, leagueEndpoint, ONE_WEEK_MS);
            const json = await response.json();
            return json;
        }
    
    }

    async function getSelectedLeagueRosters(nflStateData, selectedLeagueData, leagueType, weeks) {
        let localIsMissingRosters = false;
        setIsLoadingSelectedLeagueRosters(true);
        let leagueRosters = await getSelectedLeagueRostersEndpoint(league_id, leagueType);
        const leagueUsers = await getSelectedLeagueUsersEndpoint(league_id, leagueType);
        const allWeeklyProjections = await getAllWeeklyProjections(nflStateData, leagueType);
        let leagueUsersObject = new LeagueUsers(leagueUsers, leagueRosters, nflStateData, leagueType, current_user_id, leagueObject);
        let currentUserObject = new CurrentUser(leagueUsers, leagueRosters, nflStateData, leagueType, current_user_id, leagueObject);
        //New Code
        //Get User's projections
        await currentUserObject.roster_data.getPlayerInfoAndProjectionsForTeam(allWeeklyProjections, playerInfoJson, leagueObject.getFinalWeekOfSeasonForLeague());
        if(currentUserObject.roster_data.userHasPlayers === false) {
            setIsMissingRosters(true);
            localIsMissingRosters = true;
        }

        //Get Opponent's projections
        for(let user of leagueUsersObject.userList.entries()) {
            await user[1].roster_data.getPlayerInfoAndProjectionsForTeam(allWeeklyProjections, playerInfoJson, leagueObject.getFinalWeekOfSeasonForLeague());
            if(user[1].roster_data.userHasPlayers === false) {
                setIsMissingRosters(true);
                localIsMissingRosters = true;
            }
        }

        let replacementLevelValuesJsonBlock = replacementLevelValuesJson ? replacementLevelValuesJson : null;
        if(leagueObject !== null && (leagueObject._replacement_level_values === null || !replacementLevelValuesJsonBlock) ) {
            replacementLevelValuesJsonBlock = replacementLevelValuesJsonBlock ? replacementLevelValuesJsonBlock : getReplacementLevelValues(playerProjectionsForWeekWithPosition, currentUserObject, leagueUsersObject);
            setReplacementLevelValuesJson(replacementLevelValuesJsonBlock);
            leagueObject.set_replacement_level_values(replacementLevelValuesJsonBlock);
            //Get Highest Points Above Replacement Score for any owned player on any team and set it as _largest_par_score in the leagueObject
            leagueObject.determineAndSetHighestValueScoreForAllOwnedPlayers(currentUserObject, leagueUsersObject, weeks[0], weeks[1]);
        }

        
        if(localIsMissingRosters === true) {
            setShowMissingRosters(true);
        } else if(leagueObject !== null && replacementLevelValuesJsonBlock && leagueObject._replacement_level_values !== null && leagueObject.largest_par_score !== null && leagueObject.isDoneLoading) {
            leagueUsersObject = setAllPlayerValues(leagueUsersObject, replacementLevelValuesJsonBlock, leagueObject.largest_par_score, useNegativePlayerValues, weeks[0], weeks[1]);
            currentUserObject = setUserPlayerValues(currentUserObject, replacementLevelValuesJsonBlock, leagueObject.largest_par_score, useNegativePlayerValues, weeks[0], weeks[1]);
        }

    
        //New code
        setLeagueUsersRosterObject(leagueUsersObject);
        leagueUsersRosterObject2 = leagueUsersObject;
        setCurrentUsersRosterObject(currentUserObject);
        currentUsersRosterObject2 = currentUserObject;
        leagueUsersRosterObject2 = leagueUsersObject;


        // props.setSelectedLeagueRostersInParent(leagueRosters, leagueUsersObject, currentUserObject);
        setIsLoadingSelectedLeagueRosters(false);
        setHasSelectedLeagueRosters(true);
        setAllPlayerValuesSet(true);
    }

    async function getSelectedLeagueRostersEndpoint(league_id, leagueType) {
        if(leagueType === "sleeper") {
            let leagueRostersEndpoint = `https://api.sleeper.app/v1/league/${league_id}/rosters`;
            const response = await returnLocalBrowserCacheOrResponse(CURRENT_CACHES.leagueRosters, leagueRostersEndpoint, ONE_HOUR_MS);
            // const response = await fetch(leagueRostersEndpoint);
            const json = await response.json();
            return json;
        }
    }

    async function getSelectedLeagueUsersEndpoint(league_id, leagueType) {
        if(leagueType === "sleeper") {
            let leagueUsersEndpoint = `https://api.sleeper.app/v1/league/${league_id}/users`;
            const response = await returnLocalBrowserCacheOrResponse(CURRENT_CACHES.leagueUsers, leagueUsersEndpoint, ONE_DAY_MS);

            // const response = await fetch(leagueUsersEndpoint);
            const json = await response.json();
            return json;
        }
    }

    //Set all player values for all players on teams in the leagueUsersObject
    function setAllPlayerValues(leagueUsersObject, replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek) {
        leagueUsersObject.setAllPlayerValues(replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek);
        return leagueUsersObject;
    }

    //Set all player values for all players on the current user's team
    function setUserPlayerValues(currentUserObject, replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek) {
        currentUserObject.setAllPlayerValues(replacementLevelValuesJson, largestParScore, useNegativePlayerValues, startWeek, endWeek);
        return currentUserObject;
    }

    function getReplacementLevelValues(currentWeekProjectionsWithPositions, currentUserTeamObject, leagueUserTeamObject) {
        let replacementLevelPlayers = {};
        let replacementLevelValues = {};
        
        let takenPlayers = getNonFreeAgentPlayersList(currentUserTeamObject, leagueUserTeamObject);
        // let projections = await getPlayerProjectionsForWeekWithPosition(nflStateData.league_season, nflStateData.week);
        let positionsArray = leagueObject.get_unique_roster_positions_array();
        let projectionsToUse = currentWeekProjectionsWithPositions;
        //            return this.roster_positions.filter((value, index, array) => array.indexOf(value) === index);

        //filter projectionsToUse json object to only include entries where player.player_id is not in the array takenPlayers
        projectionsToUse = projectionsToUse.filter(playerEntry => !takenPlayers.includes(playerEntry.player_id));



        //For each position in positionsArray, add top 3 projected scoring players to replacementLevelValues json object
        for(let position of positionsArray) {
            replacementLevelValues[position] = [];
            replacementLevelPlayers[position] = [];

            //filter projectionsToUse json object to only include entries where player.fantasy_positions contains the position name
            let projectionsToUseForPosition = projectionsToUse.filter(playerEntry => playerEntry.player.fantasy_positions && playerEntry.player.fantasy_positions.includes(position));

            let numberOfPlayersToAverageForReplacementLevel = 3;
            replacementLevelPlayers[position]  = projectionsToUseForPosition.slice(0, numberOfPlayersToAverageForReplacementLevel);
        }

        for(let position in replacementLevelPlayers) {
            let tempRunningScore = 0;
            for(let player of replacementLevelPlayers[position]) {
                //getPlayerPointProjectionNew Function imported from UserRoster.js
                tempRunningScore += UserRoster.getPlayerPointProjectionNew(player.stats, selectedLeagueData.scoring_settings, player.player.position);
            }
            if(tempRunningScore === 0) {
                replacementLevelValues[position] = 0;
            } else {
                replacementLevelValues[position] = Math.round(tempRunningScore / replacementLevelPlayers[position].length);
            }
        }

        let flexPositions = Object.keys(replacementLevelPlayers).filter(key => key.includes("FLEX"))

        for(let position of flexPositions) {
            if(replacementLevelValues[position] === 0) {
                replacementLevelValues[position] = getHighestReplacementScoreForFlexPositions(replacementLevelValues, position);
            }
        }        

        return replacementLevelValues;
    
    }

    //Given a list of position replacement values
    //Return the highest replacement value for point total of any FLEX position
    function getHighestReplacementScoreForFlexPositions(replacementLevelValues, position) {
        let allowedFlexPositions = getPositionsAllowedForFlexPosition(position);
        let highestReplacementScore = 0;

        for(let position of allowedFlexPositions) {
            if(replacementLevelValues[position] > highestReplacementScore) {
                highestReplacementScore = replacementLevelValues[position];
            }
        }
        return highestReplacementScore;
    }

    //FLEX positions are dependent on the name of the FLEX position:
        // "FLEX", // WR/RB/TE
        // "WRRB_FLEX", //WR/RB
        // "REC_FLEX", // WR/TE
        // "SUPER_FLEX", // QB/WR/RB/TE
        // "IDP_FLEX" // DL/DT/LB/DT/DE
    //This function will return a list of positions that are allowed for a given FLEX position
    function getPositionsAllowedForFlexPosition(position) {
        if (position === "FLEX") {
            return ["WR", "RB", "TE"];
        }
        if (position === "WRRB_FLEX") {
            return ["WR", "RB"];
        }
        if (position === "REC_FLEX") {
            return ["WR", "TE"];
        }
        if (position === "SUPER_FLEX") {
            return ["QB", "WR", "RB", "TE"];
        }
        if (position === "IDP_FLEX") {
            return ["DL", "DT", "DE", "LB", "CB", "DB"];
        }
        return ["WR", "RB", "TE"];// as a backup  
    }

    //Get the ids of all players on teams and 
    function getNonFreeAgentPlayersList(currentUserTeamObject, leagueUserTeamObject) {
        let takenPlayers = [];
        takenPlayers.push(...currentUserTeamObject.roster_data.players);
        for(let user of leagueUserTeamObject.userList.entries()) {
            takenPlayers.push(...user[1].roster_data.players);
        }
        return takenPlayers;
    }

    async function getAllWeeklyProjections(nflStateData, leagueType) {
        let allProjections = [];

        if(leagueType === "sleeper") {
            let season = nflStateData.season;
            let currentWeek = nflStateData.week;
            
            for(let week = currentWeek; week <= leagueObject.getFinalWeekOfSeasonForLeague(); week++) {
                //chase this could potentially be re-written to use better endpoint with less info - but would take some re-working of the json object expectations most likely
                //endpoint to use: https://api.sleeper.com/projections/nfl/2023/1?season_type=regular&position[]=DEF&position[]=K&position[]=QB&position[]=RB&position[]=TE&position[]=WR&order_by=pts_half_ppr
                const projectionsEndpoint = `https://api.sleeper.app/v1/projections/nfl/regular/${season}/${week}`;
                let minCreatedTime =  new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).getTime(); //24 hours ago
                const weeklyProjections = await writeWeeklyProjectionJsonToLocalBrowserStorageAndReturn("projectionJsonWeek_" + week, minCreatedTime, projectionsEndpoint);
                allProjections.push(weeklyProjections);
            }
        }
        return allProjections;
    }

    function getPlayerName(playerId) {
        if(playerInfoJson[playerId].full_name) {
            return playerInfoJson[playerId].full_name;
        } else {
            return "No Name Found For Player";
        }
    }

    function getPlayerPosition(playerId) {
        return playerInfoJson[playerId].position;
    }
  
    function getPlayerTeam(playerId) {
        return playerInfoJson[playerId].team;
    }
  
    //chase this is going to be behind until we can pull the large json file once a day or so
    function getPlayerInjuryStatus(playerId) {
        return playerInfoJson[playerId].injury_status;
    }


    //remove current user and make them their own roster object
    //return the 2 rosters in an array [0] = league [1] = user
    function stripCurrentUserRoster(leagueRosters, user_id) {
        let userRoster;
        for(let roster of leagueRosters.entries()) {
            if(roster[1].owner_id === user_id) {
                userRoster = roster[1];
                leagueRosters.splice(roster[0], 1);
                break;
            }
        }

        return [leagueRosters, userRoster];
        // setCurrentUserRoster(userRoster);
        // return leagueRosters;
    }

    //New version with localized classes:
    // async function getPlayerInfoForRosters(rosters, nflStateData, leagueSettings) {
    //     let season = nflStateData.season;
    //     let currentWeek = nflStateData.week;
    // }

    async function writeWeeklyProjectionJsonToLocalBrowserStorageAndReturn(key, minCreatedTime, jsonEndpoint) {
        const now = new Date().getTime();
        const createdTimeString = "CreatedTime";
        let jsonData = '';
        //If key doesn't exist or key is older than the passed minimum date, create it
        if((!localStorage.hasOwnProperty(key) || !localStorage.hasOwnProperty(key + createdTimeString)) || (localStorage.hasOwnProperty(key) && localStorage.hasOwnProperty(key + createdTimeString) && (localStorage.getItem(key + createdTimeString) < minCreatedTime))) {
            if(localStorage.hasOwnProperty(key)) {
            localStorage.removeItem(key);
            }
            if(localStorage.hasOwnProperty(key + createdTimeString)) {
            localStorage.removeItem(key + createdTimeString);
            }
            const response = await fetch(jsonEndpoint);
            jsonData = await response.json();
          
            for(let player of Object.entries(jsonData)) {
            //filter out any player who doesn't have "pts_std" in their json object? that or has adP_dd_ppr (this may filter out kickers though...)
            if(player[1].pts_std === undefined) {
                delete jsonData[player[0]];
            }
        }
          
        localStorage.setItem(key + createdTimeString, now)
        localStorage.setItem(key, JSON.stringify(jsonData));
        } else {
          jsonData = JSON.parse(localStorage.getItem(key));
        }
        
        return jsonData;
    }

    //Create player object for trade breakdown screen
    function createCurrentUsersTeamPlayerObject(checkedPlayerId, teamId) {
        return {
            player_id: checkedPlayerId,
            teamId: teamId,
            id: Math.random().toString(),
            player_info: currentUsersRosterObject.roster_data.player_info[checkedPlayerId],
            player_projections: currentUsersRosterObject.roster_data.player_projections[checkedPlayerId]
        };
    }

    function createOpponentPlayerObject(checkedPlayerId, teamId) {
        let opponentTeam;
        for(let team of leagueUsersRosterObject.userList) {
            if(("team_" + team["user_id"]) === teamId) {
                opponentTeam = team;
                break;
            }
        }

        return {
            player_id: checkedPlayerId,
            teamId: teamId,
            id: Math.random().toString(),
            player_info: opponentTeam.roster_data.player_info[checkedPlayerId],
            player_projections: opponentTeam.roster_data.player_projections[checkedPlayerId]
        };
    }

    function deSelectAllOpponentTeamPlayersOtherThanPassedTeam(teamId) {
        let checkedPlayers = document.querySelectorAll(".singleOpponentTeam:not(#" + teamId + ") input[class='opponentPlayerCheckbox']:checked");
        for( var i = 0; i < checkedPlayers.length; i++){ 
            //checkedPlayers[i].checked = false;
            checkedPlayers[i].click();
        }
    }

    function deSelectAllPlayers() {
        let checkedPlayers = document.querySelectorAll("input[class='currentUserPlayerCheckbox']:checked");
        for( var i = 0; i < checkedPlayers.length; i++){ 
            //checkedPlayers[i].checked = false;
            checkedPlayers[i].click();
        }

    }

    function getNumberOfSelectedCurrentUserPlayers() {
        let checkedPlayers = document.querySelectorAll("#currentUserTeamContainer input[class='currentUserPlayerCheckbox']:checked");
        if(checkedPlayers === null || checkedPlayers === undefined) {
            return 0;
        }
        return checkedPlayers.length;
    }

    function deSelectAllOpponentPlayers() {
        let checkedPlayers = document.querySelectorAll(".opponentTeamContainer input[class='currentUserPlayerCheckbox']:checked");
        for( var i = 0; i < checkedPlayers.length; i++){ 
            checkedPlayers[i].click();
        }
    }
    
    function getTradeBreakdownLeagueSettings(leagueData) {
        let roster_positions = leagueData.roster_positions;
        let scoring_settings = leagueData.scoring_settings;
        let playoff_week_start = leagueData.settings.playoff_week_start;
        let returnedSettings = {roster_positions, scoring_settings, playoff_week_start};
        return returnedSettings;
    }

    //chase something below is resulting in the below string???
    //projectedPointTotal: '019.764200000000002bonus_pass_cmp_2513.13000000000000312.56815.74611.3579.21147.94199999999999912.2380000000000017.0600000000000018.13'
    function getPlayerPointProjection(playerSingleWeekProjectionsJson, leagueScoringSettings) {
        let projectedPoints = 0;
        if(leagueScoringSettings) {
            for(let stat of Object.entries(playerSingleWeekProjectionsJson)) {
                if(leagueScoringSettings[stat[0]]) {
                    projectedPoints = Number(projectedPoints + (stat[1] * leagueScoringSettings[stat[0]]));
                }
            }
            //Handle bonuses
            for(let settings of Object.entries(leagueScoringSettings)) {
                if(settings[0].includes("bonus")) {
                    //examples: bonus_pass_yd_300
                    //strip the word bonus off
                    let stat = settings[0].replace('bonus_','');
                    let numberNeededForBonus;
                    //if it contains a number, save and remove number, add to total if player is projected to beat number
                    if(/\d/.test(stat)) {
                        let lastUnderscoreIndex = stat.lastIndexOf('_');
                        //strip and save the number at the end (and remove the trailing _ before the number)
                        numberNeededForBonus = stat.substring(lastUnderscoreIndex + 1);
                        stat = stat.substring(0, lastUnderscoreIndex);

                        //rush_rec_yd is a bonus for all-purpose??
                        if(stat[0].includes('rush_rec_yd')) {
                            let rush_yd = playerSingleWeekProjectionsJson['rush_yd'];
                            let rec_yd = playerSingleWeekProjectionsJson['rec_yd'];
                            if((rush_yd + rec_yd) >= Number(numberNeededForBonus)) {
                                projectedPoints += settings[1];
                            }
                        }
                        //if the user has a stat that matches the remaining string i.e. "pass_yd", and it's >= the saved number, add it to projectedPoints
                        else if(Number(playerSingleWeekProjectionsJson[stat]) >= Number(numberNeededForBonus)) {
                            projectedPoints += settings[1];
                        }
                    } else {
                        //There are bonuses like ... bonus_rec_rb ... bonus_fd_qb
                        //These are a bit more complicated, need to know player's position and match it, also I think fd for a qb is "pass_fd", so would need to match 'qb' and 'pass'
                        //Not as straightforward, requires specific logic for each bonus, do later.
                    }
                    
                }
            }
        } else {
            if(playerSingleWeekProjectionsJson && playerSingleWeekProjectionsJson.pts_half_ppr) {
                return Number(playerSingleWeekProjectionsJson.pts_half_ppr);
            } else {
                return 0;
            }
        }
        return projectedPoints;
    }


 

    const getCardColor = (player_position) => {
        if(player_position === 'RB') {
            return 'rbCard';
        }
        else if(player_position === 'WR') {
            return 'wrCard';
        }
        else if(player_position === 'TE') {
            return 'teCard';
        }
        else if(player_position === 'QB') {
            return 'qbCard';
        }
        else if(player_position === 'DEF') {
            return 'defCard';
        }
        else if(player_position === 'K') {
            return 'kCard';
        }
        else if(player_position === 'DL' || player_position === 'DE' || player_position === 'DT') {
            return 'dlCard';
        }
        else if(player_position === 'DB' || player_position === 'CB') {
            return 'dbCard';
        }
        else if(player_position === 'LB') {
            return 'lbCard';
        }
        else {
            return '';
        }
    }

    //Custom hook for handling Analyze button clicking
    //Maybe this could be used to actually not compute trades until clicked? each time that changes we update the 'trade' in our system with what's clicked
    useEffect(() => {
        jumpToTradeBreakdown(isAnalyzeButtonClicked);
    }, [isAnalyzeButtonClicked]);

    //chase look into replacing this and the jump to top link, and potentially other navigations to ids on click with 
    // https://blog.webdevsimplified.com/2022-06/use-imperative-handle/ <-- the useref example here
    function jumpToTradeBreakdown(isAnalyzeClicked) {
        if(isAnalyzeClicked) {
            const element = document.getElementById(breakdownId);

            // Scroll to the element when the component mounts
            if (element) {
                const yOffset = -70; 
                const element = document.getElementById(breakdownId);
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        }
    }

    function writeSettingsToLocalBrowserStorage() {
        //If you change the key, also change it in setSettings() function
        const key = leagueSettingsKeySuffix + '_' + league_id;
        const storedSettings = getSettingsFromLocalBrowserStorage(key);
        const newSettings = {
            "playerValueWeight": tempPlayerValueGradeWeight,
            "pointDifferentialWeight": tempPointDifferentialGradeWeight,
            "startWeek": tempStartWeek,
            "endWeek": tempEndWeek,
            "useNegativePlayerValues": useNegativePlayerValues,
            "useReplacementPlayers": useReplacementPlayers
        }
        if(storedSettings && JSON.stringify(newSettings) === JSON.stringify(storedSettings)) {
            //Settings aren't changed, do nothing
        } else {
            localStorage.setItem(key, JSON.stringify(newSettings));
        }
    }

    function getSettingsFromLocalBrowserStorage(key) {
        if(localStorage.hasOwnProperty(key)) {
            return JSON.parse(localStorage.getItem(key));
        }
        return null;
    }

    //Some settings need to be altered after league/nfl date loads in, or if the user has stored settings
    function setSettings() {
        const key = leagueSettingsKeySuffix + '_' + league_id;
        const storedSettings = getSettingsFromLocalBrowserStorage(key);
        if(storedSettings) {
            const minStartWeek = nflStateData.week;
            if(storedSettings.startWeek < minStartWeek) {
                setStartWeek(minStartWeek);
                setTempStartWeek(minStartWeek);
                weeks[0] = minStartWeek;
            }
             else {
                setStartWeek(storedSettings.startWeek);
                setTempStartWeek(storedSettings.startWeek)
                weeks[0] = storedSettings.startWeek;
            }
            setEndWeek(storedSettings.endWeek);
            setTempEndWeek(storedSettings.endWeek);
            weeks[1] = storedSettings.endWeek;
            setPlayerValueGradeWeight(storedSettings.playerValueWeight);
            setTempPlayerValueGradeWeight(storedSettings.playerValueWeight);
            setPointDifferentialGradeWeight(storedSettings.pointDifferentialWeight);
            setTempPointDifferentialGradeWeight(storedSettings.pointDifferentialWeight);
            setUseNegativePlayerValues(storedSettings.useNegativePlayerValues);
            setUseReplacementPlayers(storedSettings.useReplacementPlayers);
        } else {
            setStartWeek(nflStateData.week);
            setTempStartWeek(nflStateData.week);
            setEndWeek(leagueObject.getFinalWeekOfSeasonForLeague());
            setTempEndWeek(leagueObject.getFinalWeekOfSeasonForLeague());
            weeks = [nflStateData.week, leagueObject.getFinalWeekOfSeasonForLeague()];
        }
        setSettingsHaveBeenSet(true);
    }

    const analyzeTradeHandler = event => {
        jumpToTradeBreakdown(isAnalyzeButtonClicked);
        setIsAnalyzeButtonClicked(true);
    }

    const resetTradeHandler = event => {
        deSelectAllPlayers();
        setIsAnalyzeButtonDisabled(true);
        setIsResetButtonDisabled(true);
    }

    //Called when minimizing an opponent's team
    const resetOpponentTradeHandler = event => {
        deSelectAllOpponentPlayers();
        setIsAnalyzeButtonDisabled(true);
        if(getNumberOfSelectedCurrentUserPlayers() === 0) {
            setIsResetButtonDisabled(true);
        }
    }

    async function searchableDropdownSelectionHandler(playerId, teamId) {
        teamId = "team_"+teamId;
        await clickOpponentTeamToOpenById(teamId);
        selectPlayerById(playerId, teamId);
    }

    function selectPlayerById(playerId, teamId) {
        const pe = document.querySelector("input[value='"+playerId+"']");
        pe.checked = true;
        checkPlayerHandler(playerId, true, teamId, false)
    }

    //If an opponent's team list is collapsed, click it to open
    //If another opponent is expanded, close them first by clicking their collapse button
    async function clickOpponentTeamToOpenById(teamId) {
        const opponentTeamElement = document.getElementById(teamId);
        if(opponentTeamElement.getElementsByClassName('ownerName')[0].ariaExpanded === 'false' || opponentTeamElement.parentElement.classList.contains('hidden')) {
            if(selectedOpponentTeamId.length && selectedOpponentTeamId !== teamId) {
                //Another team is expanded, close it
                const openOpponentTeamElement = document.getElementById("team_"+selectedOpponentTeamId);
                openOpponentTeamElement.getElementsByTagName("i")[0].click();

                //works but should be done with a wait specific to the changes, not a time
                setTimeout(function() {
                    document.getElementById(teamId).getElementsByTagName("i")[0].click();
                }, 500)
            } else {
                opponentTeamElement.getElementsByTagName("i")[0].click();
            }
        }
    }

    const valueFieldPercentageChange = event => {
        let newValuePercentage = 50;
        if(event.target.valueAsNumber !== undefined && event.target.valueAsNumber >= 0 && event.target.valueAsNumber <= 100) {
            newValuePercentage = event.target.valueAsNumber;
        } else if(isNaN(event.target.valueAsNumber)) {
            newValuePercentage = '';
        }
        setTempPlayerValueGradeWeight(newValuePercentage);
        setTempPointDifferentialGradeWeight(100-newValuePercentage);
    }

    const differenceFieldPercentageChange = event => {
        let newValuePercentage = 50;
        if(event.target.valueAsNumber !== undefined && event.target.valueAsNumber >= 0 && event.target.valueAsNumber <= 100) {
            newValuePercentage = event.target.valueAsNumber;
        } else if(isNaN(event.target.valueAsNumber)) {
            newValuePercentage = '';
        }
        setTempPointDifferentialGradeWeight(newValuePercentage);
        setTempPlayerValueGradeWeight(100-newValuePercentage);
    }

    //Handle input changes on the Settings drawer "End Week" input
    const endWeekInputChange = event => {
        const minWeek = nflStateData.week;
        const maxWeek = 18; //could use leagueObject.getFinalWeekOfSeasonForLeague() but I guess if they want to go 18, let them? 

        if(isNaN(event.target.valueAsNumber)) {
            setTempEndWeek('');
        } else {
            setTempEndWeek(event.target.valueAsNumber);
        }
        if(event.target.valueAsNumber && (event.target.valueAsNumber < minWeek || event.target.valueAsNumber > maxWeek)) {
            setDrawerErrorMessage('End Week is invalid, must be between '+minWeek+' and '+maxWeek+'.');
        } else if(event.target.valueAsNumber < tempStartWeek) {
            setDrawerErrorMessage('End Week is invalid, must be greater than start week.');
        } else if(event.target.valueAsNumber && (event.target.valueAsNumber >= minWeek && event.target.valueAsNumber <= maxWeek)) {
            setDrawerErrorMessage('');
        } else {
            setDrawerErrorMessage('End week is invalid.');
        }
    }

    //Handle input changes on the Settings drawer "Start Week" input
    const startWeekInputChange = event => {
        const minWeek = nflStateData.week;
        const maxWeek = 18; //could use leagueObject.getFinalWeekOfSeasonForLeague() but I guess if they want to go 18, let them?

        if(isNaN(event.target.valueAsNumber)) {
                setTempStartWeek('');
        } else {
            setTempStartWeek(event.target.valueAsNumber);
        }
        if(event.target.valueAsNumber && (event.target.valueAsNumber < minWeek || event.target.valueAsNumber > maxWeek)) {
            setDrawerErrorMessage('Start Week is invalid, must be between '+minWeek+' and '+maxWeek+'.');
        } else if(event.target.valueAsNumber > tempEndWeek) {
            setDrawerErrorMessage('Start Week is invalid, must be less than or equal to end week.');
        } else if(event.target.valueAsNumber && (event.target.valueAsNumber >= minWeek && event.target.valueAsNumber <= maxWeek)) {
            setDrawerErrorMessage('');
        } else {
            setDrawerErrorMessage('Start week is invalid.');
        }
    }

    const useNegativePlayerValuesInputChange = event => {
        setUseNegativePlayerValues(event.target.checked);
    }

    const useReplacementPlayersInputChange = event => {
        setUseReplacementPlayers(event.target.checked);
    }

    //Validate/Correct settings drawer data when it closes
    useEffect(() => {
        if(drawerIsOpen && !show) {
            //On settings drawer close:
            setDrawerIsOpen(false);
            //validate weights
            if(isNaN(tempPlayerValueGradeWeight) || tempPlayerValueGradeWeight === '') {
                if(!isNaN(tempPointDifferentialGradeWeight) && tempPointDifferentialGradeWeight <= 100) {
                    setPlayerValueGradeWeight(100-tempPointDifferentialGradeWeight);
                    setTempPlayerValueGradeWeight(100-tempPointDifferentialGradeWeight);
                } else {
                    setPlayerValueGradeWeight(50);
                    setTempPlayerValueGradeWeight(50);
                }
            } else {
                //playerValueGradeWeight is valid
                setPlayerValueGradeWeight(tempPlayerValueGradeWeight);
            }
            if(isNaN(tempPointDifferentialGradeWeight) || tempPointDifferentialGradeWeight === '') {
                if(!isNaN(tempPlayerValueGradeWeight) && tempPlayerValueGradeWeight <= 100) {
                    setPointDifferentialGradeWeight(100-tempPlayerValueGradeWeight);
                    setTempPointDifferentialGradeWeight(100-tempPlayerValueGradeWeight);
                } else {
                    setPointDifferentialGradeWeight(50);
                    setTempPointDifferentialGradeWeight(50);
                }
            } else {
                //pointDifferentialGradeWeight is valid
                setPointDifferentialGradeWeight(tempPointDifferentialGradeWeight);
            }

            //validate start/end weeks
            const minWeek = nflStateData.week;
            const maxWeek = 18; //could use leagueObject.getFinalWeekOfSeasonForLeague() but I guess if they want to go 18, let them?

            //Validate and set Start Week
            if(isNaN(tempStartWeek) || tempStartWeek === '' || (tempStartWeek < minWeek || tempStartWeek > maxWeek)) {
                setStartWeek(minWeek);
                setTempStartWeek(minWeek);
            } else if(tempStartWeek > tempEndWeek) {
                if(tempEndWeek <= maxWeek) {
                    setStartWeek(tempEndWeek);
                    setTempStartWeek(tempEndWeek);
                }
            } else {
                //tempStartWeek is valid
                setStartWeek(tempStartWeek);
            }

            //Validate and set End Week
            if(isNaN(tempEndWeek) || tempEndWeek === '' || (tempEndWeek < minWeek || tempEndWeek > maxWeek)) {
                setEndWeek(maxWeek);
                setTempEndWeek(maxWeek);
            } else if(tempEndWeek < tempStartWeek) {
                if(tempStartWeek <= maxWeek) {
                    setEndWeek(tempStartWeek);
                    setTempEndWeek(tempStartWeek);
                }
            } else if(tempEndWeek < startWeek) {
                setEndWeek(startWeek);
                setTempEndWeek(startWeek);
            }
             else {
                //tempEndWeek is valid
                setEndWeek(tempEndWeek);
            }
            setDrawerErrorMessage(''); //Remove drawer message

            //Write to local storage for user:
            writeSettingsToLocalBrowserStorage();
        } else if(show) {
            setDrawerIsOpen(true);
        }
      }, [show]);

    return(
        <div ref={refToTop} id="leagueView" className="boldFont mainPageContent">
            <h1  className="largeHeader">Analyze Trades</h1>
                {selectedLeagueData.name && 
                    <div id="centerHeaderDiv">
                        <div id="emptydiv"></div>
                        <div id="leagueNameDiv">
                            <h2 className="highlightOutfit sectionHighlight" id="leagueNameHeader">{selectedLeagueData.name}</h2>
                        </div>
                        <div id="settingsDiv">
                            {!showLoading && <i data-testid="leagueSettingsButton" onClick={handleShow} className="settingsIcon bi bi-gear"></i>}
                        </div>
                    </div>
                }
            {showLoading && !isMissingRosters && <Loader displayText="Loading League, this may take a few moments. Refresh if it takes 20+ seconds"></Loader>}
            {showMissingRosters && <div id="leagueHasNoRostersYet" className="boldFont header warningText">
                This league has incomplete rosters.<br />
                Try again when all teams have players.
            </div>}      
            {!showLoading && !hasNoRosters && !isLoadingSelectedLeagueData && !showMissingRosters && !isMissingRosters &&
                <div>
                    <Offcanvas placement={'end'} show={show} onHide={handleClose}>
                        <Offcanvas.Header data-testid="settingsClose" closeButton>
                        <Offcanvas.Title>League Settings</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Form>
                                <Row id="weightsRow">
                                    <h4 className="infoFont">Grade Weights</h4>
                                    <Col>
                                        <Form.Label htmlFor="valuePercentageField">Player Values</Form.Label>
                                        <div className="percentFormRow">
                                            <Form.Control
                                                type="number"
                                                id="valuePercentageField"
                                                aria-describedby="Player Values Weight %"
                                                onChange={valueFieldPercentageChange}
                                                value={tempPlayerValueGradeWeight}
                                                data-testid="playerValuesWeightField"
                                            />
                                            <span className="percentSign">%</span>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Label htmlFor="differencePercentageField">Point Differential</Form.Label>
                                        <div className="percentFormRow">
                                            <Form.Control
                                                type="number"
                                                id="differencePercentageField"
                                                aria-describedby="Point Differential Weight %"
                                                onChange={differenceFieldPercentageChange}
                                                value={tempPointDifferentialGradeWeight}
                                                data-testid="pointDifferentialWeightField"
                                            />
                                            <span className="percentSign">%</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row id="weeksRow">
                                    <h4 className="infoFont">Weeks</h4>
                                    <Col>
                                        <Form.Label htmlFor="startingWeekField">Start Week</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="startingWeekField"
                                            aria-describedby="Starting Week"
                                            onChange={startWeekInputChange}
                                            value={tempStartWeek}
                                            data-testid="startWeekField"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label htmlFor="endWeekField">End Week</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="endWeekField"
                                            aria-describedby="End Week"
                                            onChange={endWeekInputChange}
                                            value={tempEndWeek}
                                            data-testid="endWeekField"
                                        />
                                    </Col>
                                </Row>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="negative-values-switch"
                                    label="Use negative player values"
                                    value={useReplacementPlayers}
                                    checked={useNegativePlayerValues}
                                    onChange={useNegativePlayerValuesInputChange}
                                />
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="replacement-players-switch"
                                    label="Use replacement players"
                                    value={useReplacementPlayers}
                                    checked={useReplacementPlayers}
                                    onChange={useReplacementPlayersInputChange}
                                />       
                                <Form.Check // prettier-ignore
                                    disabled
                                    type="switch"
                                    id="dynasty-switch"
                                    label="Dynasty (Coming Soon)"
                                />
                                {drawerErrorMessage && <div className="warningText">{drawerErrorMessage}</div>}
                            </Form>
                        </Offcanvas.Body>
                    </Offcanvas>
                    {allOpponentPlayersForSearch.length > 0 && 
                        <SearchableDropdown
                            options={allOpponentPlayersForSearch}
                            name="player_name"
                            team="player_team"
                            position="player_position"
                            playerId="player_id"
                            ownerId="owner_id"
                            value="player_value"
                            id="searchBar"
                            selectedVal={searchInput}
                            handleChange={(val) => setSearchInput(val)}
                            placeholder="Search Player..."
                            searchableDropdownSelectionHandler={searchableDropdownSelectionHandler}
                        />
                    }
                    <div className="buttonPair" id="analyzeResetButtonsTop">
                        <button data-testid="testResetTop" disabled={isResetButtonDisabled} onClick={resetTradeHandler} id='resetTradeButtonTop' className='halfButton resetButton secondaryButton linkButton siteButton boldFont'>Reset</button>
                        <button disabled={isAnalyzeButtonDisabled} onClick={analyzeTradeHandler} id='analyzeTradeButtonTop' className='halfButton analyzeButton linkButton siteButton boldFont'>{analyzeTradeButtonText}</button>
                    </div>
                    <div className="leagueTeamsView">
                        {!isLoadingSelectedLeagueRosters && currentUserRoster && 
                            <CurrentUserTeamView getCardColor={getCardColor} currentUsersRosterObject={currentUsersRosterObject} leagueType={leagueType} onCheckPlayer={checkPlayerHandler} currentUserRoster={currentUserRoster} current_user_id={current_user_id}></CurrentUserTeamView>
                        }
                        {!isLoadingSelectedLeagueRosters && selectedLeagueRosters && allPlayerValuesSet && 
                            <LeagueTeamsView allPlayerValuesSet={allPlayerValuesSet} selectedOpponentTeamId={selectedOpponentTeamId} setSelectedOpponentTeamId={setSelectedOpponentTeamId} isOpponentSelected={isOpponentSelected} resetOpponentTradeHandler={resetOpponentTradeHandler} getCardColor={getCardColor} leagueUsersRosterObject={leagueUsersRosterObject} leagueType={leagueType} onCheckPlayer={checkPlayerHandler} leagueRosters={selectedLeagueRosters} ></LeagueTeamsView>
                        }     
                    </div>
                    <div className="buttonPair" id="analyzeResetButtonsBottom">
                        <button disabled={isResetButtonDisabled} onClick={resetTradeHandler} id='resetTradeButtonBottom' className='halfButton resetButton secondaryButton linkButton siteButton boldFont'>Reset</button>
                        <button disabled={isAnalyzeButtonDisabled} onClick={analyzeTradeHandler} id='analyzeTradeButtonBottom' className='halfButton analyzeButton linkButton siteButton boldFont'>{analyzeTradeButtonText}</button>
                    </div>
                    {isAnalyzeButtonClicked && currentlySelectedUserPlayers.length > 0 && 
                        <TradeBreakdownView2 settings={settingsProp} listOfFlexPositions={listOfFlexPositions} getCardColor={getCardColor} id={breakdownId} leagueType={leagueType} leagueObject={leagueObject} currentUsersRosterObject={currentUsersRosterObject} leagueUsersRosterObject={leagueUsersRosterObject} currentlySelectedUserPlayers={currentlySelectedUserPlayers} currentlySelectedOpponentPlayers={currentlySelectedOpponentPlayers} currentUserRoster={currentUserRoster} leagueRosters={selectedLeagueRosters} tradeBreakdownLeagueSettings={leagueTradeBreakdownSettings}></TradeBreakdownView2>    
                    }
                    <Link2 onClick={goToTop} id='jumpToTop' className='infoFont'>Jump To Top</Link2>
                </div>
            }
        </div>
    );
    
};


export default LeagueView;

