export class LeagueScoringSettings {
    constructor(data, leagueType) {
        if(leagueType === "sleeper") {
            this.scoring_settings = data;
            // this.blk_kick = data.blk_kick;
            // this.blk_kick_ret_yd = data.blk_kick_ret_yd;
            // this.bonus_def_fum_td_50p = data.bonus_def_fum_td_50p;
            // this.bonus_def_int_td_50p = data.bonus_def_int_td_50p;
            // this.bonus_fd_qb = data.bonus_fd_qb;
            // this.bonus_fd_rb = data.bonus_fd_rb;
            // this.bonus_fd_te = data.bonus_fd_te;
            // this.bonus_fd_wr = data.bonus_fd_wr;
            // this.bonus_pass_cmp_25 = data.bonus_pass_cmp_25;
            // this.bonus_pass_yd_300 = data.bonus_pass_yd_300;
            // this.bonus_pass_yd_400 = data.bonus_pass_yd_400;
            // this.bonus_rec_rb = data.bonus_rec_rb;
            // this.bonus_rec_te = data.bonus_rec_te;
            // this.bonus_rec_wr = data.bonus_rec_wr;
            // this.bonus_rec_yd_100 = data.bonus_rec_yd_100;
            // this.bonus_rec_yd_200 = data.bonus_rec_yd_200;
            // this.bonus_rush_att_20 = data.bonus_rush_att_20;
            // this.bonus_rush_rec_yd_100 = data.bonus_rush_rec_yd_100;
            // this.bonus_rush_rec_yd_200 = data.bonus_rush_rec_yd_200;
            // this.bonus_rush_yd_100 = data.bonus_rush_yd_100;
            // this.bonus_rush_yd_200 = data.bonus_rush_yd_200;
            // this.bonus_sack_2p = data.bonus_sack_2p;
            // this.bonus_tkl_10p = data.bonus_tkl_10p;
            // this.def_2pt = data.def_2pt;
            // this.def_3_and_out = data.def_3_and_out;
            // this.def_4_and_stop = data.def_4_and_stop;
            // this.def_forced_punts = data.def_forced_punts;
            // this.def_kr_yd = data.def_kr_yd;
            // this.def_pass_def = data.def_pass_def;
            // this.def_pr_yd = data.def_pr_yd;
            // this.def_st_ff = data.def_st_ff;
            // this.def_st_fum_rec = data.def_st_fum_rec;
            // this.def_st_td = data.def_st_td;
            // this.def_st_tkl_solo = data.def_st_tkl_solo;
            // this.def_td = data.def_td;
            // this.ff = data.ff;
            // this.fg_ret_yd = data.fg_ret_yd;
            // this.fgm = data.fgm;
            // this.fgm_0_19 = data.fgm_0_19;
            // this.fgm_20_29 = data.fgm_20_29;
            // this.fgm_30_39 = data.fgm_30_39;
            // this.fgm_40_49 = data.fgm_40_49;
            // this.fgm_50p = data.fgm_50p;
            // this.fgm_yds = data.fgm_yds;
            // this.fgm_yds_over_30 = data.fgm_yds_over_30;
            // this.fgmiss = data.fgmiss;
            // this.fgmiss_0_19 = data.fg;
            // this.fgmiss_20_29 = data.fgmiss_20_29;
            // this.fgmiss_30_39 = data.fgmiss_30_39;
            // this.fgmiss_40_49 = data.fgmiss_40_49;
            // this.fgmiss_50p = data.fgmiss_50p;
            // this.fum = data.fum;
            // this.fum_lost = data.fum_lost;
            // this.fum_rec = data.fum_rec;
            // this.fum_rec_td = data.fum_rec_td;
            // this.fum_ret_yd = data.fum_ret_yd;
            // this.idp_pass_def_3p = data.idp_pass_def_3p;
            // this.idp_sack_yd = data.idp_sack_yd;
            // this.int = data.int;
            // this.int_ret_yd = data.int_ret_yd;
            // this.kr_yd = data.kr_yd;
            // this.pass_2pt = data.pass_2pt;
            // this.pass_att = data.pass_att;
            // this.pass_cmp = data.pass_cmp;
            // this.pass_cmp_40p = data.pass_cmp_40p;
            // this.pass_fd = data.pass_fd;
            // this.pass_inc = data.pass_inc;
            // this.pass_int = data.pass_int;
            // this.pass_int_td = data.pass_int_td;
            // this.pass_sack = data.pass_sack;
            // this.pass_td = data.pass_td;
            // this.pass_td_40p = data.pass_td_40p;
            // this.pass_td_50p = data.pass_td_50p;
            // this.pass_yd = data.pass_yd;
            // this.pr_yd = data.pr_yd;
            // this.pts_allow = data.pts_allow;
            // this.pts_allow_0 = data.pts_allow_0;
            // this.pts_allow_1_6 = data.pts_allow_1_6;
            // this.pts_allow_14_20 = data.pts_allow_14_20;
            // this.pts_allow_21_27 = data.pts_allow_21_27;
            // this.pts_allow_28_34 = data.pts_allow_28_34;
            // this.pts_allow_35p = data.pts_allow_35p;
            // this.pts_allow_7_13 = data.pts_allow_7_13;
            // this.qb_hit = data.qb_hit;
            // this.rec = data.rec;
            // this.rec_0_4 = data.rec_0_4;
            // this.rec_10_19 = data.rec_10_19;
            // this.rec_20_29 = data.rec_20_29;
            // this.rec_2pt = data.rec_2pt;
            // this.rec_30_39 = data.rec_30_39;
            // this.rec_40p = data.rec_40p;
            // this.rec_5_9 = data.rec_5_9;
            // this.rec_fd = data.rec_fd;
            // this.rec_td = data.rec_td;
            // this.rec_td_40p = data.rec_td_40p;
            // this.rec_td_50p = data.rec_td_50p;
            // this.rec_yd = data.rec_yd;
            // this.rush_2pt = data.rush_2pt;
            // this.rush_40p = data.r_rush_40p;
            // this.rush_att = data.rush_att;
            // this.rush_fd = data.rush_fd;
            // this.rush_td = data.rush_td;
            // this.rush_td_40p = data.rush_td_40p;
            // this.rush_td_50p = data.rush_td_50p;
            // this.rush_yd = data.rush_yd;
            // this.sack = data.sack;
            // this.sack_yd = data.sack_yd;
            // this.safe = data.safe;
            // this.st_ff = data.st_ff;
            // this.st_fum_rec = data.st_fum_rec;
            // this.st_td = data.st_td;
            // this.st_tkl_solo = data.st_tkl_solo;
            // this.tkl = data.tkl;
            // this.tkl_ast = data.tkl_ast;
            // this.tkl_loss = data.tkl_loss;
            // this.tkl_solo = data.tkl_solo;
            // this.xpm = data.xpm;
            // this.xpmiss = data.xpmiss;
            // this.yds_allow = data.yds_allow;
            // this.yds_allow_0_100 = data.yds_allow_0_100;
            // this.yds_allow_100_199 = data.yds_allow_100_199;
            // this.yds_allow_200_299 = data.yds_allow_200_299;
            // this.yds_allow_300_349 = data.yds_allow_300_349;
            // this.yds_allow_350_399 = data.yds_allow_350_399;
            // this.yds_allow_400_449 = data.yds_allow_400_449;
            // this.yds_allow_450_499 = data.yds_allow_450_499;
            // this.yds_allow_500_549 = data.yds_allow_500_549;
            // this.yds_allow_550p = data.yds_allow_550p;
        }
    }
}