import React from "react";

//Props:
// onClick={handleClickOnTeamList} 
// isCollapsed={!isOpponentSelected} 
// userRosterObject={current_roster} <-- a UserRoster object
// index={index} <-- index of the user in the team list?



const ownerDisplayNameCard2 = (props) => {
    let userRosterObject = props.userRosterObject;

    function getTeamName() {
        return (userRosterObject.metadata.team_name)
    }

    return (
        <div onClick={props.onClick} className="ownerName boldFont" key={userRosterObject.getUserID()} data-index={props.index} data-ownerid={userRosterObject.getUserID()} id={"team_" + userRosterObject.getUserID()} data-bs-toggle="collapse" data-bs-target="#collapsePlayerList" aria-expanded="false" aria-controls="collapseExample">
            {props.isCollapsed && <i className="bi bi-chevron-right"></i>}
            {!props.isCollapsed && <i data-testid="currentOpponentCollapse" className="bi bi-chevron-down"></i>}
            <div className="teamName">{getTeamName()}</div><span className="infoFont ownerDisplayName">@{userRosterObject.display_name}</span>
        </div>
    );
}

export default ownerDisplayNameCard2;