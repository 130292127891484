export class LeagueSettings {
    constructor(settingsData, leagueType) {
        if(leagueType === "sleeper") {
            this._playoff_week_start = settingsData.playoff_week_start;
            this._last_scored_leg =  settingsData.last_scored_leg;
            this._num_teams = settingsData.num_teams;
            this._start_week = settingsData.start_week;
            this._trade_deadline = settingsData.trade_deadline;
            this.end_week = this.calculateEndWeek(settingsData.playoff_week_start, settingsData.playoff_round_type, settingsData.playoff_teams);
        }
    }

    //playoffStartWeek: the week that the playoffs start
    //RoundType: 
        //0: single week per playoff round
        //1: single week per playoff round, except for championship which is 2 weeks
        //2: 2 weeks per playoff round
    //numberOfPlayoffTeams: The number of teams that make the playoffs
    //Given the above, return the final week of the season that has games in this league
    calculateEndWeek(playoffStartWeek, roundType, numberOfPlayoffTeams) {
        //playoffStartWeek of 0 means no playoffs
        if(playoffStartWeek !== 0) {
            //Math.log2(x) gives you the number of times you need to divide a number by 2 to get to 1. Round it (up in realistic cases) because it can be a decimal.
            const numberOfPlayoffRounds = Math.round(Math.log2(numberOfPlayoffTeams)); 
            let numberOfPlayoffWeeks = numberOfPlayoffRounds;
            if(roundType === 1) {
                numberOfPlayoffWeeks = numberOfPlayoffWeeks + 1; //Extra week for championship round
            }

            if(roundType === 2) {
                numberOfPlayoffWeeks = numberOfPlayoffWeeks * 2; //2 weeks per round.
            }

            let finalWeek = (playoffStartWeek -1) + numberOfPlayoffWeeks;
            if(finalWeek <= 18 && finalWeek >= 11) {
                return finalWeek;
            }
        }

        //Team has no playoffs, or fallback if something goes wrong.
        return 18
    }

    //Getters
    get playoff_week_start() {
        return this._playoff_week_start;
    }

    get last_scored_leg() {
        return this._last_scored_leg;
    }

    get num_teams() {
        return this._num_teams;
    }

    get start_week() {
        return this._start_week;
    }

    get trade_deadline() {
        return this._trade_deadline;
    }

    //Setters
    set playoff_week_start(value) {
        this._playoff_week_start = value;
    }

    set last_scored_leg(value) {
        this._last_scored_leg = value;
    }

    set num_teams(value) {
        this._num_teams = value;
    }

    set start_week(value) {
        this._start_week = value;
    }

    set trade_deadline(value) {
        this._trade_deadline = value;
    }
    
}
