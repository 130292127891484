export class LeagueRosterPositions {

    //Chase : Kickers? Defense?
    constructor(data, leagueType) {
        this.roster_positions = null;
        this.QBSpots = 0;
        this.RBSpots = 0;
        this.WRSpots = 0;
        this.TESpots = 0;
        this.FLEXSpots = 0;
        this.WRRB_FLEXSpots = 0;
        this.REC_FLEXSpots = 0;
        this.SUPER_FLEXSpots = 0;
        if(leagueType === "sleeper") {
            this.roster_positions = data;
            for(let spot of this.roster_positions.entries()) {
                switch(spot[1]) {
                    case 'QB':
                        this.QBSpots++;
                        break;
                    case 'RB':
                        this.RBSpots++;
                        break;
                    case 'WR':
                        this.WRSpots++;
                        break;
                    case 'TE':
                        this.TESpots++;
                        break;
                    case 'FLEX':
                        this.FLEXSpots++;
                        break;
                    case 'WRRB_FLEX':
                        this.WRRB_FLEXSpots++;
                        break;
                    case 'REC_FLEX':
                        this.REC_FLEXSpots++;
                        break;
                    case 'SUPER_FLEX':
                        this.SUPER_FLEXSpots++;
                        break;
                    default:
                    //ignore
                } 
            }
        }    
    }

    getRosterSpots() {
        return {QBSPOTS: this.QBSpots, RBSPOTS: this.RBSpots, WRSPOTS: this.WRSpots, TESPOTS: this.TESpots, FLEXSPOTS: this.FLEXSpots};
    };

    getUniqueRosterPositionsArray() {
        if(this.roster_positions != null) {
            return this.roster_positions.filter((value, index, array) => array.indexOf(value) === index);
        }
    }

    //Return all types of FLEX positions in the league
    getAllFlexPositionTypesInLeague() {
        if(this.roster_positions != null) {
            return this.roster_positions.filter(value => value.includes("FLEX"));
        }
    }

    //FLEX positions are dependent on the name of the FLEX position:
        // "FLEX", // WR/RB/TE
        // "WRRB_FLEX", //WR/RB
        // "REC_FLEX", // WR/TE
        // "SUPER_FLEX", // QB/WR/RB/TE
    //This function will return a list of positions that are allowed for a given FLEX position
    getPositionsAllowedForFlexPosition(position) {
        if(position === "FLEX") {
            return ["WR", "RB", "TE"];
        }
        if(position === "WRRB_FLEX") {
            return ["WR", "RB"];
        }
        if(position === "REC_FLEX") {
            return ["WR", "TE"];
        }
        if(position === "SUPER_FLEX") {
            return ["QB", "WR", "RB", "TE"];
        }
        return ["WR", "RB", "TE"];
    }

    getFlexPositionsAllowedForPlayerPosition(position) { 
        if(position === "RB") {
            return ["FLEX", "WRRB_FLEX", "SUPER_FLEX"];
        }
        if(position === "WR") {
            return ["FLEX", "REC_FLEX", "SUPER_FLEX", "WRRB_FLEX"];
        }
        if(position === "TE") {
            return ["FLEX", "REC_FLEX", "SUPER_FLEX"];
        }
        if(position === "QB") {
            return ["SUPER_FLEX"];
        }
        if(position === "LB" || position === "DL" || position === "DB" || position === "DT" || position === "DE" || position === "CB") {
            return ["IDP_FLEX"];
        }
    }

    getFlexPositionsAllowedForPlayerPositionForThisLeague(position) {
        if(this.getAllLeaguePositionsThatCanBeUsedForFlex().includes(position)){
            let possibleFlexPositionsAllowedForPlayerPosition = this.getFlexPositionsAllowedForPlayerPosition(position);
            let flexPositionsInThisLeague = this.getAllFlexPositionTypesInLeague();
            return flexPositionsInThisLeague.filter(value => possibleFlexPositionsAllowedForPlayerPosition.includes(value));
        }
        return null;
    }
    
    //Get all the positions that can go into any of the FLEX roster spots in the league
    getAllLeaguePositionsThatCanBeUsedForFlex() {
        let flexPositions = this.getAllFlexPositionTypesInLeague();
        
        //For each flex position, get a unique list of the positions that are in flex positions
        let positionsAllowedForFlex = [];
        for(let position of flexPositions) {
            positionsAllowedForFlex.push(...this.getPositionsAllowedForFlexPosition(position));
        }

        return positionsAllowedForFlex.filter((value, index, array) => array.indexOf(value) === index);
    }
    
}