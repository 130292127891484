import React from "react";
import './Loader.css';
import Spinner from 'react-bootstrap/Spinner';

const Loader = (props) => {

    

    return(
        <div id="spinnerDiv">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">{props.displayText}</span>
            </Spinner>
            <p className='boldFont spinnerText'>{props.displayText}</p>
        </div>

    )
}

export default Loader;