import React from "react";

import './AboutSection.css';


const AboutSection = (props) => {


//Props:
// id, image,  sectionHighlight, sectionHeader, sectionInfo


    return (
        <div id={props.id} className={props.bg + " twoColumnRow aboutSubSection"}>
            <div id={props.id + 'image'} className="aboutSectionImage twoColumn boldFont">
                <img src={props.image} width="300" height="300" alt=""/>
            </div>
            <div id={props.id + "Text"} className="aboutHighlightText twoColumn">
                    <div className="highlightOutfit sectionHighlight">
                        {props.sectionHighlight}
                    </div>
                <div className="boldFont sectionHeader">
                    {props.sectionHeader}
                </div>
                <div className="infoFont sectionInfo">
                    {props.sectionInfo}
                </div>
            </div>
        </div>
        
    );

};



export default AboutSection;