import React, { useState, useRef, forwardRef } from "react";
import { Nav } from "react-bootstrap";
import NavBar from "./NavBar";


const HeaderMain = (props) => {
   

    return (
        <div id="headerMain" className="boldFont">
            {/* <div className="pageHeader">
                <h1>Fantasy Football Trade Assistant</h1>
            </div> */}
            <div>
                <p id="homeDescription" className="tradeAnalyzerDescription infoFont">
                    Analyze Fantasy Football Trades Before You Fire Them Off
                </p>
            </div>
        </div>
    );

};



export default HeaderMain;