import React from "react";
import './PlayerCard.css';

const PlayerCard = (props) => {

    const player_info = props.player_info;
    let teamId = props.teamId;
    let player = props.player;

    const handlePlayerCheck = (event) => {
        const checkedPlayerId = event.target.value;
        const isChecked = event.target.checked;

        if(!teamId) {
            teamId="team_noTeam"
        }
        props.onCheckPlayer(checkedPlayerId, isChecked, teamId, props.isCurrentUser);
    }

    return (
        <div className={"playerCard boldFont " + props.getCardColor(player_info[player].player_position)}>
            <div className="playerCardTextDiv">
                <span className="playerInfo">
                    {player_info[player].player_position} - {player_info[player].player_team} {String('(' + player_info[player].player_value + ')')}
                </span>
                <div>
                    <div id={player_info[player].player_name.replace(/\s/g, '')} className="playerName">{player_info[player].player_name && player_info[player].player_name}</div>
                </div>  
            </div>  
            <div className="playerCheckboxDiv">
                    <input onChange={handlePlayerCheck} className="currentUserPlayerCheckbox" type="checkbox" value={player} aria-describedby={player_info[player].player_name.replace(/\s/g, '')}/>
            </div> 
        </div>
    );

}

export default PlayerCard;