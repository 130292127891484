import React from "react";
import { ErrorBoundary } from 'react-error-boundary'
import LeagueView from './LeagueView'
import './LeagueViewErrorBoundary.css';

function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <div className='leagueViewErrorBoundary boldFont' role="alert">
      <p className='warningText'>Something went wrong:</p>
      <pre className="errorMessage">{error.stack}</pre> 
      <p className='boldFont navigateHomeText'>Navigate Home and try again</p>
    </div>
  )
}


const LeagueViewErrorBoundary = (props) => {
// function LeagueViewErrorBoundary(props) {
  return (
    <ErrorBoundary
      FallbackComponent={MyFallbackComponent}
      onReset={() => {
        // reset the state of your app here

      }}
      resetKeys={['someKey']}
    >
      <LeagueView debug={props.debug}/>
    </ErrorBoundary>
  )
}


export default LeagueViewErrorBoundary;