import React from "react";
import './LineupDisplay.css';
import Table from 'react-bootstrap/Table';

const LineupDisplay = (props) => {
    //props.lineup
    //props.lineupDifferences


    function roundProjectionTo2Decimals(projection) {
        return Math.round((projection + Number.EPSILON) * 100) / 100;
    }

    function getLineupDifferencesClassForPlayer(playerName, playerPosition, isPreTrade) {
        if(props.lineupDifferences !== null && props.lineupDifferences !== undefined && props.lineupDifferences.length > 0) {
            let playerEntry = props.lineupDifferences.find(entry => entry.player.player_name === playerName && entry.player.player_position === playerPosition);
            if(playerEntry !== undefined) {
                if(playerEntry.isInPreTradeTeam === false) {
                    if(isPreTrade) { 
                        //give removed class to players no longer on team
                        return "removedPlayer";
                    }
                    return "newPlayer";
                } else if(playerEntry.isMovedToOrFromBench === true) {
                    return "movedPlayer";
                }
            }
        }
        return "";
    }

    // const lineupDiv = Object.entries(props.lineup).filter(position => position[0] !== "projectedPointTotal").map((position) => 
    //     <div key={"position_" + position[0] + getRandomIntAsString()}>
    //         {Object.entries(position[1].players).map((player) =>
    //             <div key={player[1].player_name.replace(/\s/g, "") + "_parentDiv"}>
    //                 <p className={getLineupDifferencesClassForPlayer(player[1].player_name, props.isPreTrade)} key={player[1].player_name.replace(/\s/g, "")} >{position[0] + ": " +  player[1].player_name} - {roundProjectionTo2Decimals(player[1].projections.pts_league)}</p>
    //             </div>
    //         )}
    //     </div>
    // );

    function getPtsLeague(player) {
       if(player[1] !== undefined && player[1] !== null && player[1].projections !== undefined && player[1].projections !== null) {
            if(player[1].projections.pts_league !== undefined && player[1].projections.pts_league !== null) {
                return player[1].projections.pts_league;
            }
       }
       return 0;
    }

    //Remove spaces in player names
    function getTableRowKey(player) {
        if(player[1] !== null && player[1] !== undefined && player[1].player_name !== null && player[1].player_name !== undefined) {
            return player[1].player_name.replace(/\s/g, "") + "_parentTr";
        }
        return Math.random().toString() + "_parentTr";
    }

    const lineupDivTableStarters = Object.entries(props.lineup).filter(position => position[0] !== "projectedPointTotal" && position[0] !== "BN").map((position) =>                               
        (   
            Object.entries(position[1].players).map((player) =>
            <tr className="weeklyBreakdownTableRow" key={getTableRowKey(player)} >
                <td className='weeklyBreakdownTablePositionCell'>
                    {position[0]}
                </td>
                <td className={"weeklyBreakdownTableNameCell " + getLineupDifferencesClassForPlayer(player[1].player_name, player[1].player_position, props.isPreTrade)}>
                    {player[1].player_name}
                </td>
                <td className={"weeklyBreakdownTableProjCell " + getLineupDifferencesClassForPlayer(player[1].player_name, player[1].player_position, props.isPreTrade)}>
                    {roundProjectionTo2Decimals(getPtsLeague(player))}
                </td>
            </tr> 
        )) 
    );

    const lineupDivTableBench = Object.entries(props.lineup).filter(position => position[0] !== "projectedPointTotal" && position[0] === "BN").map((position) =>                               
        (   
            Object.entries(position[1].players).map((player) =>
                <tr className="weeklyBreakdownTableRow" key={getTableRowKey(player)}>
                    <td className='weeklyBreakdownTablePositionCell'>
                        {position[0]}
                    </td>
                    <td className={"weeklyBreakdownTableNameCell " + getLineupDifferencesClassForPlayer(player[1].player_name, player[1].player_position, props.isPreTrade)}>
                        {player[1].player_name}
                    </td>
                    <td className={"weeklyBreakdownTableProjCell " + getLineupDifferencesClassForPlayer(player[1].player_name, player[1].player_position, props.isPreTrade)}>
                        {roundProjectionTo2Decimals(getPtsLeague(player))}
                    </td>
                </tr> 
            )
        ) 
    );

    return(
        <div>
            <Table className='table boldFont'>
                <thead>
                    <tr className='weeklyBreakdownTableHeader'>
                        <th className='weeklyBreakdownTablePositionCell'>Position</th>
                        <th style={{"width" : "125%"}} className='weeklyBreakdownTableNameCell'>Name</th>
                        <th className='weeklyBreakdownTableProjCell'>Projection</th>
                    </tr>
                </thead>
                <tbody>
                    {lineupDivTableStarters}
                    <tr className='benchTableHeader'>
                        <th colSpan={3}>Bench</th>
                    </tr>
                    {lineupDivTableBench}
                </tbody>
            </Table>

        </div>
    )
}

export default LineupDisplay;