import React from "react";
import CurrentUserTeamPlayerList from "./CurrentUserTeamPlayerList";
import './CurrentUserTeamView.css';

const CurrentUserTeamView = (props) => {
    let currentUsersRosterObject = props.currentUsersRosterObject;


    return (
        <div id={"team_" + currentUsersRosterObject.user_id} className="teamColumn userTeamColumn">
            <h2 className="proposeTradeHeaderText">Your Team</h2>
            <div data-testid="currentUserTeamName" className="boldFont ownerName">
                {currentUsersRosterObject.metadata.team_name}<span className="infoFont ownerDisplayName"> @{currentUsersRosterObject.display_name}</span>
                {/*{currentUserRoster.user_info.metadata.team_name}<span className="ownerDisplayName">@{currentUserRoster.user_info.display_name}</span>*/}
            </div>
            <CurrentUserTeamPlayerList getCardColor={props.getCardColor} team_id={currentUsersRosterObject.roster_data.owner_id} player_info={currentUsersRosterObject.roster_data.player_info} onCheckPlayer={props.onCheckPlayer} players={currentUsersRosterObject.roster_data.players}></CurrentUserTeamPlayerList>
        </div>
    );
};

export default CurrentUserTeamView;

