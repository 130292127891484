import { LeagueSettings } from "./LeagueSettings";
import { LeagueScoringSettings } from "./LeagueScoringSettings";
import { LeagueRosterPositions } from "./LeagueRosterPositions";


export class League {
    //Need this to somehow be dynamic going forward with other websites. Easiest way would most likely be a flattening of sorts
    //For the deeper nested object, and potentially some logic to parse differently depending on site?
    //Also, doing away with useless (or maybe site specific) data?
    constructor(data, leagueType) {
        this._isDoneLoading = false;

        if(leagueType === "sleeper") {
            this._total_rosters = data.total_rosters;
            this._status = data.status;
            this._sport = data.sport;
            this._settings = new LeagueSettings(data.settings, leagueType);
            this._season_type = data.season_type;
            this._season = data.season;
            this._scoring_settings = new LeagueScoringSettings(data.scoring_settings, leagueType);
            this._roster_positions = new LeagueRosterPositions(data.roster_positions, leagueType);
            this._previous_league_id = data.previous_league_id;
            this._name = data.name;
            this._league_id = data.league_id;
            this._draft_id = data.draft_id;
            this._avatar = data.avatar;
            this._replacement_level_values = null;
            this._largest_par_score = 0;
        }
    }

    // Getters
    get isDoneLoading() {
        return this._isDoneLoading;
    }

    get total_rosters() {
        return this._total_rosters;
    }

    get status() {
        return this._status;
    }

    get sport() {
        return this._sport;
    }

    get settings() {
        return this._settings;
    }

    get season_type() {
        return this._season_type;
    }

    get season() {
        return this._season;
    }

    get scoring_settings() {
        return this._scoring_settings;
    }

    get roster_positions() {
        return this._roster_positions;
    }

    get previous_league_id() {
        return this._previous_league_id;
    }

    get name() {
        return this._name;
    }

    get league_id() {
        return this._league_id;
    }

    get draft_id() {
        return this._draft_id;
    }

    get avatar() {
        return this._avatar;
    }

    get replacement_level_values() {
        return this._replacement_level_values;
    }

    get largest_par_score() {
        return this._largest_par_score;
    }

    // Setters
    set isDoneLoading(value) { 
        this._isDoneLoading = value; 
    }

    set total_rosters(value) {
        this._total_rosters = value;
    }

    set status(value) {
        this._status = value;
    }

    set sport(value) {
        this._sport = value;
    }

    set settings(value) {
        this._settings = value;
    }

    set season_type(value) {
        this._season_type = value;
    }

    set season(value) {
        this._season = value;
    }

    set scoring_settings(value) {
        this._scoring_settings = value;
    }

    set roster_positions(value) {
        this._roster_positions = value;
    }

    set previous_league_id(value) {
        this._previous_league_id = value;
    }

    set name(value) {
        this._name = value;
    }

    set league_id(value) {
        this._league_id = value;
    }

    set draft_id(value) {
        this._draft_id = value;
    }

    set avatar(value) {
        this._avatar = value;
    }

    set largest_par_score(value) {
        this._largest_par_score = value;
    }

    set_replacement_level_values(values) {
        this._replacement_level_values = values;
    }

    get_unique_roster_positions_array() {
        return this._roster_positions.getUniqueRosterPositionsArray();
    }

    getReplacementLevelValueForPlayerPosition(userObject, playerId) {
        let playerPosition = this.getPlayersPositionFromPlayerId(userObject, playerId);
        return this._replacement_level_values[playerPosition];
    }

    //Where currentUserObject is a CurrentUser object or LeagueUserObject (?? test this)
    //playerId is a string
    getPlayersPositionFromPlayerId(userObject, playerId) {
        return userObject.getPlayersPositon(playerId);
    }

    //Passing in the user and leagueUsers objects, determine and set the highest Value score for the league 
        //Which is the difference between a player and their replacement level score for their position
    //This is used to then determine max value (or Value Above Replacement-ish)
    determineAndSetHighestValueScoreForAllOwnedPlayers(currentUserObject, leagueUsersObject, startWeek, endWeek) {
        let largestDifference = 0;

        let usersLargestDifference = this.getUsersLargestParDifferenceOnTheirTeam(currentUserObject, startWeek, endWeek);
        if(usersLargestDifference > largestDifference) {
            largestDifference = usersLargestDifference;
        }

        //Check all of league users players for their replacement level score
        for(let user of leagueUsersObject.userList.entries()) {
            let singleLeagueUserObject = user[1];
            usersLargestDifference = this.getUsersLargestParDifferenceOnTheirTeam(singleLeagueUserObject, startWeek, endWeek);
            if(usersLargestDifference > largestDifference) {
                largestDifference = usersLargestDifference;
            }  
        }

        this._largest_par_score = largestDifference
        this._isDoneLoading = true;
    }

    //Given a user object (current or league), get the highest PAR (points above replacement) score of any player on the team
    //Note - sub function depends on being within League.js to access league object, if you move this, adjust accordingly.
    getUsersLargestParDifferenceOnTheirTeam(userObject, startWeek, endWeek) {
        let largestParDifferenceForThisUser = 0;
        
        for(let player of Object.entries(userObject.getPlayerProjectionsJsonFromFullData())) {
            let playerAvgRemainingProjection = userObject.getRosterData().getPlayersAvgRemainingProjectionPointsBasedOnScoringSettingsFromFullData(player[0], startWeek, endWeek);
            if(playerAvgRemainingProjection !== null && playerAvgRemainingProjection !== undefined) {
                //PAR == points above replacement
                let playerPAR = playerAvgRemainingProjection - this.getReplacementLevelValueForPlayerPosition(userObject, player[0]);
                if(playerPAR > largestParDifferenceForThisUser) {
                    largestParDifferenceForThisUser = playerPAR;
                }
            }
        }

        return largestParDifferenceForThisUser;
    }

    getAllLeaguePositionsThatCanBeUsedForFlex() {
        return this.getRosterPositionsObject().getAllLeaguePositionsThatCanBeUsedForFlex();
    }

    getRosterPositionsObject() {
        return this._roster_positions;
    }

    getPositionsAllowedForFlexPosition(position) {
        return this.getRosterPositionsObject().getPositionsAllowedForFlexPosition(position);
    }

    getFlexPositionsAllowedForPlayerPosition(playerPosition) {
        return this.getRosterPositionsObject().getFlexPositionsAllowedForPlayerPosition(playerPosition);
    }

    getFlexPositionsAllowedForPlayerPositionForThisLeague(playerPosition) {
        return this.getRosterPositionsObject().getFlexPositionsAllowedForPlayerPositionForThisLeague(playerPosition);
    }

    getFinalWeekOfSeasonForLeague() {
        return this._settings.end_week
    }

}